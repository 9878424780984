import * as types from '../actions/actionTypes';
import { USER } from '../common/types/signalRTypes';

export const registerEvents = (store, connection) => {
  connection.on(USER.UserDeleted, (message) => {
    let { userId, agendaId } = message;
    let state = store.getState();
    if (userId && state && state.user && userId === state.user.userId) {
      store.dispatch({
        type: types.APP_NEED_SIGNOUT_DIALOG_OPEN,
        agendaId
      });
    }
  });
};

export const doActions = (action, connection) => {
  // DO NOTHING
};
