import axios from 'axios';
import * as types from './actionTypes';
import { getUserAgendas } from './userActions';
import { alertAdd } from './alertActions';
import * as responseTypes from '../common/types/responseTypes';
import * as alertTypes from './alertTypes';
import { navigateToUserInvitations } from './navigationActions';
import { API_PATHS } from '../common/data/routes';
import { cultureNames } from '../common/data/localizationData';

export const setCompanyInvitationStatus = (companyInvitation, reject) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_INVITATION_SET_REQUEST, types.COMPANY_INVITATION_SET_SUCCESS, types.COMPANY_INVITATION_SET_FAILURE],
    callAPI: () => axios.post(`${API_PATHS.COMPANY_INVITATION_SET_STATUS}`, { id: companyInvitation.id, reject }),
    callSuccessAction: () => {
      dispatch({
        type: types.TABLE_REMOVE_ROWS,
        ids: [companyInvitation.id]
      });
      if (!reject) {
        dispatch(alertAdd('YouArePairedWith', alertTypes.INFO));
      } else {
        dispatch(alertAdd('YouRejectedToPairWith', alertTypes.INFO));
      }
      dispatch(getUserAgendas());
    }
  });
};

export const createUserInvitation = (userInvitation) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_INVITATION_CREATE_REQUEST, types.COMPANY_INVITATION_CREATE_SUCCESS, types.COMPANY_INVITATION_CREATE_FAILURE],
    callAPI: () => {
      return axios.post(API_PATHS.USER_INVITATION, { ...userInvitation });
    },
    callSuccessAction: (response) => {
      let { code } = ((response || {}).data);
      if (code) {
        switch (code) {
          case responseTypes.UserInvitation.UserEmailIsSame: {
            dispatch(alertAdd('UserInvitationUserEmailIsSame', alertTypes.WARNING, { email: userInvitation.email }));
            break;
          }
          case responseTypes.UserInvitation.UserEmailCantBeEmpty: {
            dispatch(alertAdd('UserInvitationUserEmailCantBeEmpty', alertTypes.WARNING));
            break;
          }
          case responseTypes.UserInvitation.UserIsAlreadyInvited: {
            dispatch(alertAdd('UserInvitationUserIsAlreadyInvited', alertTypes.WARNING, { email: userInvitation.email }));
            break;
          }
          case responseTypes.UserInvitation.UserIsAlreadyPaired: {
            dispatch(alertAdd('UserInvitationUserIsAlreadyPaired', alertTypes.WARNING, { email: userInvitation.email }));
            break;
          }
          case responseTypes.OK: {
            dispatch(alertAdd('UserInvitationUserWasInvited', alertTypes.INFO, { email: userInvitation.email }));
            dispatch(navigateToUserInvitations());
            break;
          }
          default:
            break;
        }
      }
    }
  });
};

export const expireUserInvitation = (id, callback) => (dispatch) => {
  dispatch({
    types: [types.USER_INVITATION_EXPIRE_REQUEST, types.USER_INVITATION_EXPIRE_SUCCESS, types.USER_INVITATION_EXPIRE_FAILURE],
    callAPI: () => axios.get(`${API_PATHS.USER_INVITATION_EXPIRE}/${id}`),
    callSuccessAction: (response) => {
      let { data: { code } = { code: -1 } } = response;
      if (code === responseTypes.OK) {
        callback(response.data);
        dispatch(alertAdd("UserInvitation.ExpireSuccess", alertTypes.SUCCESS));
      }

      if (code === responseTypes.UserInvitation.UserAlreadyAcceptedInvitation) {
        dispatch(alertAdd("UserInvitation.UserAlreadyAcceptedInvitation", alertTypes.WARNING));
      }
    },
    callFailureAction: () => dispatch(alertAdd("SaveFailure", alertTypes.ERROR)),
  });
};

export const acceptCompanyInvitation = (id, token, callback) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_INVITATION_ACCEPT_REQUEST, types.COMPANY_INVITATION_ACCEPT_SUCCESS, types.COMPANY_INVITATION_ACCEPT_FAILURE],
    callAPI: () => axios.get(`${API_PATHS.COMPANY_INVITATION_ACCEPT.replace('[:id]', id)}?token=${token}`),
    callSuccessAction: (response) => {
      callback(response.data);
    },
    callFailureAction: () => dispatch(alertAdd("LoadFailure", alertTypes.ERROR)),
  });
};

export const rejectCompanyInvitation = (id, token, callback) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_INVITATION_REJECT_REQUEST, types.COMPANY_INVITATION_REJECT_SUCCESS, types.COMPANY_INVITATION_REJECT_FAILURE],
    callAPI: () => axios.get(`${API_PATHS.COMPANY_INVITATION_REJECT.replace('[:id]', id)}?token=${token}`),
    callSuccessAction: (response) => {
      callback(response.data);
    },
    callFailureAction: () => dispatch(alertAdd("LoadFailure", alertTypes.ERROR)),
  });
};
