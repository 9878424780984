import * as types from '../actions/actionTypes';
import { myAccountInitialState } from './initialStates';

const myAccount = (state = myAccountInitialState, action) => {
  switch (action.type) {
    case types.MY_ACCOUNT_FETCH_SUCCESS: {
      let data = JSON.parse(JSON.stringify(action.data).replace(/null/g, "\"\""));
      return {
        ...state,
        ...data
      };
    }
    case types.MY_ACCOUNT_SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isPasswordSecured: action.data.isPasswordSecured,
        invalidPassword: action.data.invalidPassword
      };
    default:
      return state;
  }
};

export default myAccount;
