import * as types from '../actions/actionTypes';

export const initialState = {
  open: false
};

const accountMenu = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_ACCOUNT_MENU:
      return {
        ...state,
        open: !state.open
      };
    case types.CLOSE_ACCOUNT_MENU:
      return {
        ...state,
        open: false
      };
    case types.TOGGLE_ACCOUNT_MENU_SELECT_ITEM:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

export default accountMenu;
