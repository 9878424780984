import { Link, useLocation } from "react-router-dom";
import { API_PATHS, NAVIGATION_PATHS } from "../../common/data/routes";
import classNames from "classnames";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import queryString from 'query-string';

import Translation from "../common/Translation";
import display from '../../images/display.webp';
import logo from '../../images/logo_light.svg';
import './DownloadArchivePackage.scss';
import { useEffect, useState } from "react";
import axios from "axios";
import { downloadArchivePackageByToken } from "../reportFunctions";
import { ArchivePackageStateEnum } from "common/types/archivePackageTypes";
import { setLang } from "../../actions/appActions";
import { connect, ConnectedProps } from "react-redux";

const DownloadArchivePackage = ({ setLang }: DownloadArchivePackageProps) => {
  const [senderName, setSenderName] = useState('');
  const [packageState, setPackageState] = useState(-1);
  const [downloading, setDownloading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const prefix = "ArchivePackages.DownloadPackage.";
  const token = queryString.parse(location.search).token;

  const getTokenInfo = async () => {
    try {
      const response = await axios.get(`${API_PATHS.ARCHIVE_PACKAGE_TOKEN_INFO}?token=${token}`);
      const { sender, state, langCode = 'cs' } = response?.data;
      setPackageState(state);
      setSenderName(sender);
      setLang(langCode);
    } catch (error) { }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  const downloadHandle = (token: string) => async () => {
    if (!downloading) {
      setDownloading(true);
      try {
        await downloadArchivePackageByToken(token);
      } catch (error) {
        // TODO
      } finally {
        setDownloading(false);
      }
    }
  };

  const classWrapper = classNames({
    'anonymous-zone-wrapper download-archive-package': true,
    'mobile-version': isMobileOnly,
  });

  return (
    <div className={classWrapper}>
      <div className="download-archive-package-main">
        <div className="logo"><img className="logo" src={logo} alt="logo" /></div>
        <div className="general-registration">
          <span className="datarios-link-button datarios-button-green">
            <Link to={NAVIGATION_PATHS.SIGN_UP}><Translation tKey="SignUp" /></Link>
          </span>
        </div>
        <div className="download-archive-package-content">
          <div className="download-archive-package-display"><img src={display} alt="logo-formats" /></div>
          <div className="download-archive-package-text">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="download-archive-package-header">
                  {packageState === ArchivePackageStateEnum.Ready && t(`${prefix}Header`, { user: senderName })}
                  {packageState !== ArchivePackageStateEnum.Ready && ""}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="download-archive-package-main-text">
                  {packageState === ArchivePackageStateEnum.Ready && t(`${prefix}Main`, { user: senderName })}
                  {packageState !== ArchivePackageStateEnum.Ready && t("ArchivePackages.ExpiredPackage")}
                </div>
              </div>
            </div>
            <div className="download-archive-package-buttons flex flex-wrap">
              {packageState === ArchivePackageStateEnum.Ready &&
                <div className="datarios-button datarios-transparent-button datarios-button-green"
                  onClick={downloadHandle(token)}
                >
                  {downloading && <span className="k-i-loading" style={{ marginRight: "1em" }} />}
                  {t(`${prefix}Download`)}
                </div>
              }
              <div className="datarios-button datarios-transparent-button datarios-button-blue"
                onClick={() => { window.open(NAVIGATION_PATHS.DATARIOS, '_blank'); }}
              >
                <Translation tKey="WhatIsItDatarios" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

const mapDispatch = {
  setLang,
};

const connector = connect(null, mapDispatch);
type DownloadArchivePackageProps = ConnectedProps<typeof connector>;

export default connector(DownloadArchivePackage);
