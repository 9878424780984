import i18n from '../../../i18n';
import { absentSign } from '../../../common/data/archiveData';

const MILLISECONDS_IN_DAY = 86400000;

export const getDiffInDays = (date1: Date, date2: Date): number => {
  const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
  const diffInDays = diffInMilliseconds / MILLISECONDS_IN_DAY;
  return Math.floor(diffInDays);
};

export const getDateByDaysAgo = (date: Date, days: number): Date => {
  const newTimeStamp = date.getTime() - days * MILLISECONDS_IN_DAY;
  return new Date(newTimeStamp);
};

export const AddMonthsToDate = (months: number, date: Date): Date => {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
};

export const AddDaysToDate = (days: number, date: Date): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const AddMonthsToNow = (months: number): Date => {
  return AddMonthsToDate(months, new Date());
};

export const formatDate = (date: Date, options?: Intl.DateTimeFormatOptions | undefined) => {
  return date.toLocaleString(i18n.language, options);
};

export const formatDateInDays = (date: Date): string => {
  const now = new Date();
  const diffInDays = getDiffInDays(now, date);
  if (diffInDays === 0) {
    return `${i18n.t('Date.Today')} ${formatDate(date, { timeStyle: 'short' })}`;
  }

  if (diffInDays === 1) {
    return `${i18n.t('Date.Yesterday')}`;
  }

  return `${formatDate(date, { dateStyle: 'short' })}`;
};

// Format datetime string from server to readable date (by default full date format with time)
export const formatDateTimeString = (value: string, options?: Intl.DateTimeFormatOptions | undefined): string => {
  if (!value) {
    return absentSign;
  }

  const date = value.endsWith('Z') ? new Date(value) : new Date(value + 'Z');

  return formatDate(date, options ?? { dateStyle: 'short', timeStyle: 'short' });
};

// Format datetime string from server to readable short date format (without time)
export const formatDateString = (value: string) => {
  return formatDateTimeString(value, { dateStyle: 'short' });
};

export const getDateOnly = (datetime: Date) => {
  return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
};
