import { FieldHOC as Field } from './Field';
import { FieldRenderProps } from 'react-final-form';
import { FieldState } from 'final-form';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { withTranslation, WithTranslation } from 'react-i18next';
import './FloatingLabelField.scss';

interface ComponentDataProps {
  format?: string,
  textField?: string,
  valueField?: string,
  type?: string,
  data?: any[],
}

interface FloatingLabelFieldProps extends WithTranslation {
  name: string,
  tLabelKey: string,
  component: (props: any) => JSX.Element,
  fieldProps?: object,
  componentProps?: ComponentDataProps & any,
  isMandatory?: boolean,
  validate?: (value: any, allValues: object, meta?: FieldState<any>) => string | undefined,
  disabled?: boolean,
  className?: string | any,
  testId?: string,
}

const FloatingLabelField = (props: FloatingLabelFieldProps) => {
  const { name, tLabelKey, t, component, fieldProps, componentProps, isMandatory = false, validate, className, disabled = false, testId } = props;
  const Component = component;

  const validateHandler = (value: any, allValues: object, meta: FieldState<any>) => {
    if (!value && isMandatory) {
      return t('Required');
    }
    if (validate) {
      return validate(value, allValues, meta);
    }
    return undefined;
  };

  return (
    <Field name={name} {...fieldProps} validate={validateHandler}>
      {(props: FieldRenderProps<any>) => {
        const { input } = props;
        return (
          <FloatingLabel
            className="floating-label-field"
            label={t(tLabelKey)}
            editorId={`${name}-id`}
            editorValue={input.value.toString()}
          >
            <div data-testid={testId}>
              <Component id={`${name}-id`} className={className} {...props} {...componentProps} disabled={disabled} />
            </div>
          </FloatingLabel>
        );
      }}
    </Field>
  );
};

export default withTranslation('common')(FloatingLabelField);
