import { AppDispatch } from "AppRoot";
import axios from "axios";
import { API_PATHS } from "common/data/routes";
import { RootState } from "common/types/reduxStateTypes";

export const logError = (error: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();

  axios.post(API_PATHS.LOG_ERROR, { data: error, state: JSON.stringify(state) });
};
