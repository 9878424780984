import './SubmitButton.scss';
import Button from './Button';
import { ButtonLook, ButtonMode } from '../../common/types/uiTypes';

type SubmitButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  loading?: boolean,
  disabled?: boolean,
  className?: string,
  actionType?: ButtonMode,
  look?: ButtonLook
  icon?: string,
  style?: any,
  testId?: string,
  children: React.ReactNode,
};

const SubmitButton = ({
  children, icon, onClick, loading = false, className = "k-primary", actionType = 'primary', look = 'solid', disabled = false, style = {}, testId
}: SubmitButtonProps) => {
  const handleClick = (event: any) => {
    if (!loading) {
      onClick(event);
    }
  };

  return (
    <Button
      onClick={handleClick}
      className={className}
      disabled={loading || disabled}
      style={style}
      look={look}
      mode={actionType}
      testId={testId}
    >
      {loading && <span className="k-icon k-i-loading" />}
      {!loading && icon && <span className={`icon icon-${icon}`} />}
      {children}
    </Button>
  );
};

export default SubmitButton;
