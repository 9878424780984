import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import * as userActions from '../../actions/userActions';
import TextBox from '../common/TextBox';
import SubmitButton from '../common/SubmitButton';
import Translation from '../common/Translation';
import StrengthMeterTextBox from '../common/StrengthMeterTextBox';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import AnonymousZone from './AnonymousZone';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      errors: {},
      token: this.props.resetKey || ''
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isPasswordSecured !== this.props.isPasswordSecured) {
      if (!this.props.isPasswordSecured) {
        let { errors } = this.state;
        errors.password = this.props.t('PasswordIsNotSecured');
        this.setState({ errors });
      }
    }
  }

  formIsValid = () => {
    let { password, confirmPassword } = this.state;
    let { t } = this.props;
    let formIsValid = true;
    let errors = {};

    if (this.passwordBox.getScore() < 1) {
      errors.password = t('PasswordIsNotSecured');
      formIsValid = false;
    }

    if (password !== confirmPassword) {
      errors.confirmPassword = t('PasswordsDontMatchRetype');
      formIsValid = false;
    }

    this.setState({ errors });

    return formIsValid;
  };

  setNewPassword = (event) => {
    event.preventDefault();

    if (this.formIsValid()) {
      let { password, token } = this.state;
      this.props.actions.setNewPassword(password, token);
    }
  };

  renderForm = () => {
    let { errors, password, confirmPassword } = this.state;
    let { t } = this.props;
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="PasswordReset" /></div>
          <div><Translation tKey="TypePasswordTwice" /></div>
        </div>
        <form>
          <div className="row-margin">
            <StrengthMeterTextBox
              name="password"
              type="password"
              onChange={(e) => { this.setState({ password: e.target.value }); }}
              value={password}
              error={errors.password}
              placeholder={t('CreatePassword')}
              ref={(passwordBox) => { this.passwordBox = passwordBox; }} />
          </div>
          <div className="row-margin">
            <TextBox
              name="confirmPassword"
              type="password"
              autoComplete="new-password"
              onChange={(e) => { this.setState({ confirmPassword: e.target.value }); }}
              value={confirmPassword}
              error={errors.confirmPassword}
              placeholder={t('ConfirmPassword')} />
          </div>
          <div className="box-actions">
            <div className="actions">
              <SubmitButton onClick={this.setNewPassword} loading={this.props.loading}><Translation tKey="Next" /></SubmitButton>
            </div>
            <div className="links">
              <div>
                <Link to={NAVIGATION_PATHS.SIGN_IN} className="text-link underlined"><Translation tKey="DoYouKnowYourPassword" /></Link>
              </div>
            </div>
          </div>
        </form >
      </div >
    );
  };

  render() {
    return (
      <AnonymousZone>
        {this.renderForm()}
      </AnonymousZone>
    );
  }
}

ResetPassword.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  resetKey: PropTypes.string,
  t: PropTypes.func,
  isPasswordSecured: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  let isPasswordSecured = true;
  let { resetPassword } = state.user;

  if (resetPassword) {
    isPasswordSecured = resetPassword.isPasswordSecured;
  }
  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    resetKey: queryString.parse(ownProps.location.search).key || '',
    isPasswordSecured
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(ResetPassword);
