import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import Root from './components/Root';
import '@progress/kendo-ui';
import { load, loadMessages } from '@progress/kendo-react-intl';
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import './styles/mobileStyles.scss';

import { createBrowserHistory } from 'history';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/cs/numbers.json";
import localCurrency from "cldr-numbers-full/main/cs/currencies.json";
import caGregorian from "cldr-dates-full/main/cs/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/cs/dateFields.json";
import timeZoneNames from 'cldr-dates-full/main/cs/timeZoneNames.json';

import csMessages from './locales/cs/kendoUi.json';
import csMobileMessages from './locales/cs/kendoUiMobile.json';
import enMessages from './locales/en/kendoUi.json';
import enMobileMessages from './locales/en/kendoUiMobile.json';

import configureStore from './store/configureStore';

require('./favicon.ico'); // Tell webpack to load favicon.ico

const history = createBrowserHistory();
const store = configureStore(undefined, history);

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  localCurrency,
  caGregorian,
  dateFields,
  timeZoneNames
);

if (isMobileOnly) {
  loadMessages(csMobileMessages, 'cs');
  loadMessages(enMobileMessages, 'en');
} else {
  loadMessages(csMessages, 'cs');
  loadMessages(enMessages, 'en');
}

class AppRoot extends React.Component {
  render() {
    return (
      <Root store={store} history={history} />
    );
  }
}

export type AppDispatch = typeof store.dispatch;

export default AppRoot;
