import _ from 'lodash';

import { notificationInitialState } from './initialStates';
import * as types from '../actions/actionTypes';
import { NOTIFICATION_LOG_TYPES, NOTIFICATION_TYPES } from '../common/types/notificationTypes';
import { checkLogItemByType } from '../components/generalFunctions';

const convertToIsoString = (dateString) => {
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.(\d)+)*$/.test(dateString)) {
    return `${dateString}Z`;
  }
  return dateString;
};

const filterNotificationData = (data, lastSeenDates) => {
  const filteredLog = {};

  const filterByTypeAndDate = (item, path) => {
    const date = lastSeenDates ? lastSeenDates[path] : null;
    const dateCondition = date ? new Date(convertToIsoString(item.dateOfCreate)).getTime() >= new Date(date).getTime() : true;
    return checkLogItemByType(item, path) && dateCondition;
  };

  const paths = Object.values(NOTIFICATION_TYPES);
  paths.forEach((path) => {
    filteredLog[path] = data.filter((item) => filterByTypeAndDate(item, path));
  });
  const billing = data.filter(item => item.type === NOTIFICATION_LOG_TYPES.BillingAgendaBalance);
  filteredLog.billing = billing;

  return filteredLog;
};

const countNotificationData = (filteredLog) => {
  const paths = Object.values(NOTIFICATION_TYPES);
  const counts = {};
  paths.forEach((path) => {
    counts[path] = filteredLog[path].length;
  });
  return counts;
};

const joinNotificationLogs = (notificationLog, newNotificationLog) => {
  const newLog = {};
  Object.keys(notificationLog).forEach((path) => {
    const notificationsIds = notificationLog[path].map(({ id }) => id);
    const newNotifications = newNotificationLog[path].filter(({ id }) => !notificationsIds.includes(id));
    newLog[path] = [...notificationLog[path], ...newNotifications];
  });
  return newLog;
};

const notification = (state = notificationInitialState, action) => {
  switch (action.type) {
    case types.NOTIFICATIONS_FETCH_SUCCESS: {
      let newLog = {};
      let newCounts = {};
      let { lastSeenNotifyDates } = action;
      if (action.initLoading) {
        newLog = filterNotificationData(action.data.data, lastSeenNotifyDates);
        newCounts = countNotificationData(newLog);
      } else {
        const fetchedLog = filterNotificationData(action.data.data);
        newLog = joinNotificationLogs(state.log, fetchedLog);
        newCounts = countNotificationData(newLog);
      }
      return {
        ...state,
        counts: newCounts,
        lastFetched: action.data.processDate,
        log: newLog,
      };
    }
    case types.NOTIFICATIONS_SAW: {
      const notificationState = _.cloneDeep(state);
      const { counts } = notificationState;
      counts[action.path] = 0;

      return {
        ...state,
        counts,
      };
    }
    case types.NOTIFICATION_SAW: {
      const notificationState = _.cloneDeep(state);
      const { log } = notificationState;
      const filteredLog = log[action.path].filter((item) => item.externalId !== action.id);

      return {
        ...state,
        log: { ...log, [action.path]: filteredLog },
      };
    }
    case types.NOTIFICATIONS_READ: {
      return {
        ...state,
        read: action.read
      };
    }
    case types.NOTIFICATIONS_CLEAR: {
      const notificationState = _.cloneDeep(state);
      const { log } = notificationState;
      log[action.path] = [];

      return {
        ...state,
        log,
      };
    }
    default:
      return state;
  }
};

export default notification;
