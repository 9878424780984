import * as types from '../actions/actionTypes';

export const initialState = {
  chart: {
    categories: [],
    series: []
  },
  chartDonut: {
    series: []
  }
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_CHART_TOTAL_INVOICES_DATA_SUCCESS:
      return {
        ...state,
        chart: { series: action.data.series, categories: action.data.categories }
      };
    case types.DASHBOARD_CHART_DONUT_TOTAL_INVOICES_DATA_SUCCESS:
      return {
        ...state,
        chartDonut: { series: action.data.series }
      };
    case types.DASHBOARD_HOW_MUCH_YOU_SAVED_SUCCESS:
      return {
        ...state,
        chartSavings: { ...action.data }
      };
    default:
      return state;
  }
};

export default dashboard;
