import * as types from '../actions/actionTypes';

export const initialState = {

};

const virtual = (state = initialState, action) => {
  switch (action.type) {
    case types.VIRTUAL_DATA_SET: {
      let storedData = state[action.path] || [];

      return {
        ...state,
        [action.path]: [...storedData.filter(v => v.id !== action.id), ...action.items]
      };
    }
    default:
      return state;
  }
};

export default virtual;
