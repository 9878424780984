import { Field as ReactFinalFormField } from 'react-final-form';
import classNames from 'classnames';

import Input from './Input';
import { useTranslation } from 'react-i18next';

export const FieldHOC = (props) => {
  return (
    <ReactFinalFormField {...props}
      parse={value => (value === "" ? "" : value)}
    />
  );
};

export const Field = (props) => {
  const { name, component = Input, validate, required, type, className, wrapperClassName, title, testId, ...rest } = props;
  const { size = "medium", fillMode = "solid" } = rest;
  const Component = component;
  const { t } = useTranslation();

  const wrappedClass = classNames({
    'general-form-field': true,
    [className]: className,
    [`field-size-${size}`]: size,
    [`fillMode-${fillMode}`]: fillMode,
    [`testid-${testId}`]: testId,
  });

  const wrapperFieldClass = classNames({
    'form-field-container': true,
    [`${wrapperClassName}`]: wrapperClassName,
  });

  let wrapperValidate = (value) => {
    if (!value && required) {
      return { message: t('Required') };
    }
    return validate ? validate(value) : undefined;
  };

  return (
    <ReactFinalFormField
      name={name}
      validate={wrapperValidate}
      type={type}
      parse={value => (value === "" ? "" : value)}
    >
      {
        (props) => {
          const { value } = props.input;
          const fieldTitle = title ?? typeof value === 'string' ? value : null;

          return (
            <div className={wrapperFieldClass} title={title} data-testid={testId}>
              <Component id={`${name}-id`} className={wrappedClass} {...props} {...rest} />
            </div>
          );
        }
      }
    </ReactFinalFormField>
  );
};
