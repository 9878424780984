import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import './ResponsiveDropDown.scss';

const ResponsiveDropDownList = (props: any) => (
  <DropDownList
    {...props}
    popupSettings={{ className: "responsive-drop-down-list" }}
  />
);

export default ResponsiveDropDownList;
