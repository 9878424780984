import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button as KendoButton } from '@progress/kendo-react-buttons';
import { filterTriggers } from '../generalFunctions';
import { RootState } from '../../common/types/reduxStateTypes';
import { ButtonLook, ButtonMode } from '../../common/types/uiTypes';
import { GetThemeColor } from '../../styles/styleHelper';
import classNames from 'classnames';

type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
  children?: any,
  icon?: string,
  look?: ButtonLook,
  mode?: ButtonMode,
  light?: boolean,
  disabled?: boolean,
  className?: string | any,
  title?: string,
  style?: any,
  type?: "button" | "submit" | "reset" | undefined,
  testId?: string,
  disablingActionTypes?: string[] // action type when the button has to be disabled
} & ButtonConnectedProps;

const Button: React.FunctionComponent<ButtonProps> = (
  {
    onClick, children, icon, look = 'solid', style, mode = "primary", light = false, disabled = false, className, title = undefined, type,
    disablingActionTypes, triggers = [], testId }
) => {
  const handleClick = (e: any) => {
    e.preventDefault();

    if (onClick) {
      onClick(e);
    }
  };

  const isDarkButton = () => (
    !light && look === 'solid' && ['primary', 'secondary', 'tertiary', 'danger'].includes(mode)
  );

  const wrapperClass = classNames({
    'general-button': true,
    'general-text-button': children,
    'button-light': light,
    'button-dark': isDarkButton(),
    'flat-text-button': look === 'flat',
    [className]: className,
  });

  const filteredTriggers: { total: number }[] = filterTriggers(triggers, { include: disablingActionTypes });
  const loading = filteredTriggers.some(t => t.total > 0);

  return (
    <KendoButton
      onClick={handleClick}
      className={wrapperClass}
      disabled={disabled || loading}
      title={title} style={style}
      type={type}
      themeColor={GetThemeColor(mode)}
      fillMode={look}
      data-testid={testId}
    >
      {icon && <span className={`icon icon-${icon}`} />}
      {children}
    </KendoButton>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    triggers: state.ajaxStatus.triggers,
  };
};

const connector = connect(mapStateToProps);
type ButtonConnectedProps = ConnectedProps<typeof connector>;

export default connector(Button);
