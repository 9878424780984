import axios from 'axios';
import * as types from './actionTypes';
import { alertAdd } from './alertActions';
import * as alertTypes from './alertTypes';
import { COMPANY_VERIFICATION_TYPE } from '../common/types/companyVerificationTypes';
import { navigateToRoot, navigateToSignUpCompany } from '../actions/navigationActions';
import { API_PATHS } from '../common/data/routes';

export const getCompanyVerification = (token) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_VERIFICATION_FETCH_DATA_REQUEST, types.COMPANY_VERIFICATION_FETCH_DATA_SUCCESS, types.COMPANY_VERIFICATION_FETCH_DATA_FAILURE],
    callAPI: () => axios.get(API_PATHS.COMPANY_VERIFICATION, { params: { token: token } }),
    callSuccessAction: (response) => {
      let { data: { companyVerificationState } } = response;
      if (companyVerificationState === COMPANY_VERIFICATION_TYPE.VerifiedAndRedirect) {
        dispatch(navigateToRoot());
      }
    }
  });
};

export const sendCompanyVerification = (token) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_REQUEST, types.COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_SUCCESS, types.COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_FAILURE],
    callAPI: () => axios.get(API_PATHS.COMPANY_VERIFICATION_SEND, { params: { token: token } })
  });
};

export const verifyCompanyVerificationCode = (token, code) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_VERIFICATION_VERIFY_REQUEST, types.COMPANY_VERIFICATION_VERIFY_SUCCESS, types.COMPANY_VERIFICATION_VERIFY_FAILURE],
    callAPI: () => axios.get(API_PATHS.COMPANY_VERIFICATION_VERIFY, { params: { token: token, code: code } }),
    callSuccessAction: (response) => {
      let { data: { companyVerificationState } } = response;
      if (companyVerificationState === COMPANY_VERIFICATION_TYPE.VerificationFailed) {
        dispatch(alertAdd('CodeIsNotValid', alertTypes.WARNING));
      }

      if (companyVerificationState === COMPANY_VERIFICATION_TYPE.VerifiedAndRedirect) {
        dispatch(navigateToRoot());
      }
    }
  });
};

export const restartPayment = (token) => (dispatch) => {
  dispatch({
    types: [types.COMPANY_VERIFICATION_RESTART_PAYMENT_DATA_REQUEST, types.COMPANY_VERIFICATION_RESTART_PAYMENT_DATA_SUCCESS, types.COMPANY_VERIFICATION_RESTART_PAYMENT_DATA_FAILURE],
    callAPI: () => axios.get(API_PATHS.COMPANY_VERIFICATION_RESTART_PAYMENT, { params: { token: token } }),
    callSuccessAction: (response) => {
      let { data: { success } } = response;
      if (success === true) {
        dispatch(navigateToSignUpCompany(token));
      }
    }
  });
};
