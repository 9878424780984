import { AnyAction } from 'redux';
import * as types from '../actions/actionTypes';
import { userTableFiltersInitialState } from './initialStates';

const userTableFilters = (state = userTableFiltersInitialState, action: AnyAction) => {
  switch (action.type) {
    case types.USER_TABLE_FILTERS_UPDATE:
      return {
        ...state,
        ...action.tableFilters
      };
    default:
      return state;
  }
};

export default userTableFilters;
