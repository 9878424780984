import React, { useState } from 'react';
import classNames from 'classnames';
import './Panel.scss';

export type DivProps = React.HTMLAttributes<HTMLDivElement>;

export type PanelProps = {
  fullheight?: boolean,
  scrollable?: boolean,
  padded?: boolean,
  rounded?: 'top' | 'right' | 'none'
} & DivProps;

export const Panel = ({
  children, className = '', style = undefined, fullheight = false, scrollable = false, padded = false, rounded = 'top'
}: PanelProps) => {
  const wrappedClass = classNames({
    [`panel panel-rounded-${rounded}`]: true,
    'main-content-height': fullheight,
    'panel-scrollable': scrollable,
    'panel-content-padded': padded,
    [className]: className,
  });

  return (
    <div className={wrappedClass} style={style}>
      {children}
    </div>);
};

export const PanelHeader = ({ children, className }: DivProps) => {
  let wrappedClass = "panel-header";
  if (className) {
    wrappedClass += ` ${className}`;
  }

  return (
    <div className={wrappedClass}>
      {children}
    </div>);
};

export type PanelContentProps = {
  scrollable?: boolean,
} & DivProps;

export const PanelContent = ({ children, className = '', style = undefined, scrollable = false, ...rest }: PanelContentProps) => {
  const wrappedClass = classNames({
    'panel-content': true,
    'panel-content-scrollable': scrollable,
    [className]: className,
  });

  return (<div className={wrappedClass} style={style} {...rest}>
    {children}
  </div>);
};

export type PanelPlainProps = {
  fullheight?: boolean,
} & DivProps;

export const PanelPlain = ({ children, className = '', fullheight }: PanelPlainProps) => {
  const wrappedClass = classNames({
    'panel': true,
    'panel-full-height': fullheight,
    [className]: className,
  });

  return (
    <div className={wrappedClass}>
      <PanelContent>
        {children}
      </PanelContent>
    </div>);
};

export type PanelDetailProps = {
  fullheight?: boolean,
} & DivProps;

export const PanelDetail = ({ children, className, fullheight }: PanelDetailProps) => {
  let wrappedClass = "container";
  if (className) {
    wrappedClass += ` ${className}`;
  }

  return (<PanelPlain fullheight={fullheight} className={wrappedClass}>{children}</PanelPlain>);
};

export const PanelFilter = ({ children, className }: DivProps) => {
  let wrappedClass = "panel-filter";
  if (className) {
    wrappedClass += ` ${className}`;
  }

  return (<div className={wrappedClass}>
    {children}
  </div>);
};

export const PanelActions = ({ children, ...rest }: DivProps) => {
  const wrappedClass = classNames({
    actions: true,
  });
  return (<div className={wrappedClass} {...rest}>
    {children}
  </div>);
};

export type PanelRootActionProps = {
  onRemove: () => void,
} & DivProps;

export const PanelRootActions = ({ children, className, onRemove }: PanelRootActionProps) => {
  const [opened, setOpened] = useState(false);
  const wrappedClass = classNames({
    'root-actions': true,
    "className": !className
  });
  return (<div className={wrappedClass}>
    <div>
      {!opened && <span onClick={() => setOpened(!opened)} className={`k-icon k-i-arrow-chevron-left`}>&nbsp;</span>}
      {opened && <span onClick={onRemove} className={`k-icon k-i-x`}>&nbsp;</span>}
      {opened && <span onClick={() => setOpened(!opened)} className={`k-icon k-i-arrow-chevron-right`}>&nbsp;</span>}
    </div>
    {children}
  </div>);
};
