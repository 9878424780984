import * as types from '../actions/actionTypes';
import { agendaInitialState } from './initialStates';

const agenda = (state = agendaInitialState, action) => {
  switch (action.type) {
    case types.AGENDA_USERS_FETCH_SUCCESS: {
      return {
        ...state,
        users: [...action.data.items]
      };
    }
    case types.AGENDA_PARTNERS_FETCH_SUCCESS: {
      return {
        ...state,
        partners: [...action.data.items]
      };
    }
    case types.AGENDA_MAIL_FETCH_SUCCESS: {
      return {
        ...state,
        mail: { ...action.data }
      };
    }
    case types.AGENDA_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case types.AGENDA_CLOUD_DRIVE_FETCH_SUCCESS: {
      return {
        ...state,
        cloudDrive: { ...action.data }
      };
    }
    case types.AGENDA_CLOUD_DRIVES_FETCH_SUCCESS: {
      return {
        ...state,
        cloudDrives: [...action.data.items]
      };
    }
    default:
      return state;
  }
};

export default agenda;
