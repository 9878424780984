import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import Translation from '../../common/Translation';
import SubmitButton from '../../common/SubmitButton';
import { navigateToSignUpCompany } from '../../../actions/navigationActions';

const PaymentCanceled = ({ token, goToSignUp }) => {
  return (
    <div className="box-wrapper">
      <div className="box-header-group">
        <div className="box-header"><Translation tKey="PaidCanceled" /></div>
        <div><Translation tKey="PaidCanceledMessage" /></div>
      </div>
      <div className="box-actions row-margin">
        <div className="actions">
          <SubmitButton onClick={(e) => {
            e.preventDefault();
            goToSignUp(token);
          }}>
            <Translation tKey="Pay" />
          </SubmitButton>
        </div>
      </div>
      <Translation tKey="ThankYou" />
    </div>
  );
};

PaymentCanceled.propTypes = {
  goToSignUp: PropTypes.func.isRequired,
  token: PropTypes.string
};

const mapDispatchToProps = (dispatch) => {
  return {
    goToSignUp: bindActionCreators(navigateToSignUpCompany, dispatch),
  };
};

export default connect(state => state, mapDispatchToProps)(PaymentCanceled);
