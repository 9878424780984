import PropTypes from 'prop-types';
import React from 'react';
import Translation from '../common/Translation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as partnerInvitationActions from '../../actions/partnerInvitationActions';
import { navigateTo } from '../../actions/navigationActions';
import queryString from 'query-string';
import { withRouter } from 'react-router';
import AnonymousZone from '../anonymousZone/AnonymousZone';
import SubmitButton from '../common/SubmitButton';
import { NAVIGATION_PATHS } from '../../common/data/routes';

class PartnerUpdateInvitationStateByToken extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      reject: false,
      partnerInvitationAccepted: undefined,
      partnerInvitationRejected: undefined
    };
  }

  componentDidMount() {
    let { accept } = this.props;
    let { reject } = this.props;

    if (this.props.token !== undefined && accept) {
      this.props.actions.acceptInvitationPartner(this.props.token);
    }

    if (this.props.token !== undefined && reject) {
      this.props.actions.rejectInvitationPartner(this.props.token);
    }
  }

  renderLoading = () => {
    return (
      <div>
        <p>
          <span className="k-icon k-i-loading">&nbsp;</span>&nbsp;<Translation tKey="ComunicationWithServer" />
        </p>
      </div>);
  };

  renderAccepted = () => {
    return (
      <div>
        <p>
          <Translation tKey="PartnerInvitationWasAccepted" />
        </p>
      </div>);
  };

  renderRejected = () => {
    return (
      <div>
        <p>
          <Translation tKey="PartnerInvitationWasRejected" />
        </p>
      </div>);
  };

  renderSetUpIsNotPossible = () => {
    return (
      <div>
        <p>
          <Translation tKey="PartnerInvitationWasNotPossibleToSetUp" />
        </p>
      </div>
    );
  };

  render() {
    let { loading } = this.props;
    let { accept } = this.props;
    let { reject } = this.props;
    let { partnerInvitationAccepted } = this.props;
    let { partnerInvitationRejected } = this.props;
    return (
      <AnonymousZone>
        <div className="box-wrapper">
          {loading && this.renderLoading()}
          {!loading && accept && partnerInvitationAccepted && this.renderAccepted()}
          {!loading && reject && partnerInvitationRejected && this.renderRejected()}
          {!loading && reject && !partnerInvitationRejected && this.renderSetUpIsNotPossible()}
          {!loading && accept && !partnerInvitationAccepted && this.renderSetUpIsNotPossible()}
          <div className="box-actions">
            <div className="actions">
              <SubmitButton onClick={() => this.props.actions.navigateTo(NAVIGATION_PATHS.SIGN_IN)} loading={this.props.loading}>
                <Translation tKey="ReturnToSignIn" />
              </SubmitButton>
            </div>
          </div>
        </div>
      </AnonymousZone>
    );
  }
}

PartnerUpdateInvitationStateByToken.propTypes = {
  token: PropTypes.string,
  actions: PropTypes.object,
  accept: PropTypes.bool,
  reject: PropTypes.bool,
  partnerInvitationAccepted: PropTypes.bool,
  partnerInvitationRejected: PropTypes.bool,
  loading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  let { partnerInvitation } = state;
  let { partnerInvitationAccepted } = partnerInvitation;
  let { partnerInvitationRejected } = partnerInvitation;

  return {
    token: queryString.parse(ownProps.location.search).token,
    loading: state.ajaxStatus.totalTriggers > 0,
    partnerInvitationAccepted: partnerInvitationAccepted,
    partnerInvitationRejected: partnerInvitationRejected
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...partnerInvitationActions, navigateTo }, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartnerUpdateInvitationStateByToken));
