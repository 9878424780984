import React from 'react';

import Translation from './Translation';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import './LegalInfo.scss';

const LegalInfo = () => {
  return (
    <div className="legal-info">
      <div className="legal-info-item">
        <a href={NAVIGATION_PATHS.COOKIES_INFO} target="_blank" rel="noreferrer" className="hoverable">
          <Translation tKey="LegalInfo.Cookies" />
        </a>
      </div>
      <div className="legal-info-item">
        <a href={NAVIGATION_PATHS.GDPR} target="_blank" rel="noreferrer" className="hoverable">
          <Translation tKey="LegalInfo.PrivacyPolicy" />
        </a>
      </div>
      <div className="legal-info-item">
        <a href={NAVIGATION_PATHS.TERMS_AND_CONDITIONS} target="_blank" rel="noreferrer" className="hoverable">
          <Translation tKey="LegalInfo.TermsAndConditions" />
        </a>
      </div>
    </div >
  );
};

export default LegalInfo;
