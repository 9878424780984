import React from "react";
import * as PropTypes from "prop-types";

const ErrorValidationMessage = ({ errorMessage }) => {
  return (
    <div className="notification-error" data-testid="validation-error">{errorMessage}</div>
  );
};

ErrorValidationMessage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default ErrorValidationMessage;
