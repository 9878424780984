import React, { useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';

import { toggleAccountMenu, closeAccountMenu, toggleAccountMenuSelectItem } from '../../actions/accountMenuActions';
import { signOut } from '../../actions/userActions';
import { fetchMyAccount } from '../../actions/myAccountActions';
import { fetchBalance } from '../settings/billing/actions';
import Translation from './Translation';
import { Link } from 'react-router-dom';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import { PERMISSION_TYPES } from '../../common/types/permissionTypes';
import { SCREEN_RESOLUTION_TYPES } from '../../common/types/screenResolutionTypes';
import TitledComponent from './utils/TitledComponent';
import NumberFormatter from './NumberFormatter';
import './AccountMenu.scss';
import { RootState } from '../../common/types/reduxStateTypes';

const AccountMenu = ({
  open,
  email,
  name,
  permissions,
  resolution,
  balanceAmount,
  userAuthorised: isUserAuthorised,
  fetchMyAccount,
  fetchBalance,
  toggleAccountMenu,
  closeAccountMenu,
  toggleAccountMenuSelectItem,
  signOut
}: AccountMenuProps) => {
  const toggleElementRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!name && isUserAuthorised) {
      fetchMyAccount();
    }
  }, [isUserAuthorised]);

  useEffect(() => {
    if (open) {
      fetchBalance();
    }
  }, [open, fetchBalance]);

  useEffect(() => {
    const autoCloseMenuHandler = (event: MouseEvent) => {
      if (open) {
        const toggleElement = toggleElementRef.current;
        if (toggleElement?.isSameNode(event.target as Node)) {
          return;
        }
        closeAccountMenu();
      }
    };

    document.addEventListener('click', autoCloseMenuHandler);
    return () => {
      document.removeEventListener('click', autoCloseMenuHandler);
    };
  });

  const handleSignOut = (event: React.MouseEvent) => {
    event.preventDefault();
    signOut();
  };

  const renderPayments = () => {
    if (!permissions.includes(PERMISSION_TYPES.MANAGE_PAYMENTS)) {
      return;
    }

    return (<li>
      <Link to={NAVIGATION_PATHS.SETTINGS_BILLING} onClick={toggleAccountMenuSelectItem}>
        <span className="icon icon-credit-card">&nbsp;</span>
        <Translation tKey="MyCredit" />
      </Link>
    </li>);
  };

  const renderCompany = () => {
    if (!permissions.includes(PERMISSION_TYPES.MANAGE_COMPANY)) {
      return;
    }

    return (
      <li>
        <Link to={NAVIGATION_PATHS.SETTINGS_COMPANY} onClick={toggleAccountMenuSelectItem}>
          <span className="icon icon-building">&nbsp;</span>
          <Translation tKey="ManageCompany.Menu" />
        </Link>
      </li>
    );
  };

  const isNotExtraSmallScreen = resolution !== SCREEN_RESOLUTION_TYPES.XS;

  let accountMenu = "account-menu";

  if (open) {
    accountMenu += " account-menu-opened";
  }

  let balanceClassWrapper;
  if (balanceAmount === 0) {
    balanceClassWrapper = 'account-menu-balance-empty';
  } else if (balanceAmount < 0) {
    balanceClassWrapper = 'account-menu-balance-not-enough';
  } else {
    balanceClassWrapper = 'account-menu-balance-enough';
  }

  return (
    <div className="header-tile">
      <a href={NAVIGATION_PATHS.ROOT}
        ref={toggleElementRef}
        className="account-email"
        onClick={(event) => {
          event.preventDefault();
          toggleAccountMenu();
        }}>
        {TitledComponent(<span className="icon icon-user-circle" />, 'Tooltips.Icons.LoggedInUser')}
        {isNotExtraSmallScreen && email}
      </a>
      <div className={accountMenu}>
        <ul>
          <li className="account-menu-header">
            <ul>
              <li><span className="icon icon-user-circle" /></li>
              <li><span className="username">{name}</span></li>
              <li>{email}</li>
              {!_.isNil(balanceAmount) &&
                <li><span className="account-menu-balance"><Translation tKey="Credit" />:&nbsp;
                  <span className={balanceClassWrapper}>
                    <NumberFormatter amount={balanceAmount} format="n2" />
                  </span></span>
                </li>
              }
            </ul>
          </li>
          <li>
            <Link to={NAVIGATION_PATHS.SETTINGS_MY_ACCOUNT} onClick={toggleAccountMenuSelectItem}>
              <span className="icon icon-user-circle">&nbsp;</span>
              <Translation tKey="MyAccount.Title" />
            </Link>
          </li>
          {renderCompany()}
          {renderPayments()}
          <li>
            <Link to={NAVIGATION_PATHS.ROOT} onClick={(event) => handleSignOut(event)}>
              <span className="icon icon-sign-out">&nbsp;</span>
              <Translation tKey="SignOut" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { firstname, lastname } = state.myAccount;
  return {
    open: state.accountMenu.open,
    resolution: state.app.resolution,
    email: state.user.email,
    name: lastname ? `${firstname} ${lastname}` : '',
    permissions: state.user.permissions,
    balanceAmount: state.billing?.balance?.amount || 0,
    userAuthorised: state.user.isAuth,
  };
};

const mapDispatchToProps = {
  fetchMyAccount,
  fetchBalance,
  toggleAccountMenu,
  closeAccountMenu,
  toggleAccountMenuSelectItem,
  signOut
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AccountMenuProps = ConnectedProps<typeof connector>;

export default connector(AccountMenu);
