import { ErrorPanel } from "./ErrorBoundary";
import LegalInfo from "./LegalInfo";

export const NotFoundInnerPanel = () => (
  <div>
    <ErrorPanel headerText="NotFoundError.Header" mainInfo="NotFoundError.MainInfo" inApp />
    <LegalInfo />
  </div>
);

export const NotFoundPanel = () => (
  <ErrorPanel headerText="NotFoundError.Header" mainInfo="NotFoundError.MainInfo" inApp={false} />
);
