import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SideBarMenuItem from './SideBarMenuItem';

class NavGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.path.includes(this.props.to)
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path) {
      this.setState({ isOpen: this.props.path.includes(this.props.to) });
    }
  }

  onClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  renderLink() {
    const isSubmenuOpen = this.state.isOpen;
    return (
      <a onClick={() => this.onClick()} className="nav-group-label">
        <span className={`icon ${this.props.icon}`} />
        <span className="sidebar-item-label">
          {this.props.title}
        </span>
        <span className={`icon icon-manage ${isSubmenuOpen ? 'icon-down' : 'icon-right'}`} />
      </a>
    );
  }

  renderLabel() {
    if (this.props.notificationPath) {
      return (<SideBarMenuItem path={this.props.notificationPath}>
        {this.renderLink()}
      </SideBarMenuItem>);
    }

    return this.renderLink();
  }

  render() {
    let wrapperClass = "has-children";
    const inUrl = this.props.path.includes(this.props.to);
    if (this.state.isOpen && inUrl) {
      wrapperClass += " opened";
    } else if (this.state.isOpen && !inUrl) {
      wrapperClass += " hover";
    } else if (!this.state.isOpen && inUrl) {
      wrapperClass += " closed";
    }

    return (
      <li className={wrapperClass}>
        {this.renderLabel()}
        <ul>
          {this.props.children}
        </ul>
      </li>
    );
  }
}

NavGroup.propTypes = {
  to: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  children: PropTypes.array.isRequired,
  icon: PropTypes.string.isRequired
};

export default NavGroup;
