import React from 'react';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/userActions';
import TextBox from '../common/TextBox';
import SubmitButton from '../common/SubmitButton';
import Translation from '../common/Translation';
import { validateFieldLength, validateEmail } from '../generalFunctions';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import Captcha from '../common/Captcha';
import AnonymousZone from './AnonymousZone';

class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      sent: false,
      errors: {},
      captcha: ''
    };
  }

  componentDidMount() {
    this.props.actions.clear();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.emailSent !== this.props.emailSent) {
      this.setState({ sent: this.props.emailSent });
    }

    if (prevProps.isCaptchaVerified !== this.props.isCaptchaVerified) {
      if (!this.props.isCaptchaVerified) {
        this.captcha.reset();
        this.setState({ captcha: '' });
      }
    }
  }

  resetPassword = (event) => {
    event.preventDefault();

    if (this.formIsValid()) {
      let { email, captcha } = this.state;
      this.props.actions.resetPassword({ email, captcha });
    }
  };

  formIsValid = () => {
    let { t } = this.props;
    let options = {
      errors: {},
      formIsValid: true,
      data: this.state,
      t
    };

    options.formIsValid = validateFieldLength({ ...options, field: 'email' });

    if (!validateEmail(this.state.email)) {
      options.errors.email = t('EmailIsnotCorrect');
      options.formIsValid = false;
    }

    this.setState({ errors: options.errors });
    return options.formIsValid;
  };

  renderInstructions = () => {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="WhatHasHappened" /></div>
          <div><Translation tKey="ResetPasswordSent" /></div>
        </div>
        <div className="links">
          <div>
            <Link to={NAVIGATION_PATHS.SIGN_IN} className="text-link underlined"><Translation tKey="DoYouKnowYourPassword" /></Link>
          </div>
        </div>
      </div>
    );
  };

  renderForm = () => {
    let { t } = this.props;
    return (
      <>
        <div className="box-wrapper">
          <div className="box-header-group">
            <div className="box-header"><Translation tKey="NeedHelpWithPassword" /></div>
            <div><Translation tKey="EnterEmailToCreatePassword" /></div>
          </div>
        </div>
        <form>
          <div className="row-margin">
            <div className="email">
              <TextBox
                name="email"
                onChange={(e) => {this.setState({ email: e.target.value }); }}
                value={this.state.email}
                error={this.state.errors.email}
                placeholder={t('Email')} />
            </div>
          </div>
          <div className="row-margin">
            <div className="captcha">
              <Captcha ref={(el) => { this.captcha = el; }} onChange={(event) => {
                this.setState({ captcha: event.target.value });
              }} name="captcha" />
            </div>
          </div>
          <div className="box-actions">
            <div className="actions">
              <SubmitButton onClick={this.resetPassword} loading={this.props.loading} disabled={!this.state.captcha} >
                <Translation tKey="PasswordResetGo" />
              </SubmitButton>
            </div>
            <div className="links">
              <Link className="text-link underlined" to={NAVIGATION_PATHS.SIGN_IN}><Translation tKey="SignInGo" /></Link>
            </div>
          </div>
        </form>
      </>
    );
  };

  render() {
    let { sent } = this.state;
    return (
      <AnonymousZone showRegistration>
        <div className="box-wrapper">
          {!sent && this.renderForm()}
          {sent && this.renderInstructions()}
        </div>
      </AnonymousZone>
    );
  }
}

ForgottenPassword.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  emailSent: PropTypes.bool
};

const mapStateToProps = (state) => {
  let { user: { forgottenPasswordSent: { isSent, isCaptchaVerified } = { isSent: false, isCaptchaVerified: false } } } = state;

  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    emailSent: isSent,
    isCaptchaVerified
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(ForgottenPassword);
