
import cs from '../../images/FlagCzechRepublic.svg';
import en from '../../images/FlagUnitedKingdom.svg';

export const LANG_CODES = {
  cs: 'cs',
  en: 'en',
};

export const cultureNames = {
  en: 'en-US',
  cs: 'cz-CS',
};

export const languageData = [
  { text: 'Čeština', name: LANG_CODES.cs, value: LANG_CODES.cs, icon: cs },
  { text: "English", name: LANG_CODES.en, value: LANG_CODES.en, icon: en },
];

export const languages = [
  { text: 'Čeština', shortText: 'cs', value: 'cs', icon: cs },
  { text: "English", shortText: 'en', value: 'en', icon: en },
];
