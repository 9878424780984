import axios from 'axios';
import _ from 'lodash';

import * as types from './actionTypes';
import { getDateByDaysAgo } from '../components/common/utils/DateHelper';
import { API_PATHS } from '../common/data/routes';
import { loadState, savePartialState } from '../components/common/LocalStorage';
import { LONGEST_NOTIFICATION_PERIOD_IN_DAYS } from '../settings';

export const fetchNotifications = (lastFetched) => (dispatch, getState) => {
  const initLoading = !lastFetched;
  const currentDate = new Date().toISOString();
  let dateOfLastNotify = currentDate;
  const { userAppSettings } = getState();
  const lastSeenNotifyDates = userAppSettings.data.lastSeenNotifyDates;

  if (!lastSeenNotifyDates) {
    return;
  }

  const lastSeenDateList = Object.values(lastSeenNotifyDates);
  if (lastSeenDateList.length > 0) {
    if (initLoading) {
      const minPossibleDate = getDateByDaysAgo(new Date(), LONGEST_NOTIFICATION_PERIOD_IN_DAYS).toISOString();
      const minDate = _.minBy(lastSeenDateList, date => new Date(date).getTime());
      dateOfLastNotify = _.maxBy([minDate, minPossibleDate], date => new Date(date).getTime());
    } else {
      dateOfLastNotify = lastFetched;
    }
  }

  dispatch({
    types: [types.NOTIFICATIONS_FETCH_REQUEST, types.NOTIFICATIONS_FETCH_SUCCESS, types.NOTIFICATIONS_FETCH_FAILURE],
    callAPI: () => {
      const notificationPath = initLoading ? API_PATHS.EVENT_NOTIFICATIONS_WITHOUT_PROCESSED : API_PATHS.EVENT_NOTIFICATIONS;
      return axios.get(notificationPath + '?filters[0][member]=dateOfCreate&filters[0][operator]=gte&filters[0][value]=' + dateOfLastNotify);
    },
    payload: { initLoading, lastSeenNotifyDates }
  });
};

export const sawNotifications = (path) => (dispatch) => {
  return dispatch({ type: types.NOTIFICATIONS_SAW, path });
};

export const sawNotification = (id, path) => (dispatch) => {
  return dispatch({ type: types.NOTIFICATION_SAW, id, path });
};

export const clearNotifications = (path) => (dispatch) => {
  return dispatch({ type: types.NOTIFICATIONS_CLEAR, path });
};

export const readLog = (log) => (dispatch) => {
  let { notification } = loadState() || {};
  let read = [];
  if (notification && notification.read) {
    read = notification.read;
  }

  let date = new Date();
  date.setDate(date.getDate() - 30);
  read = read.filter(_log => new Date(_log.date) >= date);
  if (read.find(_log => _log.id === log.id) === undefined) {
    read.push(log);
  }

  savePartialState({ notification: { ...notification, read } });

  return dispatch({ type: types.NOTIFICATIONS_READ, read });
};

export const fetchNotificationsSetting = () => (dispatch) => {
  dispatch({
    types: [types.NOTIFICATIONS_SETTING_FETCH_REQUEST, types.NOTIFICATIONS_SETTING_FETCH_SUCCESS, types.NOTIFICATIONS_SETTING_FETCH_FAILURE],
    callAPI: () => {
      const apiPath = API_PATHS.AGENDA_SETTINGS_BY_CATEGORY.replace('[:category]', String('NotificationsSettings'));

      return axios.get(apiPath);
    }
  });
};
