export const COMPANY_VERIFICATION_TYPE = {
  NotSet: 0,
  ReadyToBeSent: 1,
  Send: 2,
  SentFailed: 3,
  Verified: 4,
  VerificationFailed: 5,
  Locked: 6,
  PaymentCanceled: 7,
  PaymentPending: 8,
  Invalid: 9,
  VerifiedAndRedirect: 10
};
