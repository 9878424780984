import { createInstance } from 'i18next';
import { loadState } from './components/common/LocalStorage';

import commonCs from './locales/cs/common.json';
import commonEn from './locales/en/common.json';

const persistedState = loadState();
const initialLanguage = persistedState?.app?.lang ?? 'cs';

const i18n = createInstance();
i18n.init({
  interpolation: {
    escapeValue: false,
  },
  lng: initialLanguage,
  debug: process.env.NODE_ENV !== 'production',
  ns: 'common',
  defaultNS: 'common',
  fallbackLng: 'cs',
  preload: true,
  resources: {
    cs: {
      common: commonCs
    },
    en: {
      common: commonEn
    }
  },
  initImmediate: false,
  react: {
    useSuspense: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
    defaultTransParent: ''
  }
});

export default i18n;
