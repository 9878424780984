import * as types from './actionTypes';
import { loadState, savePartialState } from '../components/common/LocalStorage';
import { setUserWorkflowFilterSettings } from './userAgendaSettingsActions';
import i18n from '../i18n';

export const setLang = (lang) => (dispatch) => {
  if (lang === i18n.language) {
    return;
  }

  i18n.changeLanguage(lang);

  return dispatch({
    type: types.APP_CHANGE_LANG,
    lang
  });
};

export const setResolution = (resolution) => (dispatch) => {
  return dispatch({
    type: types.APP_RESOLUTION,
    resolution
  });
};

export const setCurrentPage = (currentPage) => (dispatch) => {
  return dispatch({
    type: types.APP_CURRENT_PAGE,
    currentPage
  });
};

export const setViewMode = (viewMode, forced = false) => (dispatch) => {
  if (forced) {
    let persistedState = loadState();
    savePartialState({ app: { ...persistedState.app, viewMode, viewModeForced: viewMode } });

    return dispatch({
      type: types.APP_VIEW_MODE,
      viewMode,
      forced
    });
  } else {
    return dispatch({
      type: types.APP_VIEW_MODE,
      viewMode
    });
  }
};

export const setGridMode = (isCompact) => (dispatch) => {
  return dispatch({
    type: types.APP_GRID_MODE,
    isCompact
  });
};

export const setHasUnsavedChanges = (hasUnsavedChanges) => (dispatch) => {
  return dispatch({
    type: types.APP_HAS_UNSAVED_CHANGES,
    hasUnsavedChanges
  });
};

export const setNextLocation = (nextLocation) => (dispatch) => {
  return dispatch({
    type: types.APP_NEXT_LOCATION,
    nextLocation
  });
};
