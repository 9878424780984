import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Trans } from 'react-i18next';

import Button from '../common/Button';
import * as types from '../../actions/actionTypes';
import { signOut } from '../../actions/userActions';
import Translation from '../common/Translation';
import './Dialog.scss';

class NeedSignoutDialog extends Component {
  toggleDialog = () => {
    this.props.close();
  };

  getModalDialog = () => {
    let company = this.props.text;
    return (
      <div className="dialog">
        <Dialog title={this.props.t('WarningDialog.Title')} onClose={this.toggleDialog} width={400}>
          <div style={{ textAlign: "justify" }}>
            <Trans
              i18nKey="common:NeedSignoutDialog:Text"
              values={{ company }}
              components={[<strong key={0}>dummyContent</strong>]} /></div>
          <DialogActionsBar layout="start">
            <Button onClick={this.toggleDialog}><Translation tKey="SignInGo" /></Button>
          </DialogActionsBar>
        </Dialog>
      </div>
    );
  };

  render() {
    if (this.props.open) {
      const modalWindow = this.getModalDialog();
      const content = document && document.querySelector('.content');

      return content ? ReactDOM.createPortal(modalWindow, content) : modalWindow;
    }

    return null;
  }
}

NeedSignoutDialog.propTypes = {
  open: PropTypes.bool
};

const mapStateToProps = (state) => {
  let { app: { needSignoutDialog }, user: { agendas } } = state;
  let text = '';

  if (agendas) {
    let { items } = agendas;
    if (items) {
      let agenda = items.find((item) => item.value === needSignoutDialog.agendaId);
      if (agenda) {
        text = agenda.text;
      }
    }
  }

  return {
    open: needSignoutDialog.open,
    text
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => {
      dispatch({ type: types.APP_NEED_SIGNOUT_DIALOG_CLOSE });

      dispatch(signOut());
    }
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(NeedSignoutDialog);
