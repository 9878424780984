import { ArchivePackageDownloadStateEnum, ArchivePackageStateEnum } from 'common/types/archivePackageTypes';
import {
  ARCHIVE_DOCUMENT_STATE, ARCHIVE_ACTION
} from '../types/archiveTypes';
import { getTKeyByValue } from './common';

export const archiveActions = [
  { tKey: 'Archive.Actions.Create', value: ARCHIVE_ACTION.Create },
  { tKey: 'Archive.Actions.SetDocumentType', value: ARCHIVE_ACTION.SetDocumentType },
  { tKey: 'Archive.Actions.SetState', value: ARCHIVE_ACTION.SetState },
  { tKey: 'Archive.Actions.SetDocumentName', value: ARCHIVE_ACTION.SetDocumentName },
  { tKey: 'Archive.Actions.SetDateOfCreateInErp', value: ARCHIVE_ACTION.SetDateOfCreateInErp },
  { tKey: 'Archive.Actions.SetDateOfAccountingCase', value: ARCHIVE_ACTION.SetDateOfAccountingCase },
  { tKey: 'Archive.Actions.SetDateOfTaxableSupply', value: ARCHIVE_ACTION.SetDateOfTaxableSupply },
  { tKey: 'Archive.Actions.SetDueDate', value: ARCHIVE_ACTION.SetDueDate },
  { tKey: 'Archive.Actions.SetDateOfIssue', value: ARCHIVE_ACTION.SetDateOfIssue },
  { tKey: 'Archive.Actions.SetAccountingLine', value: ARCHIVE_ACTION.SetAccountingLine },
  { tKey: 'Archive.Actions.SetAccountingAssignment', value: ARCHIVE_ACTION.SetAccountingAssignment },
  { tKey: 'Archive.Actions.SetVatType', value: ARCHIVE_ACTION.SetVatType },
  { tKey: 'Archive.Actions.SetReverseCharge', value: ARCHIVE_ACTION.SetReverseCharge },
  { tKey: 'Archive.Actions.SetAccountingYear', value: ARCHIVE_ACTION.SetAccountingYear },
  { tKey: 'Archive.Actions.SetActivity', value: ARCHIVE_ACTION.SetActivity },
  { tKey: 'Archive.Actions.SetCenter', value: ARCHIVE_ACTION.SetCenter },
  { tKey: 'Archive.Actions.SetOrder', value: ARCHIVE_ACTION.SetOrder },
  { tKey: 'Archive.Actions.SetDocumentNumberInErp', value: ARCHIVE_ACTION.SetDocumentNumberInErp },
  { tKey: 'Archive.Actions.SetCurrency', value: ARCHIVE_ACTION.SetCurrency },
  { tKey: 'Archive.Actions.SetTotalAmount', value: ARCHIVE_ACTION.SetTotalAmount },
  { tKey: 'Archive.Actions.SetCompanyIdentificationNumber', value: ARCHIVE_ACTION.SetCompanyIdentificationNumber },
  { tKey: 'Archive.Actions.SetPartnerName', value: ARCHIVE_ACTION.SetPartnerName },
  { tKey: 'Archive.Actions.CreateInvoiceFromWorkflowRecord', value: ARCHIVE_ACTION.CreateInvoiceFromWorkflowRecord },
  { tKey: 'Archive.Actions.CreateWorkflowRecord', value: ARCHIVE_ACTION.CreateWorkflowRecord },
  { tKey: 'Archive.Actions.CreateMainDocument', value: ARCHIVE_ACTION.CreateMainDocument },
  { tKey: 'Archive.Actions.CreateAttachment', value: ARCHIVE_ACTION.CreateAttachment },
  { tKey: 'Archive.Actions.TimeStampMainDocument', value: ARCHIVE_ACTION.TimeStampMainDocument },
  { tKey: 'Archive.Actions.TimeStampAttachment', value: ARCHIVE_ACTION.TimeStampAttachment },
  { tKey: 'Archive.Actions.SetScenario', value: ARCHIVE_ACTION.SetScenario },
  { tKey: 'Archive.Actions.AgreementProcess', value: ARCHIVE_ACTION.AgreementProcess },
  { tKey: 'Archive.Actions.AutomaticAgreementProcess', value: ARCHIVE_ACTION.AutomaticAgreementProcess },
  { tKey: 'Archive.Actions.MoveToBin', value: ARCHIVE_ACTION.MoveToBin },
  { tKey: 'Archive.Actions.Parsing', value: ARCHIVE_ACTION.Parsing },
  { tKey: 'Archive.Actions.ImportToErp', value: ARCHIVE_ACTION.ImportToErp },
  { tKey: 'Archive.Actions.ManuallyCompleteInDesktop', value: ARCHIVE_ACTION.ManuallyCompleteInDesktop },
  { tKey: 'Archive.Actions.RejectInWorkflow', value: ARCHIVE_ACTION.RejectInWorkflow },
  { tKey: 'Archive.Actions.RejectInDesktop', value: ARCHIVE_ACTION.RejectInDesktop },
  { tKey: 'Archive.Actions.ReturnToStartInWorkflow', value: ARCHIVE_ACTION.ReturnToStartInWorkflow },
  { tKey: 'Archive.Actions.RemoveFromBin', value: ARCHIVE_ACTION.RemoveFromBin },
  { tKey: 'Archive.Actions.TerminateInWorkflow', value: ARCHIVE_ACTION.TerminateInWorkflow },
  { tKey: 'Archive.Actions.AddAccountingJournalDocument', value: ARCHIVE_ACTION.AddAccountingJournalDocument },
  { tKey: 'Archive.Actions.CreateInvoice', value: ARCHIVE_ACTION.CreateInvoice },
  { tKey: 'Archive.Actions.SetContract', value: ARCHIVE_ACTION.SetContract },
  { tKey: 'Archive.Actions.SetIssuedOrder', value: ARCHIVE_ACTION.SetIssuedOrder },
  { tKey: 'Archive.Actions.CreateInvoiceFromWorkflowRecord', value: ARCHIVE_ACTION.CreateWorkflowInvoiceFromWorkflowRecord }
];

export const archiveDocumentStates = [
  { tKey: 'Archive.Documents.States.Processing', value: ARCHIVE_DOCUMENT_STATE.processing },
  { tKey: 'Archive.Documents.States.ImportedToErp', value: ARCHIVE_DOCUMENT_STATE.importedToErp },
  { tKey: 'Archive.Documents.States.ManuallyCompleted', value: ARCHIVE_DOCUMENT_STATE.manuallyCompleted },
  { tKey: 'Archive.Documents.States.RejectedInDesktop', value: ARCHIVE_DOCUMENT_STATE.rejectedInDesktop },
  { tKey: 'Archive.Documents.States.RejectedInWorkflow', value: ARCHIVE_DOCUMENT_STATE.rejectedInWorkflow },
  { tKey: 'Archive.Documents.States.SentToStartInWorkflow', value: ARCHIVE_DOCUMENT_STATE.sentToStartInWorkflow },
  { tKey: 'Archive.Documents.States.Removed', value: ARCHIVE_DOCUMENT_STATE.removed },
  { tKey: 'Archive.Documents.States.TerminatedInWorkflow', value: ARCHIVE_DOCUMENT_STATE.terminatedInWorkflow },
  { tKey: 'Archive.Documents.States.Error', value: ARCHIVE_DOCUMENT_STATE.error },
  { tKey: 'Archive.Documents.States.CompletedWithAccounting', value: ARCHIVE_DOCUMENT_STATE.completedWithAccounting },
  { tKey: 'Archive.Documents.States.CompletedWithImportToErp', value: ARCHIVE_DOCUMENT_STATE.completedWithImportToErp }
];


export const archivePackageStates = [
  { tKey: 'NotSet', value: ArchivePackageStateEnum.NotSet },
  { tKey: 'ArchivePackages.States.WaitForProcessing', value: ArchivePackageStateEnum.WaitForProcessing },
  { tKey: 'ArchivePackages.States.Preparing', value: ArchivePackageStateEnum.Preparing },
  { tKey: 'ArchivePackages.States.Ready', value: ArchivePackageStateEnum.Ready },
  { tKey: 'ArchivePackages.States.Expired', value: ArchivePackageStateEnum.Expired },
  { tKey: 'ArchivePackages.States.Error', value: ArchivePackageStateEnum.Error },
  { tKey: 'ArchivePackages.States.Canceled', value: ArchivePackageStateEnum.Canceled },
];

export const ArchivePackageDownloadingStates = [
  { tKey: 'NotSet', value: ArchivePackageDownloadStateEnum.NotSet },
  { tKey: 'ArchivePackages.Recipients.States.Wait', value: ArchivePackageDownloadStateEnum.Wait },
  { tKey: 'ArchivePackages.Recipients.States.Downloaded', value: ArchivePackageDownloadStateEnum.Downloaded },
];

export const getArchiveActionTKey = (action: number) => (
  getTKeyByValue(action, archiveActions, 'NotSet')
);

export const absentSign = '—';
