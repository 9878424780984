import classNames from 'classnames';
import PropTypes from 'prop-types';

import './TextBox.scss';

const TextBox = ({ placeholder, value, name, type, className, onChange, icon, error, style, testId, autoComplete, children }) => {
  const wrapperClass = classNames({
    'k-textbox text-box': true,
    [className]: className,
    'notification-input-error': error,
  });

  let wrapperType = `text`;
  if (type) {
    wrapperType = type;
  }

  const inputHtml = (<input
    className={wrapperClass}
    style={style}
    type={wrapperType}
    name={name}
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    autoComplete={autoComplete}
    data-testid={testId}
  />);

  if (icon) {
    return (
      <div className="text-box-wrapper">
        <span className={`icon icon-${icon}`}>&nbsp;</span>
        <div className={`input-container`}>
          {inputHtml}
          {children}
          {error && <div className={`notification-error`}>{error}</div>}
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-box-wrapper">
        {inputHtml}
        {children}
        {error && <div className={`notification-error`}>{error}</div>}
      </div>
    );
  }
};

TextBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

export default TextBox;
