import { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getUserAgendas, setAgenda } from '../../actions/userActions';
import { setHasUnsavedChanges } from '../../actions/appActions';
import DropDownList from './ResponsiveDropDown';
import ConfirmDialog, { ConfirmDialogRef } from './ConfirmDialog';
import { sortByLocalStrings } from '../generalFunctions';
import { RootState } from '../../common/types/reduxStateTypes';
import AgendaWithNotificationsDropDownList from './AgendaWithNotificationsDropDownList';

const Agenda = (props: AgendaProps) => {
  const { agendas, hasUnsavedChanges, userAuthorised, companyName, setAgenda, getUserAgendas, setHasUnsavedChanges } = props;
  const initialValue: AgendaType = { text: '', value: 0, totalDocumentsForApproval: null };
  const [value, setValue] = useState<AgendaType>(initialValue);
  const confirmDialog = useRef<ConfirmDialogRef>(null);

  const fetchDataCallback = (data: AgendasType) => {
    let { selectedValue, items } = data;

    if (selectedValue && items) {
      if (value.value !== selectedValue || value.text !== companyName) {
        const newValue = items.find((i) => i.value === selectedValue);
        if (newValue) {
          setValue(newValue);
        }
      }
    }
  };

  useEffect(() => {
    if (userAuthorised) {
      getUserAgendas(fetchDataCallback);
    }
  }, [userAuthorised]);

  useEffect(() => {
    if (companyName !== value.text) {
      getUserAgendas(fetchDataCallback);
    }
  }, [companyName]);

  useEffect(() => {
    const { items } = agendas;
    if (value) {
      const item = items.find(i => i.value === value.value);
      if (item) {
        setValue(item);
      }
    }
  }, [agendas.items]);

  const changeAgenda = (agendaValue: AgendaType) => {
    setValue(agendaValue);

    let { value } = agendaValue;
    if (value) {
      setAgenda(value, true);
    }
  };

  const handleChange = (e: any) => {
    const newAgenda = e.target.value;
    if (!hasUnsavedChanges) {
      changeAgenda(newAgenda);
    } else {
      confirmDialog.current?.setParameters({ newAgenda });
      confirmDialog.current?.openDialog();
    }
  };

  const handleConfirm = (params: { newAgenda: AgendaType }) => {
    setHasUnsavedChanges(false);
    changeAgenda(params.newAgenda);
  };

  let { items } = agendas;
  if (!items) {
    items = [];
  }

  return (
    <>
      <ConfirmDialog okAction={handleConfirm}
        tKey="ConfirmQuestions.NotSavedChanges"
        ref={confirmDialog}
      />
      <AgendaWithNotificationsDropDownList
        fillMode="flat"
        data={items}
        textField="text"
        dataItemKey="value"
        value={value}
        className="agenda-selection-authenticated"
        onChange={handleChange}
      />
    </>
  );
};

export type AgendaType = {
  value: number,
  text: string,
  additionalText?: string,
  totalDocumentsForApproval: number | null;
};

export type AgendasType = {
  items: AgendaType[],
  selectedValue: number,
}

const mapStateToProps = (state: RootState) => {
  let { user: { agendas } } = state;
  let items: AgendaType[] = [];

  if (agendas) {
    items = sortByLocalStrings(agendas.items, 'text');
  }

  return {
    agendas: { ...agendas, items },
    companyName: state.company.name,
    userAuthorised: state.user.isAuth,
    hasUnsavedChanges: state.app.hasUnsavedChanges,
  };
};

const mapDispatchToProps = {
  setAgenda,
  getUserAgendas,
  setHasUnsavedChanges,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type AgendaProps = ConnectedProps<typeof connector>;

export default connector(Agenda);
