import * as types from '../actions/actionTypes';

export const initialState = {
  isAuth: false,
  tokenExpireIn: 0,
  agendas: {
    items: [],
    selectedValue: 0,
  },
  permissions: [],
  email: '',
  userId: 0
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_SIGN_OUT:
      return {
        ...state,
        isAuth: false,
        tokenExpireIn: 0
      };
    case types.USER_PASSWORD_SET_SUCCESS:
      return {
        ...state,
        resetPassword: {
          isPasswordSecured: action.data.isPasswordSecured
        }
      };
    case types.USER_UPDATE_TOKEN_EXPIRATION:
      return {
        ...state,
        isAuth: true,
        tokenExpireIn: action.tokenExpireIn,
        permissions: action.permissions.split('|'),
        userId: action.userId,
        email: action.email
      };
    case types.USER_SIGN_UP_USER_SUCCESS:
      return {
        ...state,
        signUpUser: {
          emailIsSent: action.data.emailIsSent,
          emailIsUsed: action.data.emailIsUsed,
          isCaptchaVerified: action.data.isCaptchaVerified,
          isPasswordSecured: action.data.isPasswordSecured
        }
      };
    case types.USER_SIGN_UP_COMPLETE_USER_SUCCESS:
      return {
        ...state,
        signUpUserComplete: {
          isPasswordSecured: action.data.isPasswordSecured
        }
      };
    case types.USER_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmail: {
          goToPart: action.data.goToPart,
          partData: action.data.partData,
          invitations: action.data.invitations
        }
      };
    case types.USER_INVITATION_REMOVE_ROW: {
      let result = {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          invitations: state.verifyEmail.invitations.filter(i => { return action.id !== i.id; })
        }
      };
      return {
        ...result,
      };
    }
    case types.USER_CLEAR:
      return {
        ...initialState
      };
    case types.USER_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        forgottenPasswordSent: { ...action.data }
      };
    case types.USER_FETCH_AGENDAS_SUCCESS:
      return {
        ...state,
        agendas: { items: action.data.items, selectedValue: action.data.selectedValue }
      };
    case types.USER_GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        ares: {
          ...action.data.records
        }
      };
    case types.USER_COMPANY_INVITATIONS_SUCCESS:
      return {
        ...state,
        invitations: action.data.invitations
      };
    case types.USER_DOCUMENTS_FOR_APPROVAL_SUCCESS:
      const selectedAgenda = state.agendas.items.find(i => i.value === state.agendas.selectedValue);
      return {
        ...state,
        agendas: {
          items: [...state.agendas.items.filter(i => i.value !== selectedAgenda.value), { ...selectedAgenda, totalDocumentsForApproval: action.data.count }],
          selectedValue: state.agendas.selectedValue
        }
      };
    default:
      return state;
  }
};

export default user;
