import * as types from '../actions/actionTypes';

const alert = (state, action) => {
  switch (action.type) {
    case types.ALERTS_ADD:
      return {
        id: action.id,
        tKey: action.tKey,
        style: action.style,
        transform: action.transform,
        sticky: action.sticky
      };
    default:
      return state;
  }
};

const alerts = (state = [], action) => {
  switch (action.type) {
    case types.ALERTS_ADD:
      return [
        ...state,
        alert(state, action)
      ];
    case types.ALERTS_REMOVE: {
      let i = state.map(a => { return a.id; }).indexOf(action.id);
      if (i >= 0) {
        return [
          ...state.slice(0, i),
          ...state.slice(i + 1)
        ];
      } else {
        return [
          ...state
        ];
      }
    }
    case types.ALERTS_CLEAR:
      return [];
    default:
      return state;
  }
};

export default alerts;
