import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { setResolution, setGridMode } from '../../actions/appActions';
import { hideSidebar, showSidebar } from '../../actions/sideBarActions';
import { SIDEBAR_VIEW_MODES } from '../../common/types/sidebarTypes';
import { getCurrentResolution } from '../generalFunctions';
import { RootState } from '../../common/types/reduxStateTypes';

interface ScreenModeProperties {
  setResolution: (resolution: string) => void,
  setGridMode: (isCompact: boolean) => void,
  hideSidebar: () => void,
  showSidebar: () => void,
  resolution: string,
  sideBarViewMode: string
}

const ScreenMode = (props: ScreenModeProperties) => {
  const { resolution, setResolution, setGridMode, hideSidebar, showSidebar, sideBarViewMode } = props;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      handleResize();

      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  const handleResize = () => {
    if (window.innerWidth <= 1199) {
      hideSidebar();
    } else if (sideBarViewMode === SIDEBAR_VIEW_MODES.TEXT) {
      showSidebar();
    }

    if (window.innerWidth <= 1023) {
      setGridMode(true);
    } else {
      setGridMode(false);
    }

    const currentResolution = getCurrentResolution();
    if (resolution !== currentResolution) {
      setResolution(currentResolution);
    }
  };

  return (
    <></>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    resolution: state.app.resolution,
    sideBarViewMode: state.sideBar.viewMode
  };
};

const mapDispatchToProps = {
  setResolution,
  setGridMode,
  hideSidebar,
  showSidebar
};

export default connect(mapStateToProps, mapDispatchToProps)(ScreenMode);
