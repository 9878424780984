import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as alertActions from '../../actions/alertActions';
import { bindActionCreators } from 'redux';
import { NotificationGroup } from '@progress/kendo-react-notification';
import Notification from './Notification';

const Notifications = ({ alerts, actions }) => {
  const position = {
    right: 0,
    alignItems: 'flex-start',
    flexWrap: 'wrap-reverse',
  };

  return (
    <NotificationGroup style={position}>
      {alerts.map((alert) => (
        <Notification
          key={alert.id}
          tKey={alert.tKey}
          style={alert.style}
          transform={alert.transform}
          id={alert.id}
          remove={actions.alertRemove}
          sticky={alert.sticky}
        />)
      )}
    </NotificationGroup>
  );
};

Notifications.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({
    tKey: PropTypes.string,
    transform: PropTypes.object,
    id: PropTypes.string,
    alertRemove: PropTypes.func,
    style: PropTypes.object
  })),
  actions: PropTypes.shape({
    alertRemove: PropTypes.func
  })
};

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(alertActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);


