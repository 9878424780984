import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Translation from '../common/Translation';
import LegalInfo from '../common/LegalInfo';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import display from '../../images/display.webp';
import logo from '../../images/logo_dark.svg';
import './AnonymousZone.scss';
import Notifications from '../common/Notifications';
import { setResolution } from '../../actions/appActions';
import { getCurrentResolution } from '../generalFunctions';
import { RootState } from '../../common/types/reduxStateTypes';

interface AnonymousZoneProps {
  showRegistration?: boolean,
  resolution: string,
  setResolution: (resolution: any) => any,
  children: React.ReactNode,
}

const AnonymousZone: React.ComponentType<AnonymousZoneProps> = ({ children, showRegistration = false, resolution, setResolution }) => {
  useEffect(() => {
    const currentResolution = getCurrentResolution();
    if (resolution !== currentResolution) {
      setResolution(currentResolution);
    }
  }, []);

  const classWrapper = classNames({
    'anonymous-zone-wrapper': true,
    'mobile-version': isMobileOnly,
  });

  return (
    <div className={classWrapper}>
      <div className="anonymous-zone-content">
        <div className="anonymous-zone">
          <div className="anonymous-zone-header">
            <div className="logo"><img src={logo} alt="logo" /></div>
            {showRegistration &&
              <span className="datarios-link-button datarios-button-blue">
                <Link to={NAVIGATION_PATHS.SIGN_UP}><Translation tKey="SignUp" /></Link>
              </span>
            }
          </div>
          <div className="box">
            {children}
            <Notifications />
          </div>
        </div>
        <div className="general">
          <div className="general-content">
            <div className="general-registration">
              {showRegistration &&
                <>
                  <div className="general-no-account"><Translation tKey="DontHaveAnAccount" /></div>
                  <span className="datarios-link-button datarios-button-green">
                    <Link to={NAVIGATION_PATHS.SIGN_UP}><Translation tKey="SignUp" /></Link>
                  </span>
                </>
              }
            </div>
            <div className="general-mission"><Translation tKey="Mission" /></div>
            <div className="general-display"><img src={display} alt="logo-formats" /></div>
          </div>
        </div>
      </div>
      <LegalInfo />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  resolution: state.app.resolution,
});

const dispatchToProps = {
  setResolution,
};

export default connect(mapStateToProps, dispatchToProps)(AnonymousZone);
