import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';
import { ConnectedRouter } from 'connected-react-router';

import i18n from '../i18n';
import Layout from './app/Layout';
import { connection, isConnected } from '../signalR';
import { getUrlWithCulture } from '../components/generalFunctions';

// Default config for AXIOS HTTP client
axios.interceptors.request.use(function(config) {
  config.withCredentials = true;
  if (isConnected()) {
    config.headers[`SignalR-ConnectionId`] = `${connection.connectionId}`;
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

// Set default AXIOS url by type of enviroment
const url = process.env.NODE_ENV === 'production' ? window.location.host : window.location.hostname + ':8190/';
axios.defaults.baseURL = window.location.protocol + '//' + url;
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  config.url = getUrlWithCulture(config.url);
  return config;
}, null, { runWhen: config => config.method === 'get' });

const appHeight = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
};

window.addEventListener('resize', appHeight);
appHeight();

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Route path="/" component={Layout} />
          </ConnectedRouter>
        </Provider>
      </I18nextProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
