import * as types from '../actions/actionTypes';

export const initialState = {
  name: '',
  street: '',
  city: '',
  postalCode: '',
  countryCode: '',
  vatIdentificationNumber: ''
};

const processData = (data) => JSON.parse(JSON.stringify(data).replace(/null/g, "\"\""));

const company = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_FETCH_DATA_SUCCESS: {
      let data = processData(action.data);
      return {
        ...state,
        ...data
      };
    }
    case types.COMPANY_UPDATED_DATA: {
      let data = processData(action.company);
      return {
        ...state,
        ...data
      };
    }
    default:
      return state;
  }
};

export default company;
