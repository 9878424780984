import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as userActions from '../../../actions/userActions';
import TextBox from '../../common/TextBox';
import Captcha from '../../common/Captcha';
import SubmitButton from '../../common/SubmitButton';
import Translation from '../../common/Translation';
import * as generalFunctions from '../../generalFunctions';
import StrengthMeterTextBox from '../../common/StrengthMeterTextBox';
import AgreeTermOfUse from '../../common/AgreeTermOfUse';
import AgreeGDPR from '../../common/AgreeGDPR';
import LanguageSwitch from '../../common/LanguageSwitch';
import Checkbox from '../../common/Checkbox';

class EmailVerification extends React.Component {
  state = {
    account: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      captcha: '',
    },
    emailIsUsed: false,
    agreedTermOfUse: false,
    agreedGDPR: false,
    errors: {}
  };

  componentDidUpdate(prevProps) {
    if (prevProps.emailIsUsed !== this.props.emailIsUsed) {
      if (this.props.emailIsUsed) {
        let { errors } = this.state;
        errors.email = this.props.t('EmailIsUsed');
        this.setState({ errors, emailIsUsed: true });
      }
    }

    if (prevProps.isCaptchaVerified !== this.props.isCaptchaVerified) {
      if (!this.props.isCaptchaVerified) {
        this.captcha.reset();
        let account = { ...this.state.account };
        account.captcha = '';
        return this.setState({ account });
      }
    }

    if (prevProps.isPasswordSecured !== this.props.isPasswordSecured) {
      if (this.props.isPasswordSecured) {
        let { errors } = this.state;
        errors.password = this.props.t('PasswordIsNotSecured');
        this.setState({ errors });
      }
    }
  }

  onChange = (event) => {
    let account = { ...this.state.account };
    account[event.target.name] = event.target.value;

    return this.setState({ account });
  };

  formIsValid = () => {
    let { account } = this.state;
    let { t } = this.props;
    let formIsValid = true;
    let errors = {};

    if (!this.props.validateEmail(account.email)) {
      errors.email = t('EmailIsnotCorrect');
      formIsValid = false;
    }

    if (account.password !== account.confirmPassword) {
      errors.confirmPassword = t('PasswordsDontMatch');
      formIsValid = false;
    }

    if (this.passwordBox.getScore() < 1) {
      errors.password = t('PasswordIsNotSecured');
      formIsValid = false;
    }

    this.setState({ errors: errors });

    return formIsValid;
  };

  signUp = (event) => {
    event.preventDefault();

    if (this.formIsValid()) {
      this.props.actions.signUpUser(this.state.account);
    }
  };

  renderForm = () => {
    let { t } = this.props;
    let { account, errors } = this.state;

    return (
      <div className="box-wrapper two-column">
        <LanguageSwitch className="anonymous-zone-language-selector" theme="blue" mode="fullsize" />
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="CreateAccountTitle" /></div>
          <div><Translation tKey="CreateAccountMessage" /></div>
        </div>
        <form>
          <div className="row row-margin">
            <div className="col-md-12">
              <div className="email">
                <TextBox
                  name="email"
                  onChange={this.onChange}
                  value={account.email}
                  error={errors.email}
                  placeholder={t('Email')} />
              </div>
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-sm-12 col-md-6">
              <div className="firstName">
                <TextBox
                  name="firstName"
                  onChange={this.onChange}
                  value={account.firstName}
                  placeholder={t('Firstname')}
                  error={errors.firstName} />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="lastName">
                <TextBox
                  name="lastName"
                  onChange={this.onChange}
                  value={account.lastName}
                  placeholder={t('Lastname')}
                  error={errors.lastName} />
              </div>
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-sm-12 col-md-6">
              <div className="password">
                <StrengthMeterTextBox
                  name="password"
                  type="password"
                  onChange={this.onChange}
                  value={account.password}
                  error={errors.password}
                  placeholder={t('Password')}
                  ref={(passwordBox) => this.passwordBox = passwordBox}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="confirmPassword">
                <TextBox
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  onChange={this.onChange}
                  value={account.confirmPassword}
                  error={errors.confirmPassword}
                  placeholder={t('ConfirmPassword')}
                />

              </div>
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-sm-12 col-md-6">
              <div className="captcha">
                <Captcha ref={(el) => { this.captcha = el; }} onChange={this.onChange} name="captcha" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="agreement-links">
                <div>
                  <Checkbox
                    onChange={(checked) => this.setState({ agreedTermOfUse: checked })}
                    label={<AgreeTermOfUse />}
                  />
                </div>
                <div>
                  <Checkbox
                    onChange={(checked) => this.setState({ agreedGDPR: checked })}
                    label={<AgreeGDPR />}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="box-actions">
            <div className="actions">
              <SubmitButton
                onClick={this.signUp}
                loading={this.props.loading}
                disabled={!(this.state.agreedTermOfUse && this.state.agreedGDPR && this.state.account.captcha)}
              >
                <Translation tKey="SignUpSubmit" />
              </SubmitButton>
            </div>
            <div className="links">
              <Link className="text-link underlined" to="/signin"><Translation tKey="SignInGo" /></Link>
            </div>
          </div>
        </form>
      </div>
    );
  };

  renderNextInstructions = () => {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="ActivateEmailWasSent" /></div>
          <div><Translation tKey="SignUpInstructions" /></div>
        </div>
      </div>
    );
  };

  render() {
    let { emailIsSent } = this.props;
    return (
      <>
        {!emailIsSent && this.renderForm()}
        {emailIsSent && this.renderNextInstructions()}
      </>
    );
  }
}

EmailVerification.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func,
  emailIsUsed: PropTypes.bool,
  emailIsSent: PropTypes.bool,
  isCaptchaVerified: PropTypes.bool,
  validateEmail: PropTypes.func,
  isPasswordSecured: PropTypes.bool
};

const mapStateToProps = (state) => {
  let { signUpUser } = state.user;
  let options = {
    emailIsUsed: false,
    emailIsSent: false,
    isCaptchaVerified: true,
    isPasswordSecured: true
  };

  if (signUpUser) {
    options = { ...signUpUser };
  }

  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    ...options
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
    ...generalFunctions
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(EmailVerification);
