import { Reducer } from 'redux';
import * as types from '../actions/actionTypes';
import { AttachmentsState } from '../common/types/reduxStateTypes';
import { AttachmentsStateActionTypes } from '../common/types/attachmentsTypes';

export const initialState: AttachmentsState = {
  data: []
};

const reducer: Reducer<AttachmentsState, AttachmentsStateActionTypes> = (
  state: AttachmentsState = initialState,
  action: AttachmentsStateActionTypes
): AttachmentsState => {
  switch (action.type) {
    case types.ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        data: action.data.items
      };
    }
    default:
      return state;
  }
};

export default reducer;
