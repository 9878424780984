import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { ConnectedProps, connect } from 'react-redux';
import _ from 'lodash';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import IconButton from './IconButton';
import Translation from './Translation';
import { formatDateInDays, getDateByDaysAgo } from './utils/DateHelper';
import { readLog } from '../../actions/notificationActions';
import { PERMISSION_TYPES } from '../../common/types/permissionTypes';
import { NOTIFICATION_LOG_TYPES } from '../../common/types/notificationTypes';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import './HistoryDropDown.scss';
import { fetchWebAppSettings, fetchTableFilterSettings, fetchWorkflowFilters } from '../../actions/userAgendaSettingsActions';
import { RootState } from '../../common/types/reduxStateTypes';

const HISTORY_DAYS_NUMBER = 4;

enum MessageTypes {
  CreditLow = 0,
  CreditZero = 1
}

interface IMessage {
  id: number,
  type: MessageTypes,
  date: Date,
  read: boolean
}

const HistoryDropDown = ({
  permissions, messages, selectedAgendaId, fetchWebAppSettings, fetchTableFilterSettings, fetchWorkflowFilters, readLog }: HistoryDropDownProps
) => {
  const hasNotification = true;
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => { setShowMenu(!showMenu); };
  const hasPermission = permissions.includes(PERMISSION_TYPES.MANAGE_PAYMENTS);

  useEffect(() => {
    const autoCloseMenuHandler = (event: any) => {
      const toggleButton = document.querySelector('.history-toggle-button');
      if (toggleButton?.isSameNode(event.target)) {
        return;
      }

      const popupElement = document.querySelector('.history-dropdown');
      if (popupElement?.contains(event.target)) {
        return;
      }

      if (showMenu) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', autoCloseMenuHandler, true);
    return () => {
      document.removeEventListener('click', autoCloseMenuHandler, true);
    };
  });

  useEffect(() => {
    if (selectedAgendaId) {
      fetchWebAppSettings();
      fetchTableFilterSettings();
      fetchWorkflowFilters();
    }
  }, [selectedAgendaId]);

  const renderEvent = (msg: IMessage, index: number) => {
    const wrapperClass = classnames({
      'history-dropdown-event-item': true,
      'event-item-unread': !msg.read,
    });

    return (
      <li className={wrapperClass} key={index} onClick={(e) => {
        readLog({ id: msg.id, date: msg.date });
      }}>
        <div>
          {msg.type === MessageTypes.CreditLow && <Trans
            i18nKey="common:History.LowCredit"
            components={[<Link className="text-link underlined" to={NAVIGATION_PATHS.SETTINGS_BILLING_CREDIT_BUY}>dummyContent</Link>]} />
          }
          {msg.type === MessageTypes.CreditZero && <Trans
            i18nKey="common:History.NoCredit"
            components={[<Link className="text-link underlined" to={NAVIGATION_PATHS.SETTINGS_BILLING_CREDIT_BUY}>dummyContent</Link>]} />
          }
          <div className="history-dropdown-event-date">{formatDateInDays(msg.date)}</div>
        </div>
        <div className="history-dropdown-event-mark">
          <div className="history-dropdown-event-mark-dot" />
        </div>
      </li>
    );
  };

  const renderDropdownContent = (messages: Array<IMessage>) => {
    if (messages.length === 0 || !hasPermission) {
      return <div className="history-dropdown-no-events"><Translation tKey="History.EmptyMessage" /></div>;
    }

    return messages.map((msg, index) => renderEvent(msg, index));
  };

  const widgetWrapperClass = classnames({
    'history-dropdown-widget': true,
    'history-dropdown-has-unread': messages?.filter(msg => !msg.read).length !== 0,
  });

  const popupWrapperClass = classnames({
    'history-dropdown': true,
    'history-dropdown-open': showMenu,
  });

  return (
    <div className={widgetWrapperClass} id="history-widget">
      <IconButton icon="bell" className="history-toggle-button" onClick={toggleMenu} titleKey="Tooltips.Icons.News">
        {hasNotification && <span className="history-dropdown-notification" />}
      </IconButton>
      <div className={popupWrapperClass}>
        <ul className="history-dropdown-list">
          {renderDropdownContent(messages)}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  let messages = Array<IMessage>();
  let { notification: { read } } = state;
  const latestDate = getDateByDaysAgo(new Date(), HISTORY_DAYS_NUMBER - 1);
  const creditLog = _.cloneDeep(state.notification.log.billing || [])
    .filter(log => log.type === NOTIFICATION_LOG_TYPES.BillingAgendaBalance)
    .filter(log => new Date(log.dateOfCreate) > latestDate);

  const sortedCreditLog = (_.sortBy(creditLog, 'dateOfCreate')).reverse();

  if (creditLog) {
    const lowCredit = sortedCreditLog
      .map(log => {
        let additionalInfo: { LowCredit: number, ZeroCredit: number } = JSON.parse(log.additionalInfo);
        return { ...log, additionalInfo };
      })
      .map(({ additionalInfo, dateOfCreate, id }) => {
        const msg: IMessage = {
          id,
          type: additionalInfo.LowCredit === 1 ? MessageTypes.CreditLow : MessageTypes.CreditZero,
          read: read.find(r => r.id === id) !== undefined,
          date: new Date(dateOfCreate)
        };
        return msg;
      });

    messages = messages.concat(lowCredit);
  }

  return {
    permissions: state.user.permissions,
    selectedAgendaId: state.user.agendas?.selectedValue,
    messages
  };
};

const mapDispatchToProps = {
  readLog,
  fetchWebAppSettings,
  fetchTableFilterSettings,
  fetchWorkflowFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type HistoryDropDownProps = ConnectedProps<typeof connector>;

export default connector(HistoryDropDown);
