import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from '../../actions/userActions';
import { bindActionCreators, compose } from 'redux';
import TextBox from '../common/TextBox';
import SubmitButton from '../common/SubmitButton';
import { Link } from 'react-router-dom';
import Translation from '../common/Translation';
import AnonymousZone from './AnonymousZone';
import { withTranslation } from 'react-i18next';
import { validateFieldLength, validateEmail } from '../generalFunctions';
import { NAVIGATION_PATHS } from '../../common/data/routes';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: []
    };
  }

  signIn = (event) => {
    event.preventDefault();
    if (this.formIsValid()) {
      this.props.actions.signIn(this.state.email, this.state.password);
    }
  };

  formIsValid() {
    let { t } = this.props;
    let options = {
      errors: {},
      formIsValid: true,
      data: this.state,
      t
    };

    options.formIsValid = validateFieldLength({ ...options, field: 'email' });
    options.formIsValid = validateFieldLength({ ...options, field: 'password' });

    if (!validateEmail(this.state.email)) {
      options.errors.email = t('EmailIsnotCorrect');
      options.formIsValid = false;
    }

    this.setState({ errors: options.errors });
    return options.formIsValid;
  }

  render() {
    let { t } = this.props;

    return (
      <AnonymousZone showRegistration>
        <div className="box-wrapper">
          <div className="box-header-group">
            <div className="box-header"><Translation tKey="SignInProcess.Header" /></div>
            <div><Translation tKey="SignInProcess.Intro" /></div>
          </div>
          <form>
            <div className="row-margin">
              <div className="email">
                <TextBox
                  name="email"
                  testId="signin-email"
                  autoComplete="username"
                  onChange={(e) => { this.setState({ email: e.target.value }); }}
                  value={this.state.email}
                  error={this.state.errors.email}
                  placeholder="Email" />
              </div>
            </div>
            <div className="row-margin">
              <div className="password">
                <TextBox
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  testId="signin-password"
                  onChange={(e) => { this.setState({ password: e.target.value }); }}
                  value={this.state.password}
                  error={this.state.errors.password}
                  placeholder={t('Password')} />
              </div>
            </div>
            <div className="box-actions">
              <div className="actions">
                <SubmitButton onClick={this.signIn} loading={this.props.loading} testId="signin-button">
                  <Translation tKey="SignIn" />
                </SubmitButton>
              </div>
              <div className="links">
                <Link className="text-link underlined" to={NAVIGATION_PATHS.FORGOTTEN_PASSWORD}><Translation tKey="ForgetPassword" /></Link>
              </div>
            </div>
          </form>
        </div>
      </AnonymousZone >);
  }
}

SignIn.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    loading: state.ajaxStatus.totalTriggers > 0
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
};

export default compose(withTranslation('common', { useSuspense: true }), connect(mapStateToProps, mapDispatchToProps))(SignIn);
