import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import * as userActions from '../../../actions/userActions';
import TextBox from '../../common/TextBox';
import SubmitButton from '../../common/SubmitButton';
import Translation from '../../common/Translation';
import { validateFieldLength } from '../../generalFunctions';
import StrengthMeterTextBox from '../../common/StrengthMeterTextBox';

class CompleteUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        firstname: '',
        lastname: '',
        password: '',
        confirmPassword: '',
      },
      errors: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPasswordSecured !== prevProps.isPasswordSecured) {
      if (!this.props.isPasswordSecured) {
        let { errors } = this.state;
        errors.password = this.props.t('PasswordIsNotSecured');
        this.setState({ errors });
      }
    }
  }

  onChange = (event) => {
    let account = { ...this.state.account };
    account[event.target.name] = event.target.value;

    return this.setState({ account });
  };

  formIsValid = () => {
    let { account } = this.state;
    let { t } = this.props;
    let options = {
      errors: {},
      formIsValid: true,
      data: account,
      t
    };

    if (this.passwordBox.getScore() < 1) {
      options.errors.password = t('PasswordIsNotSecured');
      options.formIsValid = false;
    }

    if (account.password !== account.confirmPassword) {
      options.errors.confirmPassword = t('PasswordsDontMatch');
      options.formIsValid = false;
    }

    options.formIsValid = validateFieldLength({ ...options, field: 'firstname' });
    options.formIsValid = validateFieldLength({ ...options, field: 'lastname' });

    this.setState({ errors: options.errors });
    return options.formIsValid;
  };

  signUp = (event) => {
    event.preventDefault();

    if (this.formIsValid()) {
      this.props.actions.signUpCompleteUser(this.state.account, this.props.token);
    }
  };

  renderForm = () => {
    let { t } = this.props;
    let { account, errors } = this.state;

    return (
      <div className="box-wrapper two-column">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="CompleteUserTitle" /></div>
          <div><Translation tKey="CompleteUserMessage" /></div>
        </div>
        <form>
          <div className="row row-margin">
            <div className="col-md-6">
              <TextBox
                name="firstname"
                onChange={this.onChange}
                value={account.firstname}
                placeholder={t('Firstname')}
                error={errors.firstname} />
            </div>
            <div className="col-md-6">
              <TextBox
                name="lastname"
                onChange={this.onChange}
                value={account.lastname}
                placeholder={t('Lastname')}
                error={errors.lastname} />
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-md-6">
              <StrengthMeterTextBox
                name="password"
                type="password"
                onChange={this.onChange}
                value={account.password}
                error={errors.password}
                placeholder={t('Password')}
                ref={(passwordBox) => this.passwordBox = passwordBox}
              />
            </div>
            <div className="col-md-6">
              <TextBox
                name="confirmPassword"
                type="password"
                autoComplete="new-password"
                onChange={this.onChange}
                value={account.confirmPassword}
                error={errors.confirmPassword}
                placeholder={t('ConfirmPassword')} />
            </div>
          </div>
          <div className="box-actions">
            <div className="actions">
              <SubmitButton onClick={this.signUp} loading={this.props.loading}><Translation tKey="Send" /></SubmitButton>
            </div>
            <div className="links">
              <Link className="text-link underlined" to="/signin"><Translation tKey="SignInGo" /></Link>
            </div>
          </div>
        </form >
      </div >
    );
  };

  render() {
    return (
      <div>
        {this.renderForm()}
      </div>
    );
  }
}

CompleteUser.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func,
  token: PropTypes.string,
  isPasswordSecured: PropTypes.bool
};

const mapStateToProps = (state) => {
  let isPasswordSecured = true;

  let { user: { signUpUserComplete } } = state;

  if (signUpUserComplete) {
    isPasswordSecured = signUpUserComplete.isPasswordSecured;
  }

  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    isPasswordSecured
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(CompleteUser);
