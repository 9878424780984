import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { sortByLocalStrings } from '../generalFunctions';
import i18n from '../../i18n';

export function lintel(t: TFunction, tKey: string | string[], transform: object = {}): string {
  return t(tKey, transform);
}

interface ITranslationProps extends WithTranslation {
  tKey: string | string[],
  transform?: any | null,
  options?: any | null,
}

const Translation : React.FC<ITranslationProps> = ({ t, tKey, transform = {}, options = {} }) => {
  return <React.Fragment>{lintel(t, tKey, transform)}</React.Fragment>;
};

export default withTranslation('common')(Translation);

export function translateDataset(data: { tKey: string }[], textField: string = "text", isSort: boolean = true) {
  const translatedDataset = data.map((item) => ({ ...item, [textField]: i18n.t(item.tKey) }));
  if (isSort) {
    return sortByLocalStrings(translatedDataset, textField);
  }

  return translatedDataset;
}
