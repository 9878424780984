import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import queryString from 'query-string';

import Translation from '../../common/Translation';
import SubmitButton from '../../common/SubmitButton';
import AnonymousZone from '../AnonymousZone';
import { acceptCompanyInvitation, rejectCompanyInvitation } from '../../../actions/userInvitationActions';
import { navigateTo } from '../../../actions/navigationActions';
import { NAVIGATION_PATHS } from '../../../common/data/routes';
import { FAILED, CompanyInvitation } from '../../../common/types/responseTypes';

class CompanyInvitationReview extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      loading: true,
      status: "",
      company: "",
      code: undefined
    };
  }

  componentDidMount() {
    let { match, code } = this.props;
    let { id, status } = match.params;

    if (status === "accept") {
      this.props.accept(id, code, (companyInvitation) => {
        this.updateModel(companyInvitation);
      });
    } else if (status === "reject") {
      this.props.reject(id, code, (companyInvitation) => {
        this.updateModel(companyInvitation);
      });
    }
  }

  updateModel = (companyInvitation) => {
    let { match: { params: { status } } } = this.props;

    this.setState({
      company: companyInvitation.companyName,
      code: companyInvitation.code,
      status,
      loading: false
    });
  };

  renderAccepted() {
    if (this.state.code === FAILED) {
      return this.renderFailed();
    }

    if (this.state.code === CompanyInvitation.IsAlreadyPaired) {
      return this.renderAlreadyPaired();
    }

    if (this.state.code === CompanyInvitation.IsAlreadyExpired) {
      return this.renderAlreadyExpired();
    }

    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="CompanyInvitationReview.Accepted.Title" /></div>
          <div><Trans i18nKey="CompanyInvitationReview.Accepted.Subtitle"
            values={{ company: this.state.company }}
            components={[<strong key="0">dummyContent</strong>]} />
          </div>
        </div>
        <div className="row-margin">
          <Trans i18nKey="CompanyInvitationReview.Accepted.Text"
            values={{ company: this.state.company }}
            components={[<strong key="0">dummyContent</strong>]} />
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SIGN_IN)} loading={this.props.loading}>
              <Translation tKey="SignInGo" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  }

  renderRejected() {
    if (this.state.code === CompanyInvitation.IsAlreadyPaired) {
      return this.renderAlreadyPaired();
    }

    if (this.state.code === CompanyInvitation.IsAlreadyExpired) {
      return this.renderAlreadyExpired();
    }

    if (this.state.code === FAILED) {
      return this.renderFailed();
    }

    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="CompanyInvitationReview.Rejected.Title" /></div>
          <div>
            <Trans i18nKey="CompanyInvitationReview.Rejected.Subtitle"
              values={{ company: this.state.company }}
              components={[<strong key="0">dummyContent</strong>]} />
          </div>
        </div>
        <div className="row-margin">
          <Trans i18nKey="CompanyInvitationReview.Rejected.Text"
            values={{ company: this.state.company }}
            components={[<strong key="0">dummyContent</strong>]} />
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SIGN_IN)} loading={this.props.loading}>
              <Translation tKey="SignInGo" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  }

  renderAlreadyExpired() {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="CompanyInvitationReview.Expired.Title" /></div>
          <div>
            <Trans i18nKey="CompanyInvitationReview.Expired.Subtitle"
              values={{ company: this.state.company }}
              components={[<strong key="0">dummyContent</strong>]} />
          </div>
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SIGN_IN)} loading={this.props.loading}>
              <Translation tKey="SignInGo" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  }

  renderFailed() {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="SomethingWentWrong" /></div>
        </div>
        <div className="row-margin">
          <Translation tKey="SomethingUnhappened" />
        </div>
        <div className="row-margin">
          <Translation tKey="PleaseContactHotLine" />
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SUPPORT)} loading={this.props.loading}>
              <Translation tKey="Support.Button" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  }

  renderAlreadyPaired() {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="CompanyInvitationReview.AlreadyPaired.Title" /></div>
          <div>
            <Trans i18nKey="CompanyInvitationReview.AlreadyPaired.Subtitle"
              values={{ company: this.state.company }}
              components={[<strong key="0">dummyContent</strong>]} />
          </div>
        </div>
        <div className="row-margin">
          <Translation tKey="CompanyInvitationReview.AlreadyPaired.Text" />
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SIGN_IN)} loading={this.props.loading}>
              <Translation tKey="SignInGo" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { status } = this.state;
    return (
      <AnonymousZone>
        {status === "accept" && this.renderAccepted()}
        {status === "reject" && this.renderRejected()}
        {this.state.loading && <p><span className="k-icon k-i-loading">&nbsp;</span> <Translation tKey="RequestValidating" /></p>}
      </AnonymousZone >
    );
  }
}

CompanyInvitationReview.propTypes = {
  t: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string
    })
  }),
  code: PropTypes.string,
  accept: PropTypes.func,
  reject: PropTypes.func
};


const mapStateToProps = (state, ownProps) => {
  let { code } = queryString.parse(ownProps.location.search);

  return {
    code
  };
};

const mapDispatchToProps = {
  accept: acceptCompanyInvitation,
  reject: rejectCompanyInvitation,
  navigateTo
};

export default compose(withTranslation('common', { useSuspense: true }), connect(mapStateToProps, mapDispatchToProps))(CompanyInvitationReview);
