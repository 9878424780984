import * as types from '../actions/actionTypes';

export const initialState = {
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANY_VERIFICATION_FETCH_DATA_SUCCESS:
    case types.COMPANY_VERIFICATION_VERIFY_SUCCESS:
    case types.COMPANY_VERIFICATION_SENT_VERIFICATION_MESSAGE_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default payment;
