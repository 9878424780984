import { AnyAction } from 'redux';
import * as types from '../actions/actionTypes';
import { UserFilterType } from '../common/types/commonTypes';
import { userFiltersInitialState } from './initialStates';

const userFilters = (state = userFiltersInitialState, action: AnyAction) => {
  switch (action.type) {
    case types.USER_WORKFLOW_FILTERS:
      return {
        ...state,
        workflow: action.workflowFilters
      };
    case types.USER_WORKFLOW_FILTER_SETTINGS_REMOVE_SUCCESS: {
      const newFilters = state.workflow.filter((s: UserFilterType) => s.id !== action.id);
      return {
        ...state,
        workflow: newFilters,
      };
    }
    default:
      return state;
  }
};

export default userFilters;
