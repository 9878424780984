import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';

import * as userActions from '../../actions/userActions';
import EmailVerification from './signUp/EmailVerification';
import CompanyVerification from './signUp/CompanyVerification';
import CompleteUser from './signUp/CompleteUser';
import Translation from '../common/Translation';
import AnonymousZone from './AnonymousZone';
import { GOTO_PART } from '../../common/types/signupTypes';
import { navigateToSignUpCompany, navigateToCompanyVerification } from '../../actions/navigationActions';
import jwtDecode from 'jwt-decode';
import { setLang } from '../../actions/appActions';

class SignUp extends React.Component {
  componentDidMount() {
    this.props.actions.clear();
    const { code, lang, setLang } = this.props;
    if (code !== undefined) {
      this.props.actions.verifyEmail(code);
      const tokenClaims = jwtDecode(code);
      const langCode = tokenClaims.LANGCODE;
      if (langCode && langCode !== lang) {
        setLang(langCode);
      }
    }
  }

  renderWaitInstructions() {
    return (
      <div className="box-header-group">
        <div className="box-header"><Translation tKey="VerifyingYourEmailAddress" /></div>
        <div><Translation tKey="WaitForASecond" /></div>
      </div>
    );
  }

  renderEmailIsUsedInstructions() {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="EmailIsUsed" /></div>
          <div><Translation tKey="EmailIsAlreadyActivated" /></div>
        </div>
      </div>
    );
  }

  renderForm() {
    let { goToPart, partData, code } = this.props;
    switch (goToPart) {
      case 0: {
        if (code) {
          return this.renderWaitInstructions();
        }

        return <EmailVerification />;
      }
      case GOTO_PART.GoToUserSignUp: return <EmailVerification />;
      case GOTO_PART.GoToCompanySignUp: return <CompanyVerification invitations={this.props.invitations} token={this.props.code} />;
      case GOTO_PART.GoToCompleteUserData: return <CompleteUser token={this.props.code} />;
      case GOTO_PART.RenderInstructionsEmailIsUsed: return this.renderEmailIsUsedInstructions();
      case GOTO_PART.GoToPayment: return this.props.goToSignUpCompany(this.props.code);
      case GOTO_PART.GoToSendDataBoxMessage: return this.props.goToCompanyVerification(partData);
      default:
        break;
    }
  }

  render() {
    return (
      <AnonymousZone>
        {this.renderForm()}
      </AnonymousZone>
    );
  }
}

SignUp.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  lang: PropTypes.string,
  t: PropTypes.func,
  code: PropTypes.string,
  goToPart: PropTypes.number,
  partData: PropTypes.string,
  invitations: PropTypes.arrayOf(PropTypes.object),
  goToCompanyVerification: PropTypes.func.isRequired,
  goToSignUpCompany: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  let invitations = [];
  let goToPart = 0;
  let partData = "";
  let { user } = state;

  if (user) {
    let { verifyEmail } = user;

    if (verifyEmail) {
      goToPart = verifyEmail.goToPart;
      partData = verifyEmail.partData;
      invitations = verifyEmail.invitations || [];
    }
  }

  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    code: queryString.parse(ownProps.location.search).code,
    lang: state.app.lang,
    goToPart,
    partData,
    invitations
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
    goToSignUpCompany: bindActionCreators(navigateToSignUpCompany, dispatch),
    goToCompanyVerification: bindActionCreators(navigateToCompanyVerification, dispatch),
    setLang: bindActionCreators(setLang, dispatch),
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(SignUp);
