import { ReactNode, Ref, forwardRef } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';

export type ConfirmDialogBoxProps = {
  title?: any,
  width?: number | string,
  height?: number | string;
  onClose?: () => void,
  children: ReactNode,
  classNames?: string,
  contentStyle?: React.CSSProperties,
};

export type ConfirmDialogBoxRef = {};

const ConfirmDialogBox = ({ title, onClose, children, classNames, ...rest }: ConfirmDialogBoxProps, ref: Ref<ConfirmDialogBoxRef>) => {
  const { t } = useTranslation();
  let titleComponent = title;
  if (typeof title === 'string') {
    titleComponent = t(title || "ConfirmTitle");
  }

  return (
    <Dialog title={titleComponent} className={classNames} onClose={() => onClose?.()} ref={ref} {...rest}>
      {children}
    </Dialog>
  );
};

export default forwardRef(ConfirmDialogBox);
