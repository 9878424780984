import React from "react";
import { DropDownList, DropDownListProps } from "@progress/kendo-react-dropdowns";
import { withNotificationsDropDown } from "./AgendaWithNotificationsDropDownListHOC";
import './AgendaWithNotificationsDropDownList.scss';

interface Props extends DropDownList {
}

class AgendaWithNotificationsDropDownList extends React.Component<Props> {
  public render() {
    return <DropDownList popupSettings={{ popupClass: 'min-w-72' }} {...this.props}/>;
  }
}

export default withNotificationsDropDown(AgendaWithNotificationsDropDownList);
