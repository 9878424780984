import * as types from './actionTypes';

export const toggleSidebar = () => (dispatch) => {
  return dispatch({ type: types.SIDEBAR_TOGGLE });
};

export const hideSidebar = () => (dispatch) => {
  return dispatch({ type: types.SIDEBAR_HIDE });
};

export const showSidebar = (id) => (dispatch) => {
  return dispatch({ type: types.SIDEBAR_SHOW, id });
};
