export const OK = 1;
export const FAILED = -1;

export const UserInvitation = {
  UserEmailIsSame: 9000,
  UserEmailCantBeEmpty: 9002,
  UserIsAlreadyPaired: 9003,
  UserIsAlreadyInvited: 9004,
  UserAlreadyAcceptedInvitation: 9005,
};

export const SignUpCompany = {
  DuplicateIdentificator: 8002
};

export const CompanyInvitation = {
  IsAlreadyPaired: 11001,
  IsAlreadyExpired: 11002
};
