import * as types from './actionTypes';
import { NAVIGATION_PATHS } from '../common/data/routes';

export const navigateTo = (path) => {
  return { type: types.NAVIGATE_TO, path };
};

export const navigateToRoot = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.ROOT };
};

export const navigateToPartnerInvitations = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.PARTNER_INVITATIONS };
};

export const navigateToSignUpCompany = (token) => {
  return { type: types.NAVIGATE_TO, path: `${NAVIGATION_PATHS.SIGN_UP_COMPANY}${token}` };
};

export const navigateToSignIn = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SIGN_IN };
};

export const navigateToUserInvitations = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.USER_INVITATIONS };
};

export const navigateToUserInvitation = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.USER_INVITATION_CREATE };
};

export const navigateToCompanyVerification = (token) => {
  return { type: types.NAVIGATE_TO, path: `${NAVIGATION_PATHS.COMPANY_VERIFICATION_TOKEN}${token}` };
};

export const navigateToPartnerInvitationCreate = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.PARTNER_INVITATION_CREATE };
};

export const navigateToAccountSettings = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_MY_ACCOUNT };
};

export const navigateToWorkflowGeneralSettings = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_WORKFLOW_GENERAL };
};

export const navigateToWorkflowScenarioSettings = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_WORKFLOW_SCENARIOS };
};

export const navigateToWorkflowAgreementSettings = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_WORKFLOW_AGREEMENT };
};

export const navigateToWorkflowVariableTypeSettings = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_WORKFLOW_VARIABLETYPES };
};

export const navigateToSettingsWorkflowAgreementProcessScenario = (id) => {
  return { type: types.NAVIGATE_TO, path: `${NAVIGATION_PATHS.SETTINGS_WORKFLOW_AGREEMENT_PROCESS_SCENARIO}/${id}` };
};

export const navigateToSettingsWorkflowScenario = (id) => {
  return { type: types.NAVIGATE_TO, path: `${NAVIGATION_PATHS.SETTINGS_WORKFLOW_SCENARIO}/${id}` };
};

export const navigateToUserPermissions = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.USER_PERMISSIONS };
};

export const navigateToCredit = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_BILLING };
};

export const navigateToBuyCredit = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_BILLING_CREDIT_BUY };
};

export const navigateToCreditHistory = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_BILLING_HISTORY };
};

export const navigateToPriceList = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_PRICELIST };
};

export const navigateToWorkflowAgreement = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.WORKFLOW_AGREEMENT_PROCESS };
};

export const navigateToCustomPage = (model) => {
  return { type: types.NAVIGATE_TO, ...model };
};

export const navigateToNotificationsSetting = () => {
  return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SETTINGS_NOTIFICATIONS };
};

export const navigateToWorkflowGeneralSettingsCloudDrives = () => {
  return { type: types.NAVIGATE_TO, path: `${NAVIGATION_PATHS.SETTINGS_WORKFLOW_GENERAL}#cloudDrives` };
};
