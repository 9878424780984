import { connect, ConnectedProps } from "react-redux";
import { languages } from "../../common/data/localizationData";
import { LangCodesType } from "../../common/types/commonTypes";
import ImageDropDown, { ImageDropDownTheme, ModeType } from "./ImageDropDown";
import { RootState } from "../../common/types/reduxStateTypes";

type LanguageSelectorOwnProps = {
  theme?: ImageDropDownTheme,
  mode?: ModeType,
  className?: string,
  initialValue?: string,
  onChange: (langCode: LangCodesType) => void,
}

const LanguageSelector = ({ lang, theme, mode = 'compact', initialValue, className, onChange }: LanguageSelectorProps) => {
  let initValue = languages.find(l => l.value === lang);
  if (initValue) {
    const userInitValue = languages.find(l => l.value === initialValue);
    if (userInitValue) {
      initValue = userInitValue;
    }
  }

  return (
    <ImageDropDown
      data={languages}
      dataItemKey="value"
      selectedItem={initValue}
      onValueChange={onChange}
      theme={theme}
      mode={mode}
      className={className}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  lang: state.app.lang || 'cs',
});

const connector = connect(mapStateToProps);
type LanguageSelectorProps = LanguageSelectorOwnProps & ConnectedProps<typeof connector>;

export default connector(LanguageSelector);
