import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Translation from '../../common/Translation';
import SubmitButton from '../../common/SubmitButton';
import TextBox from '../../common/TextBox';
import i18n from '../../../i18n';
import { restartPayment } from '../../../actions/companyVerificationActions';

const PaymentPending = ({ token, navigateTo, restartPayment }) => {
  const getSignUpLink = () => {
    return `${window.location.origin}/signUp?code=${token}`;
  };

  const copyToClipboard = (e) => {
    e.preventDefault();

    let textField = document.createElement('textarea');
    textField.innerText = getSignUpLink();
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  return (
    <div className="box-wrapper">
      <div className="box-header-group">
        <div className="box-header"><Translation tKey="PaidPending" /></div>
        <div>
          <Trans i18nKey="common:PaidPendingMessage"
            values={{ refreshLink: i18n.t('Refresh') }}
            components={[<Link key={0} to={`${navigateTo}`} hash="/#">dummyContent</Link>]} />
        </div>
      </div>
      <form>
        <div className="row-margin">
          <div className="row-margin">
            <TextBox
              name="code"
              value={getSignUpLink()}
              onChange={() => { /* DUMMY ACTION */ }}
            />
          </div>
          <SubmitButton onClick={copyToClipboard}>CTRL + C</SubmitButton>
        </div>
      </form >
      <Translation tKey="PaidPendingRefreshAction" />
      <br />
      <br />
      <a href="#" onClick={() => {
        restartPayment(token);
      }}>Zrušit platbu a začít novou platbou</a>
    </div >
  );
};

PaymentPending.propTypes = {
  token: PropTypes.string,
  navigateTo: PropTypes.string,
  restartPayment: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    restartPayment: bindActionCreators(restartPayment, dispatch),
  };
};

export default connect(state => state, mapDispatchToProps)(PaymentPending);
