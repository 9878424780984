import * as types from '../actions/actionTypes';

export const initialState = {
  status: -1,
  token: '',
  method: '',
  credit: undefined
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENT_REGISTRATION_INFO_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    case types.PAYMENT_AUTHORIZED_INFO_SUCCESS:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default payment;
