import React, { Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Switch } from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';

import SideBar from '../common/SideBar';
import ScreenMode from '../common/ScreenMode';
import WarningDialog from '../common/WarningDialog';
import Header from '../common/Header';
import PageTitle from '../common/PageTitle';

import * as userActions from '../../actions/userActions';
import { NAVIGATION_PATHS } from '../../common/data/routes';

import LoadingPanel from '../common/LoadingPanel';
import NeedSignoutDialog from '../common/NeedSignoutDialog';
import { SCREEN_RESOLUTION_TYPES } from '../../common/types/screenResolutionTypes';
import { Tooltip } from '@progress/kendo-react-tooltip';
import TooltipContentTemplate from '../common/TooltipContentTemplate';
import { NotFoundInnerPanel } from '../common/NotFound';
import lazy from './LazyWithRetry';

const Dashboard = lazy(() => import('../dashboard/Dashboard'));
const Devices = lazy(() => import('../devices/Devices'));
const Events = lazy(() => import('../dashboard/Events'));
const InvoicesOutgoing = lazy(() => import('../invoicesOutgoing/InvoicesOutgoing'));
const InvoicesIncoming = lazy(() => import('../invoicesIncoming/InvoicesIncoming'));
const Partners = lazy(() => import('../partners/Partners'));
const PartnerCreateInvitation = lazy(() => import('../partners/PartnerCreateInvitation'));
const PartnerInvitations = lazy(() => import('../partners/PartnerInvitations'));
const ManageUserInvitation = lazy(() => import('../userInvitations/ManageUserInvitation'));
const Users = lazy(() => import('../users/Users'));
const UserInvitations = lazy(() => import('../userInvitations/UserInvitations'));
const ManagePermissions = lazy(() => import('../users/permissions/ManagePermissions'));
const WorkflowHistory = lazy(() => import('../workflow/WorkflowHistory'));
const WorkflowStatistics = lazy(() => import('../workflow/WorkflowStatistics'));
const ManageWorkflowQueue = lazy(() => import('../workflow/ManageWorkflowQueue'));
const ManageWorkflowAgreementProcess = lazy(() => import('../workflow/ManageWorkflowAgreementProcess'));
const ManageSendAttachment = lazy(() => import('../workflow/utils/ManageSendAttachment'));
const ManageSettings = lazy(() => import('../settings/ManageSettings'));

const Archive = lazy(() => import('../archive/Archive'));
const ArchivePackages = lazy(() => import('../archive/ArchivePackages'));


// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
class App extends React.Component {
  componentDidMount() {
    let { actions, token } = this.props;

    if (token) {
      actions.verifyAccess(token);
    } else {
      actions.checkAccess();
    }

    let self = this;
    this.lastModified = new Date();

    // Cycling fire
    this.interval = setInterval(() => {
      actions.isAuth(self.lastModified);
    }, 15000);
  }

  componentDidUpdate(prevProps) {
    // If current user is still same
    if (prevProps.user === this.props.user) {
      // And has the same token expiration time (token wasn't renewed) change the time of last modification to calculate time to renew token
      if (prevProps.user.tokenExpireIn === this.props.user.tokenExpireIn) {
        this.lastModified = new Date();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  renderContent = () => {
    const { pathKey } = this.props;

    if (this.props.user.isAuth) {
      const wrapperClass = classNames({
        'content': true,
        'content-minimal': !this.props.open,
        'content-display-extra-small': this.props.resolution === SCREEN_RESOLUTION_TYPES.XS
      });

      return (<div className={wrapperClass}>
        <LoadingPanel />
        <NeedSignoutDialog />
        {this.props.resolution === SCREEN_RESOLUTION_TYPES.XS && <div className="mobile-title"><PageTitle /></div>}
        <Suspense fallback={<LoadingPanel />}>
          <Switch>
            <Route exact path={NAVIGATION_PATHS.ROOT} component={Dashboard} />
            <Route exact path={NAVIGATION_PATHS.EVENTS} component={Events} />
            <Route exact path={NAVIGATION_PATHS.INVOICES_OUTGOING} component={InvoicesOutgoing} />
            <Route exact path={NAVIGATION_PATHS.INVOICES_INCOMING} component={InvoicesIncoming} />
            <Route exact path={NAVIGATION_PATHS.USERS} component={Users} />
            <Route exact path={NAVIGATION_PATHS.USER_INVITATIONS} component={UserInvitations} />
            <Route exact path={NAVIGATION_PATHS.USER_INVITATION_CREATE} component={ManageUserInvitation} />
            <Route exact path={NAVIGATION_PATHS.USER_INVITATION_ID} component={ManageUserInvitation} />
            <Route exact path={NAVIGATION_PATHS.USER_PERMISSIONS} component={ManagePermissions} />
            <Route exact path={NAVIGATION_PATHS.ARCHIVE} component={Archive} key={pathKey} />
            <Route path={NAVIGATION_PATHS.ARCHIVE_PACKAGES} component={ArchivePackages} />
            <Route exact path={NAVIGATION_PATHS.PARTNERS} component={Partners} />
            <Route exact path={NAVIGATION_PATHS.PARTNER_INVITATIONS} component={PartnerInvitations} />
            <Route exact path={NAVIGATION_PATHS.PARTNER_INVITATION_CREATE} component={PartnerCreateInvitation} />

            <Route exact path={`${NAVIGATION_PATHS.SETTINGS_BILLING_CREDIT_PAYMENT}/:status`} component={ManageSettings} />
            <Route path={NAVIGATION_PATHS.SETTINGS} component={ManageSettings} />

            <Route exact path={NAVIGATION_PATHS.SETTINGS_WORKFLOW} component={ManageSettings} />
            <Route exact path={NAVIGATION_PATHS.WORKFLOW_AGREEMENT_PROCESS} component={ManageWorkflowAgreementProcess} key={pathKey} />
            <Route exact path={NAVIGATION_PATHS.WORKFLOW_QUEUE_RECORD_MAIL_TO} component={ManageSendAttachment} key={pathKey} />
            <Route exact path={NAVIGATION_PATHS.WORKFLOW_QUEUE} component={ManageWorkflowQueue} key={pathKey} />
            <Route exact path={NAVIGATION_PATHS.WORKFLOW_HISTORY} component={WorkflowHistory} />
            <Route exact path={NAVIGATION_PATHS.WORKFLOW_STATISTICS} component={WorkflowStatistics} />
            <Route exact path={NAVIGATION_PATHS.DEVICES} component={Devices} />
            <Route component={NotFoundInnerPanel} />
          </Switch>
        </Suspense>
      </div>);
    }

    return '';
  };

  renderMain(mainElement) {
    if (!isMobile) {
      return (
        <Tooltip
          anchorElement="target"
          position="bottom"
          parentTitle
          openDelay={1000}
          content={TooltipContentTemplate}
        >
          {mainElement}
        </Tooltip>
      );
    }

    return <>{mainElement}</>;
  }

  render() {
    const classWrapper = classNames({
      'main-wrapper': true,
      'mobile-version': this.props.resolution === SCREEN_RESOLUTION_TYPES.XS,
      'full-size-version': this.props.resolution !== SCREEN_RESOLUTION_TYPES.XS,
      'mobile-tablet-version': isMobile,
      'desktop-version': !isMobile,
    });

    return (
      <div className={classWrapper}>
        <WarningDialog />
        {this.renderMain(
          <>
            <Header />
            <main>
              <SideBar path={this.props.path} />
              <ScreenMode />
              {this.renderContent()}
            </main>
          </>
        )}
      </div>
    );
  }
}

App.propTypes = {
  open: PropTypes.bool,
  path: PropTypes.string,
  pathKey: PropTypes.string,
  user: PropTypes.shape({
    tokenExpireIn: PropTypes.number,
    isAuth: PropTypes.bool,
  }),
  actions: PropTypes.shape({
    isAuth: PropTypes.func.isRequired,
    verifyAccess: PropTypes.func.isRequired,
    checkAccess: PropTypes.func.isRequired
  }),
  token: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    token: queryString.parse(ownProps.location.search).token || undefined,
    path: ownProps.location.pathname,
    pathKey: ownProps.location.key,
    open: state.sideBar.open || false,
    resolution: state.app.resolution
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: { ...bindActionCreators(userActions, dispatch) }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
