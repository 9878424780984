import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { matchPath } from "react-router";
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

import { hideSidebar } from '../../actions/sideBarActions';
import { SCREEN_RESOLUTION_TYPES } from '../../common/types/screenResolutionTypes';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import uuidV4 from 'uuid/v4';


const NavLink = ({ to, path, children, className, hideSidebar, closeOnClick, testId }) => {
  const match = matchPath(path, {
    path: to,
    exact: to === NAVIGATION_PATHS.ROOT,
  });

  const wrapperClass = classNames({
    [className]: className,
    active: match,
  });

  const [hashKey, setHashKey] = useState(uuidV4());

  return (
    <li className={wrapperClass} data-testid={testId}><Link to={{ pathname: to, key: hashKey }} onClick={() => {
      setHashKey(uuidV4());
      if (closeOnClick) {
        hideSidebar();
      }
    }}>{children}</Link></li>
  );
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
  hideSidebar: PropTypes.func,
  closeOnClick: PropTypes.bool,
  testId: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    closeOnClick: state.app.resolution === SCREEN_RESOLUTION_TYPES.XS || isMobile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideSidebar: bindActionCreators(hideSidebar, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavLink);
