import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { toggleSidebar } from '../../actions/sideBarActions';
import { createSignUpCompanyToken } from '../../actions/userActions';
import AccountMenu from './AccountMenu';
import HistoryDropDown from './HistoryDropDown';
import Agenda from './Agenda';
import PageTitle from './PageTitle';
import IconButton from './IconButton';
import Translation from './Translation';
import { SCREEN_RESOLUTION_TYPES } from '../../common/types/screenResolutionTypes';
import './Header.scss';
import { RootState } from '../../common/types/reduxStateTypes';

interface HeaderProps {
  resolution: string,
  toggleSidebar: () => void,
  createSignUpCompanyToken: () => void
}

const Header = ({ resolution, toggleSidebar, createSignUpCompanyToken }: HeaderProps) => {
  const isExtraSmallScreen = resolution === SCREEN_RESOLUTION_TYPES.XS;
  const isCompactMode = isExtraSmallScreen || isMobile;
  const headerMenuClassWrapper = classnames({
    'header-menu': true,
    'header-menu-compact': isCompactMode,
  });
  return (
    <div className="header">
      <div className={headerMenuClassWrapper}>
        <div className="header-tile sidebar-btn">
          <IconButton onClick={toggleSidebar} icon="menu" />
        </div>
        {!isCompactMode && <div className="avatar">&nbsp;
          <span className="icon logo" />
        </div>}
      </div>
      <div className="header-left-controls">
        <div className="header-tile">
          <div className="header-tile-wrapper header-agenda-selection">
            {!isExtraSmallScreen && <><Translation tKey="Firm" />:</>} <Agenda />
          </div>
        </div>
        <div className="header-tile">
          <IconButton onClick={createSignUpCompanyToken} icon="plus-circle" titleKey="Tooltips.Icons.AddCompany" />
        </div>
      </div>
      {!isExtraSmallScreen && <div className="header-center-controls">
        <PageTitle />
      </div>}
      <div className="header-right-controls">
        <HistoryDropDown />
        <AccountMenu />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    resolution: state.app.resolution
  };
};

const mapDispatchToProps = {
  toggleSidebar,
  createSignUpCompanyToken
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
