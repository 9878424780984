import * as types from '../actions/actionTypes';
import { partnerInvitationInitialState } from './initialStates';

const partnerInvitation = (state = partnerInvitationInitialState, action) => {
  switch (action.type) {
    case types.PARTNER_INVITATION_ACCEPT_BY_TOKEN_SUCCESS:
      return {
        ...state,
        partnerInvitationAccepted: action.data.result
      };
    case types.PARTNER_INVITATION_REJECT_BY_TOKEN_SUCCESS:
      return {
        ...state,
        partnerInvitationRejected: action.data.result
      };
    default:
      return state;
  }
};

export default partnerInvitation;
