import React from 'react';
export interface TooltipContentTemplateProps {
  title: string,

}

export const TOOLTIP_SEPARATOR = '##';

const TooltipContentTemplate = (props: TooltipContentTemplateProps) => {
  const { title } = props;

  const titleStrings = title.split(TOOLTIP_SEPARATOR);

  if (titleStrings.length === 1) {
    return <>{title}</>;
  }

  return (
    <>
      {titleStrings.map((titleString: string, index: number) => <div key={index}>{titleString}<br/></div>)}
    </>
  );
};
export default TooltipContentTemplate;

