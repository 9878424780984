import React from 'react';
import { connect } from 'react-redux';
import { filterTriggers } from '../generalFunctions';

import SubmitButton from './SubmitButton';
import { RootState } from '../../common/types/reduxStateTypes';
import { ButtonLook, ButtonMode } from '../../common/types/uiTypes';

export type SubmitLoadingButtonProps = {
  children: React.ReactNode,
  icon?: string,
  type: string | string[],
  look?: ButtonLook,
  actionType?: ButtonMode,
  triggers: Array<{ total: number, name: string, success: boolean }>,
  disabled?: boolean,
  style?: React.CSSProperties,
  className?: string,
  testId?: string,
  onClick: (...args: any[]) => any
}

const SubmitLoadingButton = (props: SubmitLoadingButtonProps) => {
  const { type = [], triggers, children } = props;
  const triggerTypes = Array.isArray(type) ? type : [type];
  const filteredTriggers: { total: number }[] = filterTriggers(triggers, { include: triggerTypes });
  const loading = filteredTriggers.some(t => t.total > 0);

  return (
    <SubmitButton {...props} loading={loading}>
      <>{children}</>
    </SubmitButton>);
};

const mapStateToProps = (state: RootState) => {
  return {
    triggers: state.ajaxStatus.triggers
  };
};

export default connect(mapStateToProps)(SubmitLoadingButton);
