import * as types from '../actions/actionTypes';
import { userPermissionsInitialState } from './initialStates';

const permission = (state, action) => {
  switch (action.type) {
    case types.USER_PERMISSIONS_BULK_UPDATE_SUCCESS: {
      let { permissionId, data: { added, removed } } = action;
      let removedIds = removed.success.map(r => r.id);
      let addedIds = added.success.map(a => a.id);

      if (state.id !== permissionId) {
        return {
          ...state
        };
      }

      let { userIds } = state;
      let restIds = userIds.filter(i => !removedIds.includes(i));

      return {
        ...state,
        userIds: [...new Set([...addedIds, ...restIds])]
      };
    }
    default:
      return state;
  }
};

const userPermissions = (state = userPermissionsInitialState, action) => {
  switch (action.type) {
    case types.PERMISSIONS_FETCH_SUCCESS: {
      return {
        ...state,
        permissions: [...action.data.items]
      };
    }
    case types.USER_PERMISSIONS_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        permissions: state.permissions.map(p => permission(p, action))
      };
    }
    case types.PERMISSIONS_ROLES_FETCH_DATA_SUCCESS:
      return {
        ...state,
        roles: [...action.data.items]
      };
    default:
      return state;
  }
};

export default userPermissions;
