/* eslint-disable */
import * as types from '../actions/actionTypes';

const navigationMiddleware = history => ({ dispatch, getState }) => next => action => {
  if (action.type !== types.NAVIGATE_TO) {
    return next(action);
  }

  const state = getState();

  if (state.app.hasUnsavedChanges) {
    dispatch({
      type: types.APP_NEXT_LOCATION,
      nextLocation: action.path
    });
    return;
  }

  if (action.state !== undefined) {
    history.push(action.path, action.state);
  } else {
    history.push(action.path);
  }


  return next(action);
};

export default navigationMiddleware;
