import * as types from '../actions/actionTypes';
import { SIDEBAR_VIEW_MODES } from '../common/types/sidebarTypes';

export const initialState = {
  open: true,
  viewMode: SIDEBAR_VIEW_MODES.TEXT
};

const sideBar = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDEBAR_TOGGLE:
      return {
        ...state,
        open: !state.open,
        viewMode: state.open === true ? SIDEBAR_VIEW_MODES.ICONS : SIDEBAR_VIEW_MODES.TEXT
      };
    case types.SIDEBAR_SHOW:
      return {
        ...state,
        open: true
      };
    case types.SIDEBAR_HIDE:
      return {
        ...state,
        open: false
      };
    case types.SIDEBAR_VIEW_MODE:
      return {
        ...state,
        viewMode: action.viewMode
      };
    default:
      return state;
  }
};

export default sideBar;
