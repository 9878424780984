import axios from 'axios';
import * as types from './actionTypes';
import { API_PATHS } from '../common/data/routes';

export const createRegistrationPayment = (token) => (dispatch) => {
  dispatch({
    types: [types.PAYMENT_CREATE_REGISTRATION_REQUEST, types.PAYMENT_CREATE_REGISTRATION_SUCCESS, types.PAYMENT_CREATE_REGISTRATION_FAILURE],
    callAPI: () => axios.get(API_PATHS.CREATE_REGISTRATION_PAYMENT, { params: { token: token } }),
    callSuccessAction: (response) => {
      let { data: { data: comgateData } } = response;
      if (comgateData) {
        window.location = comgateData.redirect;
      }
    }
  });
};

export const getRegistrationPaymentInfo = (id) => (dispatch) => {
  dispatch({
    types: [types.PAYMENT_REGISTRATION_INFO_REQUEST, types.PAYMENT_REGISTRATION_INFO_SUCCESS, types.PAYMENT_REGISTRATION_INFO_FAILURE],
    callAPI: () => axios.get(API_PATHS.PAYMENT_REGISTRATION_INFO, { params: { id: id } }),
  });
};

export const getAuthorizedPaymentInfo = (id) => (dispatch) => {
  dispatch({
    types: [types.PAYMENT_AUTHORIZED_INFO_REQUEST, types.PAYMENT_AUTHORIZED_INFO_SUCCESS, types.PAYMENT_AUTHORIZED_INFO_FAILURE],
    callAPI: () => axios.get(API_PATHS.PAYMENT_AUTHORIZED_INFO, { params: { id: id } }),
  });
};
