import axios from 'axios';
import * as types from './actionTypes';
import { alertAdd } from './alertActions';
import * as alertTypes from './alertTypes';
import { navigateToPartnerInvitations } from './navigationActions';

export const getPartnerInvitationGetInfo = () => (dispatch) => {
  dispatch({
    type: types.PARTNER_INVITATION_GET
  });
};

export const createPartnerInvitation = (partnerInvitation) => (dispatch) => {
  dispatch({
    types: [
      types.PARTNER_INVITATION_ADD_REQUEST,
      types.PARTNER_INVITATION_ADD_SUCCESS,
      types.PARTNER_INVITATION_ADD_FAILURE],
    callAPI: () => axios.post(`api/PartnerInvitation`, { ...partnerInvitation }),
    callSuccessAction: () => {
      dispatch({
        type: types.PARTNER_INVITATION_ADD
      });

      if (partnerInvitation.emailIsMandatory) {
        dispatch(alertAdd('PartnerInvitationForPartnerCandidateWasInvited', alertTypes.INFO, { email: partnerInvitation.partnerEmail, company: partnerInvitation.partnerName }));
      } else {
        dispatch(alertAdd('PartnerInvitationForPartnerWasInvited', alertTypes.INFO, { company: partnerInvitation.partnerName }));
      }

      dispatch(navigateToPartnerInvitations());
    }
  });
};

export const acceptInvitationPartner = (token) => (dispatch) => {
  dispatch({
    types: [
      types.PARTNER_INVITATION_ACCEPT_BY_TOKEN_REQUEST,
      types.PARTNER_INVITATION_ACCEPT_BY_TOKEN_SUCCESS,
      types.PARTNER_INVITATION_ACCEPT_BY_TOKEN_FAILURE],
    callAPI: () => axios.put(`api/partnerInvitation/updateStateByToken?token=${token}`),
  });
};

export const rejectInvitationPartner = (token) => (dispatch) => {
  dispatch({
    types: [
      types.PARTNER_INVITATION_REJECT_BY_TOKEN_REQUEST,
      types.PARTNER_INVITATION_REJECT_BY_TOKEN_SUCCESS,
      types.PARTNER_INVITATION_REJECT_BY_TOKEN_FAILURE],
    callAPI: () => axios.put(`api/partnerInvitation/updateStateByToken?token=${token}`),
  });
};
