export enum VatRateTypeEnum {
  NotSet = 0,
  Zero = 1,
  ReduceRate1 = 2,
  ReduceRate2 = 3,
  Basic = 4,
  ReduceRate = 5
}

export enum PriceTypeEnum {
  NotSet,
  WithOutVat,
  WithVat,
  OnlyWithOutVat,
  OnlyVat
}

export enum InvoiceItemTypeEnum {
  NotSet,
  Standard,
  Advance,
  Return,
  Rounding
}

export enum InvoiceAccountingTypeEnum {
  NotSet,
  ReverseCharge
}
