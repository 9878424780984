/* eslint-disable default-case */
import { combineReducers } from 'redux';
import sideBar from './sideBarReducer';
import app from './appReducer';
import appToken from './appTokenReducer';
import user from './userReducer';
import ajaxStatus from './ajaxStatusReducer';
import alerts from './alertReducer';
import accountMenu from './accountMenuReducer';
import table from './tableReducer';
import payment from './paymentReducer';
import partnerInvitation from './partnerInvitationReducer';
import companyVerification from './companyVerificationReducer';
import myAccount from './myAccountReducer';
import dashboard from './dashboardReducer';
import * as types from '../actions/actionTypes';
import { connectRouter } from 'connected-react-router';
import company from './companyReducer';
import userPermissions from './userPermissionsReducer';
import workflow from './workflowReducer';
import agenda from './agendaReducer';
import virtual from './virtualReducer';
import notification from './notificationReducer';
import billing from './billingReducer';
import userAppSettings from './userAppSettingsReducer';
import archive from './archiveReducer';
import userFilters from './userFiltersReducer';
import notificationsSetting from './notificationsSettingReducer';
import userTableFilters from './userTableFiltersReducer';
import attachments from './attachmentsReducer';

const crossReducer = (state = {}, action) => {
  switch (action.type) {
    case types.APP_RESET:
      state = undefined;
  }

  return state;
};

const cmbReducer = (history) => combineReducers({
  app,
  appToken,
  user,
  sideBar,
  ajaxStatus,
  alerts,
  accountMenu,
  table,
  payment,
  partnerInvitation,
  companyVerification,
  myAccount,
  dashboard,
  company,
  userPermissions,
  workflow,
  agenda,
  notification,
  virtual,
  billing,
  userAppSettings,
  archive,
  userFilters,
  userTableFilters,
  notificationsSetting,
  attachments,
  router: connectRouter(history),
});

const rootReducer = (history) => (state, action) => {
  state = crossReducer(state, action);
  return cmbReducer(history)(state, action);
};

export default rootReducer;
