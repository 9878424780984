import { Reducer } from 'redux';
import * as types from '../actions/actionTypes';
import { IAppTokenState, AppTokenStateActionTypes } from '../components/settings/myAccount/MyAccountTypes';

export const initialState: IAppTokenState = {
  id: 0,
  name: '',
  clientId: '',
  clientSecret: '',
};

const appTokenReducer: Reducer<IAppTokenState, AppTokenStateActionTypes> =
  (state = initialState, action: AppTokenStateActionTypes) => {
    switch (action.type) {
      case types.APP_FETCH_SUCCESS: {
        return {
          ...state,
          ...action.data,
        };
      }
      default:
        return state;
    }
  };

export default appTokenReducer;
