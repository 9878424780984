import React, { useState } from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import AnonymousZone from './AnonymousZone';
import Translation from '../common/Translation';
import { CheckboxField as Checkbox } from '../common/Checkbox';
import Input from '../common/Input';
import TextArea from '../common/TextArea';
import SubmitLoadingButton from '../common/SubmitLoadingButton';
import Button from '../common/Button';
import FloatingLabelField from '../common/FloatingLabelField';
import { Field } from '../common/Field';
import { CONTACT_US_SEND } from '../../actions/actionTypes';
import { NAVIGATION_PATHS } from '../../common/data/routes';
import { SendMailModel } from '../../api/models/SendMailModel';
import FormChangeSpy from '../common/FormChangeSpy';
import { validateFieldLength, validateEmail } from '../generalFunctions';
import { sendEmail } from '../../actions/userActions';
import { setHasUnsavedChanges } from '../../actions/appActions';
import { datariosSupportMail } from '../../common/data/common';
import { RootState } from '../../common/types/reduxStateTypes';

interface IFormProps {
  who: string,
  telephone: string,
  email: string,
  message: string,
  gdpr: boolean
}

interface IContactSupportProps {
  hasChanges: boolean,
  sendEmail: (mail: SendMailModel, callback?: () => void) => void,
  setChanges: (hasChanges: boolean) => void,
}

export const supportReasons = {
  activationWasLocked: 'activation-was-locked',
  noDataBox: 'no-databox',
};

export const getSupportAddress = (reason: string) => {
  return `${NAVIGATION_PATHS}?reason=${reason}`;
};

const ContactSupport = (props: IContactSupportProps) => {
  const { t } = useTranslation();
  const { sendEmail, setChanges } = props;
  const [emailWasSent, setEmailWasSent] = useState(false);
  const info = '';

  const match = useLocation();
  const { reason } = queryString.parse(match.search);
  const showMessage = !reason;

  const validateForm = (values: any) => {
    const errors: ValidationErrors = {};
    let options = {
      errors,
      formIsValid: true,
      data: values,
      t
    };

    options.formIsValid = validateFieldLength({ ...options, field: 'who' });
    options.formIsValid = validateFieldLength({ ...options, field: 'telephone' });
    options.formIsValid = validateFieldLength({ ...options, field: 'email' });
    if (values.gdpr !== true) {
      options.formIsValid = false;
      errors['gdpr'] = '';
    }

    return options.errors;
  };

  if (emailWasSent) {
    return (
      <AnonymousZone>
        <div className="box-wrapper">
          <div className="box-header-group">
            <div className="box-header"><Translation tKey="Support.EmailWasSentTitle" /></div>
            <div>
              <Trans i18nKey="Support.EmailWasSentSubtitle"
                components={[<a className="text-link underlined" href={NAVIGATION_PATHS.DATARIOS} rel="noreferrer">dummyContent</a>]} />
            </div>
          </div>

          <div className="box-actions">
            <div className="actions">
              <Button onClick={() => { window.location.href = NAVIGATION_PATHS.DATARIOS; }}>
                <Translation tKey="Support.GoOnWeb" />
              </Button>
            </div>
          </div>
        </div>
      </AnonymousZone>
    );
  }

  return (
    <AnonymousZone>
      <div className="box-wrapper two-column">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="Support.Title" /></div>
          <div><Translation tKey={info ? t(info) : 'Support.Subtitle'} /></div>
        </div>
        <Form
          onSubmit={(values) => {
            let data = values as IFormProps;
            sendEmail({
              to: datariosSupportMail,
              subject: t('Support.EmailSubject'),
              message: t('Support.EmailBody', data),
              title: reason ? t(reason) : t('Support.EmailTitle')
            }, () => setEmailWasSent(true));
          }}
          validate={validateForm}
          initialValues={{
            who: '',
            telephone: '',
            email: '',
            message: '',
            gdpr: false
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="k-form contact-us">
              <FormChangeSpy onFormChange={setChanges} />
              <div className="row-margin">
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <FloatingLabelField
                      component={Input}
                      name="who"
                      tLabelKey="ContactUs.Who"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <FloatingLabelField
                      component={Input}
                      name="telephone"
                      tLabelKey="Telephone"
                    />
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <FloatingLabelField
                      validate={(value) => {
                        if (!validateEmail(value)) {
                          return t('EmailIsnotCorrect');
                        }
                      }}
                      component={Input}
                      name="email"
                      tLabelKey="Email"
                    />
                  </div>
                </div>
                {showMessage &&
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <FloatingLabelField
                        component={TextArea}
                        name="message"
                        tLabelKey="ContactUs.Description"
                      />
                    </div>
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Field
                    name="gdpr"
                    type="checkbox"
                    component={Checkbox}
                    label={<Trans i18nKey="ContactUs.Gdpr"
                      components={[<a className="text-link underlined" href={NAVIGATION_PATHS.GDPR} target="_blank" rel="noreferrer">dummyContent</a>]} />}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 spaced-group">
                  <SubmitLoadingButton
                    type={CONTACT_US_SEND} onClick={handleSubmit} disabled={!values.gdpr}
                  >
                    <Translation tKey="Send" /></SubmitLoadingButton>
                </div>
              </div>
            </form>)} />
      </div>
    </AnonymousZone>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    hasChanges: state.app.hasUnsavedChanges,
  };
};

const dispatchToProps = {
  sendEmail: sendEmail,
  setChanges: setHasUnsavedChanges,
};

export default compose(withTranslation('common'), connect(mapStateToProps, dispatchToProps))(ContactSupport);
