import axios from 'axios';
import * as types from './actionTypes';
import { alertAdd } from './alertActions';
import * as alertTypes from './alertTypes';
import * as responseTypes from '../common/types/responseTypes';
import i18n from '../i18n';
import { API_PATHS } from '../common/data/routes';
import { loadState, savePartialState } from '../components/common/LocalStorage';
import { setLang } from './appActions';

export const fetchMyAccount = () => (dispatch) => {
  dispatch({
    types: [types.MY_ACCOUNT_FETCH_REQUEST, types.MY_ACCOUNT_FETCH_SUCCESS, types.MY_ACCOUNT_FETCH_FAILURE],
    callAPI: () => axios.get(API_PATHS.MY_ACCOUNT),
    callSuccessAction: ({ data }) => {
      const langCode = data.langCode || "cs";
      dispatch(setLang(langCode));
    }
  });
};

export const updateMyAccount = (myAccount, callback) => (dispatch) => {
  dispatch({
    types: [types.MY_ACCOUNT_UPDATE_REQUEST, types.MY_ACCOUNT_UPDATE_SUCCESS, types.MY_ACCOUNT_UPDATE_FAILURE],
    callAPI: () => axios.put(API_PATHS.MY_ACCOUNT, { ...myAccount }),
    callSuccessAction: ({ data }) => {
      const localState = loadState();
      const app = { ...localState.app, lang: data.langCode };
      savePartialState({ app });
      dispatch(setLang(data.langCode));
      if (callback) {
        callback();
      }
      dispatch(alertAdd("SaveSuccess", alertTypes.SUCCESS, { topic: i18n.t('MyAccount.Title') }));
    }
  });
};

export const setNewPassword = (newPassword, currentPassword, callback) => (dispatch) => {
  dispatch({
    types: [types.MY_ACCOUNT_SET_NEW_PASSWORD_REQUEST, types.MY_ACCOUNT_SET_NEW_PASSWORD_SUCCESS, types.MY_ACCOUNT_SET_NEW_PASSWORD_FAILURE],
    callAPI: () => axios.post(API_PATHS.MY_ACCOUNT_NEW_PASSWORD, { newPassword, currentPassword }),
    callSuccessAction: (response) => {
      let { code, invalidPassword } = (response || {}).data;
      if (code !== responseTypes.FAILED) {
        dispatch(alertAdd("PasswordWasChanged", alertTypes.SUCCESS));
        if (callback) {
          callback();
        }
      } else if (invalidPassword) {
        dispatch(alertAdd("InvalidPassword", alertTypes.ERROR));
      }
    }
  });
};

export const fetchAppToken = (id) => (dispatch) => {
  dispatch({
    types: [types.APP_FETCH_REQUEST, types.APP_FETCH_SUCCESS, types.APP_FETCH_FAILURE],
    callAPI: () => axios.get(`${API_PATHS.APP}/${id}`),
  });
};

export const createAppToken = (values, callback) => (dispatch) => {
  dispatch({
    types: [types.APP_CREATE_REQUEST, types.APP_CREATE_SUCCESS, types.APP_CREATE_FAILURE],
    callAPI: () => axios.post(API_PATHS.APP, values),
    callSuccessAction: () => {
      dispatch(alertAdd("SaveSuccess", alertTypes.SUCCESS));
      if (callback) {
        callback();
      }
    }
  });
};

export const updateAppToken = (appToken) => (dispatch) => {
  dispatch({
    types: [types.APP_UPDATE_REQUEST, types.APP_UPDATE_SUCCESS, types.APP_UPDATE_FAILURE],
    callAPI: () => axios.put(API_PATHS.APP, { ...appToken }),
    callSuccessAction: () => dispatch(alertAdd("SaveSuccess", alertTypes.SUCCESS))
  });
};
