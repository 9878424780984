import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';

import SignIn from '../anonymousZone/SignIn';
import SignUp from '../anonymousZone/SignUp';
import PaymentGate from '../anonymousZone/payments/PaymentGate';
import PaymentStatus from '../anonymousZone/payments/PaymentStatus';
import CompanyVerificationStatus from '../anonymousZone/signUp/CompanyVerificationStatus';
import ForgottenPassword from '../anonymousZone/ForgottenPassword';
import ResetPassword from '../anonymousZone/ResetPassword';
import SelectAgenda from '../anonymousZone/SelectAgenda';
import EmailVerification from '../anonymousZone/signUp/EmailVerification';
import CompanyInvitationReview from '../anonymousZone/companyInvitation/CompanyInvitationReview';
import PartnerAcceptInvitationStateByToken from '../partners/PartnerAcceptInvitationStateByToken';
import PartnerRejectInvitationStateByToken from '../partners/PartnerRejectInvitationStateByToken';
import ContactSupport from '../anonymousZone/ContactSupport';
import DownloadArchivePackage from '../anonymousZone/DownloadArchivePackage';

import App from "./App";
import { NAVIGATION_PATHS } from '../../common/data/routes';
import ErrorBoundary from '../common/ErrorBoundary';

class Layout extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <LocalizationProvider language={this.props.lang}>
          <IntlProvider locale={this.props.lang}>
            <Switch>
              <Route exact path={NAVIGATION_PATHS.SIGN_IN} component={SignIn} />
              <Route exact path={NAVIGATION_PATHS.SIGN_UP} component={SignUp} />
              <Route exact path={NAVIGATION_PATHS.PAYMENT} component={PaymentGate} />
              <Route exact path={NAVIGATION_PATHS.PAYMENT_STATUS} component={PaymentStatus} />
              <Route exact path={NAVIGATION_PATHS.COMPANY_VERIFICATION} component={CompanyVerificationStatus} />
              <Route exact path={NAVIGATION_PATHS.EMAIL_VERIFICATION} component={EmailVerification} />
              <Route exact path={NAVIGATION_PATHS.FORGOTTEN_PASSWORD} component={ForgottenPassword} />
              <Route exact path={NAVIGATION_PATHS.RESET_PASSWORD} component={ResetPassword} />
              <Route exact path={NAVIGATION_PATHS.AGENDA_SELECTION} component={SelectAgenda} />
              <Route exact path={NAVIGATION_PATHS.ACCEPT_PARTNER} component={PartnerAcceptInvitationStateByToken} />
              <Route exact path={NAVIGATION_PATHS.REJECT_PARTNER} component={PartnerRejectInvitationStateByToken} />
              <Route exact path={NAVIGATION_PATHS.COMPANY_INVITATION} component={CompanyInvitationReview} />
              <Route path={NAVIGATION_PATHS.SUPPORT} component={ContactSupport} />
              <Route exact path={NAVIGATION_PATHS.ARCHIVE_PACKAGE_DOWNLOAD} component={DownloadArchivePackage} />
              <Route component={App} />
            </Switch>
          </IntlProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    );
  }
}

Layout.propTypes = {
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.app.lang || 'cs'
  };
};


export default connect(mapStateToProps, undefined)(Layout);
