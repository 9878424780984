import * as types from '../actions/actionTypes';
import { workflowInitialState } from './initialStates';
import _ from 'lodash';

const workflowAgreementProcessStep = (state, action) => {
  switch (action.type) {
    case types.WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK_UPDATE_SUCCESS: {
      let { workflowAgreementProcessStepId, data: { added, removed } } = action;
      let removedIds = removed.success.map(r => r.id);
      let addedIds = added.success.map(a => a.id);

      if (state.id !== workflowAgreementProcessStepId) {
        return {
          ...state
        };
      }

      let { userIds } = state;
      let restIds = userIds.filter(i => !removedIds.includes(i));

      return {
        ...state,
        userIds: [...new Set([...addedIds, ...restIds])]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEP_UPDATE_SUCCESS: {
      if (state.id !== action.id) {
        return {
          ...state
        };
      }

      return {
        ...state,
        name: action.data.name,
      };
    }
    default:
      return state;
  }
};

const workflowVariable = (state, action) => {
  switch (action.type) {
    case types.WORKFLOW_VARIABLE_UPDATE_SUCCESS: {
      if (state.id !== action.id) {
        return {
          ...state
        };
      }

      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
};

const workflow = (state = workflowInitialState, action) => {
  switch (action.type) {
    case types.WORKFLOW_VARIABLE_TYPES_FETCH_SUCCESS: {
      return {
        ...state,
        variableTypes: [...action.data.items]
      };
    }
    case types.WORKFLOW_VARIABLES_SET: {
      return {
        ...state,
        variables: [...state.variables.filter(v => v.workflowVariableTypeId !== action.id), ...action.items]
      };
    }
    case types.WORKFLOW_VARIABLE_ADD_SUCCESS: {
      return {
        ...state,
        variables: [...state.variables, { ...action.data }]
      };
    }
    case types.WORKFLOW_VARIABLE_UPDATE_SUCCESS: {
      return {
        ...state,
        variables: state.variables.map(v => workflowVariable(v, action))
      };
    }
    case types.WORKFLOW_VARIABLE_REMOVE_SUCCESS: {
      let index = state.variables.map(i => { return i.id; }).indexOf(action.id);
      return {
        ...state,
        variables: [...state.variables.slice(0, index), ...state.variables.slice(index + 1)]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEPS_FETCH_BY_SCENARIO_SUCCESS: {
      return {
        ...state,
        agreementProcessSteps: [...action.data.items]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEP_ADD_SUCCESS: {
      return {
        ...state,
        agreementProcessSteps: [...state.agreementProcessSteps, { ...action.data, userIds: [] }]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEP_REMOVE_SUCCESS: {
      let index = state.agreementProcessSteps.map(i => { return i.id; }).indexOf(action.id);
      return {
        ...state,
        agreementProcessSteps: [...state.agreementProcessSteps.slice(0, index), ...state.agreementProcessSteps.slice(index + 1)]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEP_USERS_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        agreementProcessSteps: state.agreementProcessSteps.map(aps => workflowAgreementProcessStep(aps, action))
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEP_UPDATE_SUCCESS: {
      return {
        ...state,
        agreementProcessSteps: state.agreementProcessSteps.map(aps => workflowAgreementProcessStep(aps, action))
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_STEPS_SORT: {
      return {
        ...state,
        agreementProcessSteps: [...action.data]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_SCENARIO_FETCH_SUCCESS: {
      return {
        ...state,
        agreementProcessScenario: action.data
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_SCENARIOS_FETCH_SUCCESS: {
      return {
        ...state,
        agreementProcessScenarios: [...action.data.items]
      };
    }
    case types.WORKFLOW_AGREEMENT_PROCESS_USER_ACCESSIBLE_STEP_IDS_FETCH_SUCCESS: {
      return {
        ...state,
        accessibleStepIds: [...action.data.items]
      };
    }
    case types.WORKFLOW_QUEUE_STATES_FETCH_SUCCESS: {
      return {
        ...state,
        queueStates: [...action.data.items]
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_FETCH_EMAIL_SUCCESS: {
      return {
        ...state,
        emailRecord: { ...action.data }
      };
    }
    case types.WORKFLOW_SCENARIO_UPDATE_SUCCESS:
    case types.WORKFLOW_SCENARIO_FETCH_SUCCESS: {
      return {
        ...state,
        scenario: action.data
      };
    }
    case types.WORKFLOW_SCENARIOS_FETCH_SUCCESS: {
      return {
        ...state,
        scenarios: [...action.data.items]
      };
    }
    case types.WORKFLOW_SCENARIOS_FETCH_WITHOUT_DELETED_SUCCESS: {
      return {
        ...state,
        activeScenarios: [...action.data.items]
      };
    }
    case types.WORKFLOW_FETCH_SCENARIO_CONDITION_MEMBERS_SUCCESS: {
      return {
        ...state,
        scenarioConditionMembers: [...action.data.items]
      };
    }
    case types.WORKFLOW_FETCH_STEP_ACTIONS_SUCCESS: {
      return {
        ...state,
        stepActions: [...action.data.items]
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_FETCH_HISTORY_SUCCESS: {
      return {
        ...state,
        historyRecords: [...action.data.items]
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_FETCH_INFO_SUCCESS: {
      return {
        ...state,
        info: { ...action.data }
      };
    }
    case types.WORKFLOW_TAB_TOGGLE_QUEUE_FILTER: {
      return {
        ...state,
        queueFilter: !state.queueFilter
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_FETCH_RELATED_SUCCESS: {
      let item = { id: action.id, items: [...action.data.items] };
      let _related = item;
      if (state.related) {
        _related = _.cloneDeep(state.related);
        let _index = state.related.findIndex(i => i.id === action.data.id);
        if (_index === -1) {
          _related.push(item);
        } else {
          _related = _related.splice(_index, 0, item);
        }
      }

      return {
        ...state,
        related: _related
      };
    }
    case types.WORKFLOW_STATISTICS_CHART_TOTAL_DATA_SUCCESS:
      return {
        ...state,
        chart: { series: action.data.series, categories: action.data.categories }
      };
    case types.WORKFLOW_QUEUE_RECORD_EMAIL_HISTORY_SUCCESS: {
      return {
        ...state,
        mailToHistory: { ...action.data }
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_GRAPH_SUCCESS: {
      return {
        ...state,
        graph: { ...action.data },
        graphState: 'loaded'
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_GRAPH_FAILURE: {
      return {
        ...state,
        graph: null,
        graphState: 'failed'
      };
    }
    case types.WORKFLOW_QUEUE_RECORD_GRAPH_RESET: {
      return {
        ...state,
        graph: undefined,
        graphState: 'reset'
      };
    }
    case types.VAT_RATES_FETCH_SUCCESS: {
      const vatRates = action.data.items.map((rate) => ({
        ...rate,
        validFrom: rate.validFrom ? new Date(rate.validFrom) : null,
        validTo: rate.validTo ? new Date(rate.validTo) : null,
      }));
      vatRates.sort((rate1, rate2) => rate1.percent - rate2.percent);
      return {
        ...state,
        vatRates,
      };
    }
    case types.WORKFLOW_SCENARIO_MEMBERS_DATA: {
      return {
        ...state,
        scenarioConditionMembersData: action.data,
      };
    }
    default:
      return state;
  }
};

export default workflow;
