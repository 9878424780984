import React from 'react';
import { Trans } from 'react-i18next';
import { NAVIGATION_PATHS } from '../../common/data/routes';

import i18n from '../../i18n';

const AgreeGDPR = () => {
  return (<Trans i18nKey="AgreeGDPR.Text1"
    values={{ gdpr: i18n.t('AgreeGDPR.Text2') }}
    components={[
      <a key={1} target="_blank" rel="noreferrer"
        href={NAVIGATION_PATHS.GDPR}
        className="text-link underlined"
      >dummyContent</a>
    ]} />);
};

export default AgreeGDPR;
