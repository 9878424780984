import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';

import { createRegistrationPayment } from '../../../actions/paymentActions';
import SubmitButton from '../../common/SubmitButton';
import Translation from '../../common/Translation';
import './PaymentGate.scss';
import AnonymousZone from '../AnonymousZone';
import iconVisa from '../../../images/payment-visa.svg';
import iconMastercard from '../../../images/payment-mastercard.svg';
import iconMaestro from '../../../images/payment-maestro.svg';

class PaymentGate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  onChange = (event) => {
    let account = { ...this.state.account };
    account[event.target.name] = event.target.value;

    return this.setState({ account });
  };

  createPayment = (event) => {
    event.preventDefault();

    this.props.createRegistrationPayment(this.props.token);
  };

  render() {
    return (
      <AnonymousZone>
        <div className="payment">
          <div className="box-header-group">
            <div className="box-header"><Translation tKey="PaymentGate" /></div>
            <div><Translation tKey="PaymentGateMessage" /></div>
          </div>
          <div>
            <form>
              <div className="row-margin">
                <SubmitButton onClick={this.createPayment} className="button button-ok">
                  <Translation tKey="Pay" />
                </SubmitButton>
              </div>
              <div className="row">
                <div className="payment-icons">
                  <img src={iconVisa} alt="visa" />
                  <img src={iconMastercard} alt="mastercard" />
                  <img src={iconMaestro} alt="maestro" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </AnonymousZone>
    );
  }
}

PaymentGate.propTypes = {
  createRegistrationPayment: PropTypes.func,
  loading: PropTypes.bool,
  token: PropTypes.string,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let { token } = queryString.parse(ownProps.location.search);
  return {
    token,
    loading: state.ajaxStatus.totalTriggers > 0
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRegistrationPayment: bindActionCreators(createRegistrationPayment, dispatch)
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(PaymentGate);
