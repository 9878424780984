import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './IconButton.scss';

interface IconButtonProps {
  icon: string,
  actionType?: 'primary' | 'secondary' | 'danger' | 'inactive',
  type?: 'icon' | 'link',
  onClick?: () => void,
  disabled?: boolean,
  children?: React.ReactNode,
  className?: string,
  titleKey?: string,
  style?: React.CSSProperties,
  testId?: string,
}

const IconButton = (props: IconButtonProps) => {
  const {
    icon, actionType, onClick, disabled = false, children, className = '', type = 'icon', titleKey, testId, style, ...rest
  } = props;
  const { t } = useTranslation();
  const wrapperClass = classNames({
    'icon-button': true,
    'icon-button-disabled': disabled,
    [`icon icon-${icon}`]: icon,
    [`icon-action-${actionType}`]: actionType,
    'icon-button-link': type === 'link',
    [className]: className,
  });

  return (
    <a
      className={wrapperClass}
      title={titleKey && !disabled ? t(titleKey) : ''}
      style={{ textDecoration: 'none', cursor: disabled ? 'inherit' : 'pointer', ...style }}
      data-testid={testId}
      onClick={(event) => {
        event.preventDefault();
        document.querySelector('.a-button');
        if (!disabled && onClick) {
          onClick();
        }
      }}
      {...rest}
    >
      {children}
    </a>
  );
};

export default IconButton;
