import { Input as KendoInput } from '@progress/kendo-react-inputs';
import KendoHOC from './utils/KendoHOC';

const Input = (options) => {
  const { size = 'medium', ...props } = options;
  // temporarily until kendo support input size
  const style = { lineHeight: size === 'small' ? '2em' : '2.7em' };

  return KendoHOC(KendoInput, { ...props, style });
};

export default Input;
