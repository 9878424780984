export const PERMISSION_TYPES = {
  ARCHIVE: 'Archive',
  ARCHIVE_PACKAGES: 'ArchivePackages',
  ARCHIVE_MANAGE: 'ArchiveManage',
  ARCHIVE_LOGGING: 'ArchiveLogging',
  DEVICE: 'Device',
  INVOICE_INCOMING: 'InvoiceIncoming',
  INVOICE_OUTGOING: 'InvoiceOutgoing',
  MANAGE_COMPANY: 'ManageCompany',
  MANAGE_DEVICE: 'ManageDevice',
  MANAGE_PARTNERS: 'ManagePartners',
  MANAGE_PAYMENTS: 'ManagePayments',
  MANAGE_USERS: 'ManageUsers',
  WORKFLOW: 'Workflow',
  WORKFLOW_QUEUE: 'WorkflowQueue',
  WORKFLOW_OUTPUT_QUEUE: 'WorkflowOutputQueue',
  MANAGE_WORKFLOW: 'ManageWorkflow',
  WORKFLOW_STATISTICS: 'WorkflowStatistics',
  WORKFLOW_ARCHIVE: 'WorkflowArchive',
  WORKFLOW_AGREEMENT_PROCESS: 'WorkflowAgreementProcess',
  WORKFLOW_USER_HISTORY: 'WorkflowUserHistory',
};
