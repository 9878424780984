import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import queryString from 'query-string';

import { getCompanyVerification, sendCompanyVerification, verifyCompanyVerificationCode } from '../../../actions/companyVerificationActions';
import { navigateTo } from '../../../actions/navigationActions';
import Translation from '../../common/Translation';
import SubmitButton from '../../common/SubmitButton';
import TextBox from '../../common/TextBox';
import PaymentCanceled from '../payments/PaymentCanceled';
import PaymentPending from '../payments/PaymentPending';
import { COMPANY_VERIFICATION_TYPE } from '../../../common/types/companyVerificationTypes';
import AnonymousZone from '../AnonymousZone';
import { NAVIGATION_PATHS } from '../../../common/data/routes';
import './CompanyVerificationStatus.scss';

class CompanyVerificationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        code: ''
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.props.getCompanyVerification(this.props.token);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      if (this.props.errors) {
        this.setState({ errors: this.props.errors });
      }
    }
  }

  onSent = () => {
    this.props.sendCompanyVerification(this.props.token);
  };

  onVerify = (e) => {
    e.preventDefault();

    let { form } = this.state;
    let { code } = form;

    if (form.code === undefined || form.code.length < 1) {
      let { errors } = this.state;
      errors.code = this.props.t("CodeIsRequired");

      this.setState({ errors });

      return;
    }

    this.props.verifyCompanyVerificationCode(this.props.token, code);
  };

  onChange = (e) => {
    let { form } = { ...this.state };
    form[e.target.name] = e.target.value;
    let errors = {};
    if (!form.code || form.code.length < 1) {
      errors = { code: this.props.t("CodeIsRequired") };
    }

    this.setState({ form, errors });
  };

  mapCompanyVerificationStateToRenderFunc = (state) => {
    switch (state) {
      case COMPANY_VERIFICATION_TYPE.ReadyToBeSent:
        return this.renderReadyToBeSent;

      case COMPANY_VERIFICATION_TYPE.Send:
      case COMPANY_VERIFICATION_TYPE.VerificationFailed:
        return this.renderSend;

      case COMPANY_VERIFICATION_TYPE.SentFailed:
        return this.renderSentFailed;

      case COMPANY_VERIFICATION_TYPE.Locked:
        return this.renderLocked;

      case COMPANY_VERIFICATION_TYPE.Verified:
        return this.renderVerified;

      case COMPANY_VERIFICATION_TYPE.PaymentCanceled:
        return this.renderPaymentCanceled;

      case COMPANY_VERIFICATION_TYPE.PaymentPending:
        return this.renderPaymentPending;

      default:
        return this.renderFailed;
    }
  };

  renderPaymentPending = () => {
    let { location: { pathname, search }, companyVerification: { data } } = this.props;

    return (<PaymentPending token={data} navigateTo={`${pathname}${search}`} restartPaymentTo={`/restart-payment${search}`} />);
  };

  renderPaymentCanceled = () => {
    let { companyVerification: { data } } = this.props;

    return (<PaymentCanceled token={data} />);
  };

  renderVerified = () => {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="ThankYouForRegistration" /></div>
          <div><Translation tKey="YourCompanyHasBeenVerified" /></div>
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SIGN_IN)} loading={this.props.loading}>
              <Translation tKey="SignInGo" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  };

  renderLocked = () => {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="VerificationOfCompanyIdentity" /></div>
          <div><Translation tKey="ActivationOfYourCompanyHasBeenLocked" /> <Translation tKey="PleaseContactSupport" /></div>
        </div>
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => this.props.navigateTo(NAVIGATION_PATHS.SUPPORT)} loading={this.props.loading}>
              <Translation tKey="Support.Button" />
            </SubmitButton>
          </div>
        </div>
      </div>
    );
  };

  renderNumberOfAttemps = () => {
    let { companyVerification } = this.props;
    let { numberOfVerificationAttempts } = companyVerification;

    if (numberOfVerificationAttempts === 7) {
      return;
    }

    return (
      <div className="signin-number-of-attemps">
        <Translation tKey="NumberOfRemainingAttempts" />: {numberOfVerificationAttempts}
      </div>
    );
  };

  renderSend = () => {
    let { form } = this.state;
    let { errors } = this.state;

    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="VerificationOfCompanyIdentity" /></div>
          <div>
            <Trans
              i18nKey="common:VerificationCodeWasSentInToYourDataBox"
              components={[<a className="text-link underlined" target="_blank" href={NAVIGATION_PATHS.DATABOX} rel="noreferrer">dummyContent</a>]} />
          </div>
        </div>
        <form>
          <div className="row row-margin">
            <div className="col-md-9" style={{ paddingRight: 0 }}>
              <TextBox name="code"
                onChange={this.onChange}
                value={form.code}
                placeholder={this.props.t('VerificationCode')}
                error={errors.code} />
            </div>
            <div className="col-md-3">
              <SubmitButton className="k-primary full-size-button" onClick={this.onVerify} loading={this.props.loading}>
                <Translation tKey="Verify" />
              </SubmitButton>
            </div>
          </div>
          {this.renderNumberOfAttemps()}
        </form >
      </div >
    );
  };

  renderReadyToBeSent = () => {
    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="VerificationOfCompanyIdentity" /></div>
          <div><Translation tKey="CompanyVerificationStatusReadyToSendText" /></div>
        </div>
        <div className="box-actions row-margin">
          <div className="action">
            <SubmitButton onClick={this.onSent} className="button button-ok" loading={this.props.loading}><Translation tKey="SendDataMessage" /></SubmitButton>
          </div>
        </div>
        <div className="note">
          <Translation tKey="CompanyVerificationStatusReadyToSendNote" />
        </div>
        <Translation tKey="ThankYouForYourPatience" />
      </div>
    );
  };

  renderSentFailed = () => {
    let { companyVerification } = this.props;
    let { numberOfSentAttempts } = companyVerification;

    return (
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="VerificationOfCompanyIdentity" /></div>
          <div><Translation tKey="SendDataMessageToYourDataBoxFailed" /></div>
        </div>
        <div className="row-margin"><Translation tKey="NumberOfAttempts" />: <b>{numberOfSentAttempts}</b></div>
        <div className="row-margin">
          <Translation tKey="CompanyVerificationStatusSentFailedNote" />
        </div>
        <div className="box-actions row-margin">
          <div className="action">
            <SubmitButton onClick={this.onSent} className="button button-ok"><Translation tKey="SendDataMessageAgain" /></SubmitButton>
          </div>
        </div>
      </div>
    );
  };

  renderFailed = () => {
    return (
      <div>
        <Translation tKey="WaitForASecond" />
      </div>
    );
  };

  render() {
    let { companyVerification: { companyVerificationState } } = this.props;
    let renderFunc = this.mapCompanyVerificationStateToRenderFunc(companyVerificationState);

    return (
      <AnonymousZone>
        {renderFunc && renderFunc()}
      </AnonymousZone>
    );
  }
}

CompanyVerificationStatus.propTypes = {
  getCompanyVerification: PropTypes.func,
  sendCompanyVerification: PropTypes.func,
  verifyCompanyVerificationCode: PropTypes.func,
  goToRoot: PropTypes.func,
  token: PropTypes.string,
  loading: PropTypes.bool,
  companyVerification: PropTypes.object,
  t: PropTypes.func.isRequired,
  errors: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  let { companyVerification } = state;
  let errors = {};

  return {
    token: queryString.parse(ownProps.location.search).token,
    companyVerification: { ...companyVerification },
    errors,
    loading: state.ajaxStatus.totalTriggers > 0
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyVerification: bindActionCreators(getCompanyVerification, dispatch),
    sendCompanyVerification: bindActionCreators(sendCompanyVerification, dispatch),
    verifyCompanyVerificationCode: bindActionCreators(verifyCompanyVerificationCode, dispatch),
    navigateTo: bindActionCreators(navigateTo, dispatch),
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(CompanyVerificationStatus);
