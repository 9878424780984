import * as types from '../actions/actionTypes';

export const initialState = {
  totalTriggers: 0,
  triggers: []
};

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - '_SUCCESS'.length) === '_SUCCESS';
}

const triggerDown = (state, name, success) => {
  if (state.name === name) {
    return {
      total: state.total > 0 ? state.total - 1 : 0,
      name,
      success
    };
  }

  return state;
};

export default function ajaxStatusReducer(state = initialState, action) {
  if (action.type === types.BEGIN_AJAX_CALL) {
    let index = state.triggers.findIndex(t => t.name === action.name);
    let triggers = [];
    if (index !== -1) {
      triggers = [
        ...state.triggers.slice(0, index),
        {
          total: state.triggers[index].total + 1,
          name: state.triggers[index].name,
          success: false
        },
        ...state.triggers.slice(index + 1),
      ];
    } else {
      triggers = [...state.triggers, { total: 1, name: action.name, success: false }];
    }

    return {
      totalTriggers: state.totalTriggers + 1,
      triggers,
    };
  } else if (actionTypeEndsInSuccess(action.type)) {
    return {
      totalTriggers: state.totalTriggers > 0 ? state.totalTriggers - 1 : 0,
      triggers: state.triggers.map(r => triggerDown(r, action.type.substring(0, action.type.length - '_SUCCESS'.length), true)),
    };
  } else if (action.type === types.AJAX_CALL_ERROR) {
    let actionName = action.name;
    if (actionName.includes('_FAILURE')) {
      actionName = action.name.substring(0, action.name.length - '_FAILURE'.length);
    }

    return {
      totalTriggers: state.totalTriggers > 0 ? state.totalTriggers - 1 : 0,
      triggers: state.triggers.map(r => triggerDown(r, actionName, false)),
    };
  }
  return state;
}
