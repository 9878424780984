import classNames from 'classnames';

import ErrorValidationMessage from '../ErrorValidationMessage';
import { HIGHLIGHT_CLASSNAME } from '../../../settings';

const KendoHOC = (type, props) => {
  const { id, icon, input, meta, className, highlightText, ...rest } = props;
  const Type = type;
  const isInvalid = (meta.error && meta.touched) || (meta.submitError && meta.pristine);

  const wrapperInputClass = classNames({
    [className]: className,
    'notification-input-error': isInvalid
  });

  const wrapperClass = classNames({
    'notification-container-error': isInvalid,
    [HIGHLIGHT_CLASSNAME]: highlightText && input.value.toString().includes(highlightText),
  });

  let errorMessage = meta.error || meta.submitError;
  if (meta.error && meta.error.message) {
    errorMessage = meta.error.message;
  }

  return (
    <div className={wrapperClass} id={id}>
      {icon && <span className={`icon icon-${icon}`}>&nbsp;</span>}
      <Type
        className={wrapperInputClass}
        {...input}
        {...rest}
      />
      {isInvalid && <ErrorValidationMessage errorMessage={errorMessage} />}
    </div>
  );
};

export default KendoHOC;
