import { VatRateTypeEnum } from "./invoiceTypes";

export type ArchiveDisplayModeType = 'GridView' | 'DetailView' | 'DocView';

export const ARCHIVE_ACTION_SOURCE = {
  desktop: 1,
  web: 2,
  automatic: 3,
};

export const ARCHIVE_ENTITY_TYPE = {
  noState: 0,
  invoice: 1,
  invoiceAttachment: 2,
  workflowRecord: 3,
};

export const ARCHIVE_DOCUMENT_STATE = {
  processing: 1,
  importedToErp: 2,
  manuallyCompleted: 3,
  rejectedInDesktop: 4,
  rejectedInWorkflow: 5,
  sentToStartInWorkflow: 6,
  removed: 7,
  terminatedInWorkflow: 8,
  error: 9,
  completedWithAccounting: 10,
  completedWithImportToErp: 11,
};

export const ARCHIVING_STATE = {
  disabled: 1,
  waitingForProcessing: 2,
  processing: 3,
  documentCreated: 4,
  error: 5
};

export const ARCHIVE_ACTION = {
  Create: 1,
  SetDocumentType: 2,
  SetState: 3,
  SetDocumentName: 4,
  SetDateOfCreateInErp: 5,
  SetDateOfAccountingCase: 6,
  SetDateOfTaxableSupply: 7,
  SetDueDate: 8,
  SetDateOfIssue: 9,
  SetAccountingLine: 10,
  SetAccountingAssignment: 11,
  SetVatType: 12,
  SetReverseCharge: 13,
  SetAccountingYear: 14,
  SetActivity: 15,
  SetCenter: 16,
  SetOrder: 17,
  SetDocumentNumberInErp: 18,
  SetCurrency: 19,
  SetTotalAmount: 20,
  SetCompanyIdentificationNumber: 21,
  SetPartnerName: 22,
  CreateInvoiceFromWorkflowRecord: 23,
  CreateWorkflowRecord: 24,
  CreateMainDocument: 25,
  CreateAttachment: 26,
  TimeStampMainDocument: 27,
  TimeStampAttachment: 28,
  SetScenario: 29,
  AgreementProcess: 30,
  AutomaticAgreementProcess: 31,
  MoveToBin: 32,
  Parsing: 33,
  ImportToErp: 34,
  ManuallyCompleteInDesktop: 35,
  RejectInWorkflow: 36,
  RejectInDesktop: 37,
  ReturnToStartInWorkflow: 38,
  RemoveFromBin: 39,
  TerminateInWorkflow: 40,
  AddAccountingJournalDocument: 41,
  CreateInvoice: 42,
  SetContract: 43,
  SetIssuedOrder: 44,
  CreateWorkflowInvoiceFromWorkflowRecord: 45
};

export type ArchiveDocumentType = {
  id: number,
  dateOfCreate?: Date,
  dateOfModified?: Date,
  name: string,
  senderEmail: string
  partnerName: string,
  companyIdentificationNumber: string,
  type: string,
  state: number, //ArchivDocumentStateEnum
  dateOfTimeStamping?: Date,
  dateOfAccounting?: Date,
  dateOfAccountingCase?: Date,
  dateOfTaxableSupply?: Date,
  dueDate?: Date,
  dateOfIssue?: Date,
  vatRateType: VatRateTypeEnum,
  activity: string,
  center: string,
  order: string,
  contract: string,
  issuedOrder: string,
  documentNumber: string,
  currencyCode: string,
  totalAmount?: number,
  accountingLine: string,
  accountingAssignment: string,
  reverseCharge?: boolean,
  accountingYear?: number,
  mainDocumentId?: number,
  externalType: number,
  isMainDocument: boolean,
  accountingJournal?: string,
  archivingState: number, // ArchivingStateEnum
  invalid: boolean;
};

export type ArchiveViewDocumentType = {
  attachmentCount: number
};

export type DocumentHistoryType = {
  action: number,
  actionSource: number,
  date: Date,
  userEmail: string,
};

export type ErpAccountingJournalDocumentType = {
  id: string,
  Date?: Date,
  AccountMd?: string,
  AccountD?: string,
  TotalAmount: number,
  Number?: string,
};

export type ArchiveDocumentPropertiesType = keyof ArchiveDocumentType;

export type ArchiveRelatedDocuments = { [key: number]: ArchiveDocumentType[] };

export type ArchiveFile = {
  documentId: number,
  dateOfTimeStamping: string,
  dateOfTimeStampExpiration: string,
};

export type ArchivePackageMode = 'create' | 'edit';
