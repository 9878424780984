import _ from 'lodash';
import { AnyAction } from 'redux';

import { archiveInitialState } from './initialStates';
import * as types from '../actions/actionTypes';
import { ArchiveFile } from '../common/types/archiveTypes';
import { ArchivePackageType } from '../common/types/archivePackageTypes';
import { ArchiveState } from '../common/types/reduxStateTypes';

const archive = (state: ArchiveState = archiveInitialState, action: AnyAction) => {
  switch (action.type) {
    case types.ARCHIVE_DOCUMENT_FETCH_RELATED_SUCCESS: {
      const id = action.id;
      const items = [...action.data.items];
      const related = _.cloneDeep(state.related);
      related[id] = items;

      return {
        ...state,
        related
      };
    }
    case types.ARCHIVE_FILE_FETCH_SUCCESS: {
      const archiveFile: ArchiveFile = action.data;
      return {
        ...state,
        archiveFile,
      };
    }
    case types.ARCHIVE_FETCH_PACKAGE_SUCCESS: {
      const archivePackage: ArchivePackageType = action.data;
      return {
        ...state,
        archivePackage
      };
    }
    default:
      return state;
  }
};

export default archive;
