import React, { useState } from 'react';
import { ListItemProps, DropDownListProps, DropDownListChangeEvent, DropDownList } from "@progress/kendo-react-dropdowns";
import ResponsiveDropDownList from './ResponsiveDropDown';
import classNames from 'classnames';

import './ImageDropDown.scss';
import { ThemeType } from '../../common/types/commonTypes';

export type ImageDropDownTheme = ThemeType | 'blue';

export type ImageDropDownProps = DropDownListProps & {
  onValueChange?: (value: any) => void,
  selectedItem: any,
  theme?: ImageDropDownTheme,
  mode?: ModeType,
};

export type ModeType = 'compact' | 'fullsize';

const ImageDropDown = (props: ImageDropDownProps) => {
  const { data = [], onValueChange, selectedItem, theme = 'dark', mode = 'fullsize', className = '', ...rest } = props;
  const [value, setValue] = useState(selectedItem ?? null);

  const itemRender = (
    li: React.ReactElement<HTMLLIElement, string | React.JSXElementConstructor<any>>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <div className="image-dropdown-item">
        <img src={dataItem.icon} />
        <span className="image-dropdown-item-text">{dataItem.text}</span>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (
    element: React.ReactElement<HTMLSpanElement, string | React.JSXElementConstructor<any>>,
    dataItem: any
  ) => {
    var item = dataItem;
    if (!item) {
      return <></>;
    }

    const itemText = (mode === 'compact' && item.shortText) ? item.shortText : item.text;
    const itemChildren = (
      <div className="image-dropdown-value">
        <img src={item.icon} />
        <span className="image-dropdown-value-text">{itemText}</span>
      </div>
    );
    return React.cloneElement(element, element.props, itemChildren);
  };

  const changeHandler = (event: DropDownListChangeEvent) => {
    const selectedItem = event.target.value;
    if (selectedItem) {
      setValue(selectedItem);
      if (onValueChange) {
        onValueChange(selectedItem.value);
      }
    }
  };

  const classWrapper = classNames({
    [`image-dropdown image-dropdown-${theme} image-dropdown-${mode}`]: true,
    [className]: className,
  });

  return (
    <ResponsiveDropDownList
      {...rest}
      className={classWrapper}
      data={data}
      textField="text"
      dataItemKey="value"
      value={value}
      onChange={changeHandler}
      itemRender={itemRender}
      valueRender={valueRender}
    />
  );
};

export default ImageDropDown;
