//https://redux.js.org/recipes/reducingboilerplate
import { beginAjaxCall, ajaxCallError } from '../actions/ajaxStatusActions';
import * as responseTypes from '../common/types/responseTypes';
import * as actionTypes from '../actions/actionTypes';
import { checkAccessForbidden, renewToken } from '../actions/userActions';
import { HTTP_STATUS } from '../common/types/httpStatusTypes';

const doRequest = (dispatch, action) => {
  const {
    types,
    callAPI,
    shouldCallSuccesAction = () => true,
    shouldCallOkAction = (response) => {
      let { code } = ((response || {}).data);
      if (code) {
        return code === responseTypes.OK;
      }

      return false;
    },
    payload = {},
    callSuccessAction = () => { },
    callFailureAction = () => { },
    callOkAction = () => { }
  } = action;

  const [requestType, successType, failureType] = types;

  dispatch({
    type: requestType,
    ...payload
  });

  dispatch(beginAjaxCall(requestType.substring(0, requestType.length - '_REQUEST'.length)));

  return callAPI().then(
    response => {
      let { data } = response;
      if (typeof data === 'boolean') {
        data = { result: data };
      } else if (Array.isArray(data)) {
        data = { items: [...data] };
      }

      if (shouldCallSuccesAction(response)) {
        dispatch({
          data: { ...data },
          type: successType,
          ...payload
        });

        callSuccessAction(response);

        if (shouldCallOkAction(response)) {
          callOkAction(response);
        }
      } else {
        dispatch(ajaxCallError(failureType));
        callFailureAction();
      }
    })
    .catch(error => {
      let { response } = error;
      let { status } = response || {};
      if (status) {
        switch (status) {
          case HTTP_STATUS.FORBIDDEN:
            dispatch(checkAccessForbidden());
            break;
          case HTTP_STATUS.PAYMENT_REQUIRED:
            dispatch({ type: actionTypes.APP_OPEN_WARNING_DIALOG, tKey: 'HttpStatus.PaymentRequired' });
            dispatch({
              error,
              type: failureType,
              ...payload
            });
            break;
          case HTTP_STATUS.UPGRADE_REQUIRED:
            dispatch(renewToken(action));
            break;
          default: {
            dispatch({
              error,
              type: failureType,
              ...payload
            });

            callFailureAction();
          }
        }
      }

      dispatch(ajaxCallError(failureType));
    });
};

export const callAPIMiddleware = ({ dispatch, getState }) => {
  return next => action => {
    const {
      types,
      callAPI,
      shouldCallAPI = () => true,
    } = action;

    if (!types) {
      // Normal action: pass it on
      return next(action);
    }

    if (!Array.isArray(types) ||
      types.length !== 3 ||
      !types.every(type => typeof type === 'string')) {
      throw new Error('Expected an array of three string types.');
    }

    if (typeof callAPI !== 'function') {
      throw new Error('Expected callAPI to be a function.');
    }

    if (!shouldCallAPI(getState())) {
      return;
    }

    return doRequest(dispatch, action);
  };
};
