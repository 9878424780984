import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as paymentActions from '../../../actions/paymentActions';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Translation from '../../common/Translation';
import { COMGATE_PAYMENT_STATE_TEXT, COMGATE_PAYMENT_STATUS } from '../../../common/types/paymentTypes';
import SubmitButton from '../../common/SubmitButton';
import AnonymousZone from '../AnonymousZone';
import PaymentCanceled from './PaymentCanceled';
import PaymentPending from './PaymentPending';
import { navigateToCompanyVerification } from '../../../actions/navigationActions';
import queryString from 'query-string';

class PaymentStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeft: 30
    };
  }

  componentDidMount() {
    this.props.actions.getRegistrationPaymentInfo(this.props.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.serverStatus !== this.props.serverStatus) {
      if (this.props.serverStatus === COMGATE_PAYMENT_STATUS.Paid) {
        this.goToVerification();
      } else {
        this.setState({ serverStatus: prevProps.serverStatus });
      }
    }
  }

  goToVerification = () => {
    this.props.goToCompanyVerification(this.props.refId);
  };

  renderTextByStatus = (status) => {
    let { pathname, search } = this.props.location;
    status = status.toLowerCase();
    if (status === COMGATE_PAYMENT_STATE_TEXT.Success) {
      return (
        <div className="box-wrapper payment">
          <div className="box-header-group">
            <div className="box-header"><Translation tKey="PaidSuccess" /></div>
            <div><Translation tKey="PaidSuccessMessage" /></div>
          </div>
          <div className="box-actions">
            <div className="actions">
              <SubmitButton onClick={() => this.goToVerification()} className="k-primary button">
                <Translation tKey="Continue" />
              </SubmitButton>
            </div>
          </div>
        </div>
      );
    } else if (status === COMGATE_PAYMENT_STATE_TEXT.Pending) {
      return (<PaymentPending token={this.props.token} navigateTo={`${pathname}${search}`} />);
    } else if (status === COMGATE_PAYMENT_STATE_TEXT.Canceled) {
      return (<PaymentCanceled token={this.props.token} />);
    }

    return (<div>&nbsp;</div>);
  };

  render() {
    let { status } = this.props;

    return (
      <AnonymousZone>
        {this.renderTextByStatus(status)}
      </AnonymousZone>
    );
  }
}

PaymentStatus.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  id: PropTypes.string,
  refId: PropTypes.string,
  status: PropTypes.string,
  t: PropTypes.func.isRequired,
  serverStatus: PropTypes.number,
  token: PropTypes.string,
  location: PropTypes.object,
  goToCompanyVerification: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  let serverStatus = -1;
  let token = "";
  let { payment } = state;
  if (payment) {
    serverStatus = payment.status || -1;
    token = payment.token || "";
  }

  const queryStringParams = queryString.parse(ownProps.location.search);

  return {
    id: queryStringParams.id,
    refId: queryStringParams.refId,
    status: ownProps.match.params.status,
    loading: state.ajaxStatus.totalTriggers > 0,
    serverStatus,
    token
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(paymentActions, dispatch),
    goToCompanyVerification: bindActionCreators(navigateToCompanyVerification, dispatch)
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(PaymentStatus);
