import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Checkbox as KendoCheckbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import KendoHOC from './utils/KendoHOC';
import { useState } from 'react';

export type CheckboxProps = {
  className?: string | any,
  label: any,
  disabled?: boolean,
  onChange?: (event: CheckboxChangeEvent) => void,
};

const Checkbox = (props: CheckboxProps) => {
  const { className, label, ...rest } = props;
  const [checkboxId] = useState(uuidv4());
  const wrapperClass = classNames({
    checkbox: true,
    [className]: className
  });

  return (
    <KendoCheckbox id={checkboxId} className={wrapperClass} {...rest}>
      <label
        htmlFor={checkboxId}
        className={"k-checkbox-label"}
        style={{
          display: "inline-block",
        }}
      >
        {label}
      </label>
    </KendoCheckbox>
  );
};

export const CheckboxField = (props: CheckboxProps) => KendoHOC(Checkbox, props);

export default Checkbox;
