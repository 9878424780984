import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';

import { getUserAgendas, setAgenda } from '../../actions/userActions';
import { fetchMyAccount } from '../../actions/myAccountActions';
import Translation from '../common/Translation';
import SubmitButton from '../common/SubmitButton';
import AnonymousZone from './AnonymousZone';
import { sortByLocalStrings } from '../generalFunctions';
import { RootState } from '../../common/types/reduxStateTypes';
import { AgendaType, AgendasType } from '../common/Agenda';
import AgendaWithNotificationsDropDownList from '../common/AgendaWithNotificationsDropDownList';

interface SelectAgendaProps {
  loading: boolean,
  agendas: Array<any>,
  selectedAgendaId: number | null;
  fetchMyAccount: () => any,
  getUserAgendas: (callback: any) => any,
  setAgenda: (agendaId: number, reload?: boolean) => any,
  children: React.ReactNode
}

const SelectAgenda: React.ComponentType<SelectAgendaProps> = ({ fetchMyAccount, selectedAgendaId, getUserAgendas, setAgenda, loading, agendas }) => {
  const [currentAgenda, setCurrentAgenda] = useState(null);
  const initialValue: AgendaType = { text: '', value: 0, totalDocumentsForApproval: null };
  const [value, setValue] = useState<AgendaType>(initialValue);
  useEffect(() => {
    fetchMyAccount();
    getUserAgendas(fetchDataCallback);
  }, []);

  const fetchDataCallback = (data: AgendasType) => {
    let { selectedValue, items } = data;

    if (selectedValue && items) {
      if (value.value !== selectedValue) {
        const newValue = items.find((i) => i.value === selectedValue);
        if (newValue) {
          setValue(newValue);
        }
      }
    }
  };

  return (<>
    <AnonymousZone>
      <div className="box-wrapper">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="SelectAgenda.Title" /></div>
          <div><Translation tKey="SelectAgenda.Intro" /></div>
        </div>
        {agendas &&
          <div className="row-margin">
            <AgendaWithNotificationsDropDownList
              className="agenda-combo-box general-clearable-combobox h-10"
              size="large"
              data={agendas}
              textField="text"
              dataItemKey="value"
              value={value}
              onChange={({ value }) => {
                setValue(value as AgendaType);
              }}
            />
          </div>
        }
        <div className="box-actions">
          <div className="actions">
            <SubmitButton onClick={() => {
              if (value) {
                setAgenda(value.value);
              }
            }} loading={loading} testId="select-agenda-button">
              <Translation tKey="SelectAgenda.Button" />
            </SubmitButton>
          </div>
        </div>
      </div>
    </AnonymousZone>
  </>);
};

const mapStateToProps = (state: RootState) => {
  let { agendas } = state.user;
  let items = [];
  let selectedAgendaId = null;

  if (agendas) {
    items = sortByLocalStrings(agendas.items, 'text');
    selectedAgendaId = agendas.selectedValue;
  }

  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    agendas: items,
    selectedAgendaId
  };
};

const dispatchToProps = {
  fetchMyAccount,
  setAgenda,
  getUserAgendas
};

export default connect(mapStateToProps, dispatchToProps)(SelectAgenda);
