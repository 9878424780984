import * as types from './actionTypes';
import uuidV4 from 'uuid/v4';

//style: "info", "success", "warning", "error"
export const alertAdd = (tKey, style, transform = { topic: '' }, sticky = false) => {
  return ({
    type: types.ALERTS_ADD,
    tKey,
    style,
    transform,
    id: uuidV4(),
    sticky
  });
};

export const alertRemove = (id) => ({
  type: types.ALERTS_REMOVE,
  id
});

export const alertClear = () => ({
  type: types.ALERTS_CLEAR,
});
