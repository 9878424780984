import * as types from '../actions/actionTypes';
import { tableInitialState } from './initialStates';

const row = (state, index, action) => {
  switch (action.type) {
    case types.TABLE_SELECT_ROWS: {
      if (index >= action.min && index <= action.max) {
        return {
          ...state,
          selected: action.selected
        };
      } else {
        return { ...state };
      }
    }
    case types.TABLE_DESELECT_ALL_ROWS: {
      return {
        ...state,
        selected: false
      };
    }
    case types.TABLE_SELECT_ROW: {
      if (index === action.index) {
        return {
          ...state,
          selected: action.selected
        };
      } else {
        return { ...state };
      }
    }
    case types.TABLE_EXPAND_CHANGE: {
      if (index === action.index) {
        return {
          ...state,
          expanded: action.expanded
        };
      } else {
        return { ...state };
      }
    }
    case types.TABLE_SELECT_ALL_ROWS: {
      return {
        ...state,
        selected: action.checked
      };
    }
    case types.TABLE_EXPAND_ALL: {
      return {
        ...state,
        expanded: true
      };
    }
    case types.TABLE_COLLAPSE_ALL: {
      return {
        ...state,
        expanded: false
      };
    }
    default:
      return state;
  }
};

const updateRow = (data, action) => {
  let ids = data.map(i => { return i.id; });
  let indexData = ids.indexOf(action.data.id);
  if (indexData !== -1) {
    return [...data.slice(0, indexData), { ...data[indexData], ...action.data }, ...data.slice(indexData + 1)];
  } else if (action.id) {
    let currentIndexData = ids.indexOf(action.id);
    if (currentIndexData !== -1) {
      return [...data.slice(0, currentIndexData), ...data.slice(currentIndexData + 1)];
    }
  }

  return -1;
};

const updateRowPartially = (data, action) => {
  let { syncProperty, updatedProperty } = action;

  let indexData = data.findIndex(item => item.id === action.data[syncProperty]);
  if (indexData !== -1) {
    let dataItem = { ...data[indexData] };
    if (dataItem) {
      if (Array.isArray(dataItem[updatedProperty])) {
        let propertyDataItem = [...dataItem[updatedProperty]];
        let indexPropertyDataItem = propertyDataItem.findIndex(item => item.id === action.data.id);

        if (indexPropertyDataItem !== -1) {
          propertyDataItem[indexPropertyDataItem] = { ...action.data };

          dataItem[updatedProperty] = propertyDataItem;
        }
      } else {
        dataItem[updatedProperty] = { ...action.data };
      }

      return [...data.slice(0, indexData), { ...dataItem }, ...data.slice(indexData + 1)];
    }
  }

  return -1;
};

const reloadRowProperty = (data, action) => {
  let { id, property } = action;

  let indexData = data.findIndex(item => item.id === id);
  if (indexData !== -1) {
    let dataItem = { ...data[indexData] };
    if (dataItem) {
      if (Array.isArray(dataItem[property])) {
        dataItem[property] = action.data.items;
      }
    }

    return [...data.slice(0, indexData), { ...dataItem }, ...data.slice(indexData + 1)];
  }

  return -1;
};

const table = (state = tableInitialState, action) => {
  switch (action.type) {
    case types.TABLE_FILTER_CHANGE: {
      let { filter: { filters } } = action;
      if (filters) {
        return {
          ...state,
          filter: filters
        };
      }

      return state;
    }
    case types.TABLE_FILTER_CLEAR:
      return {
        ...state,
        filter: []
      };
    case types.TABLE_FETCH_DATA_SUCCESS: {
      if (!action.data) {
        return {
          ...state
        };
      }

      let { data } = action.data || {};

      data.forEach(o => {
        o.selected = false;
      });
      return {
        ...state,
        total: action.data.recordsFiltered,
        data: data.slice(state.skip - action.skip, (state.skip - action.skip) + state.take),
        cache: {
          ...state.cache,
          data: data,
          skip: action.skip,
          take: action.take
        }
      };
    }
    case types.TABLE_PAGE_CHANGE:
      return {
        ...state,
        skip: action.skip,
        take: action.take
      };
    case types.TABLE_SORT_CHANGE:
      return {
        ...state,
        sort: action.sort
      };
    case types.TABLE_RESET:
      return {
        ...tableInitialState,
        url: action.url,
        actionUrl: action.actionUrl,
        editUrl: action.editUrl,
        columns: action.columns,
        defaultSort: action.defaultSort,
        path: action.path,
        pathQueryString: action.queryString,
        filter: action.filter,
        notificationPath: action.notificationPath
      };
    case types.TABLE_REFESH:
      return {
        ...state,
        url: action.url,
        actionUrl: action.actionUrl,
        editUrl: action.editUrl,
        columns: action.columns,
        defaultSort: action.defaultSort,
        path: action.path,
        pathQueryString: action.queryString,
        filter: action.filter,
        notificationPath: action.notificationPath
      };
    case types.TABLE_CLEAR:
      return {
        ...tableInitialState
      };
    case types.TABLE_CACHE_DATA:
      return {
        ...state,
        data: state.cache.data.slice(state.skip - state.cache.skip, (state.skip - state.cache.skip) + state.take)
      };
    case types.TABLE_SELECT_ROW: {
      let result = {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };

      return {
        ...result,
        selected: result.data.filter(i => { return i.selected === true; }).length
      };
    }
    case types.TABLE_DESELECT_ALL_ROWS: {
      let result = {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };

      return {
        ...result,
        selected: result.data.filter(i => { return i.selected === true; }).length
      };
    }
    case types.TABLE_SELECT_ROWS: {
      let result = {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };

      return {
        ...result,
        selected: result.data.filter(i => { return i.selected === true; }).length
      };
    }
    case types.TABLE_SELECT_ALL_ROWS: {
      let result = {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };

      return {
        ...result,
        selected: result.data.filter(i => { return i.selected === true; }).length
      };
    }
    case types.TABLE_REMOVE_ROWS: {
      let result = {
        ...state,
        data: state.data.filter(i => { return !action.ids.includes(i.id); }),
        total: state.total - action.ids.length
      };

      return {
        ...result,
        selected: result.data.filter(i => { return i.selected === true; }).length
      };
    }
    case types.TABLE_UPDATE_ROW_SUCCESS: {
      if (!action.reload) {
        let { cache } = state;

        let result = {
          ...state
        };

        let dataUpdateResult = updateRow(state.data, action);
        if (dataUpdateResult !== -1) {
          result.data = dataUpdateResult;
        }

        let cacheUpdateResult = updateRow(cache.data, action);
        if (cacheUpdateResult !== -1) {
          result.cache = {
            ...state.cache,
            data: cacheUpdateResult
          };
        }

        return { ...result };
      }

      return {
        ...state
      };
    }
    case types.TABLE_UPDATE_ROW_PARTIALLY_SUCCESS: {
      let { syncProperty, updatedProperty } = action;
      let result = {
        ...state
      };

      if (syncProperty && updatedProperty) {
        let dataUpdateResult = updateRowPartially(state.data, action);
        if (dataUpdateResult !== -1) {
          result.data = dataUpdateResult;
        }

        let cacheUpdateResult = updateRowPartially(state.cache.data, action);
        if (cacheUpdateResult !== -1) {
          result.cache = {
            ...state.cache,
            data: cacheUpdateResult
          };
        }
      }

      return result;
    }
    case types.TABLE_EXPAND_CHANGE: {
      return {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };
    }
    case types.TABLE_SET_TOTAL: {
      return {
        ...state,
        total: action.total
      };
    }
    case types.TABLE_COLLAPSE_ALL: {
      return {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };
    }
    case types.TABLE_EXPAND_ALL: {
      return {
        ...state,
        data: state.data.map((i, index) => row(i, index, action))
      };
    }
    case types.TABLE_MUTATE: {
      return {
        ...state,
        data: action.data
      };
    }
    case types.TABLE_MUTATE_ROW: {
      let result = {
        ...state
      };

      let { cache } = state;

      let dataUpdateResult = updateRow(state.data, action);
      if (dataUpdateResult !== -1) {
        result.data = dataUpdateResult;
      }

      let cacheUpdateResult = updateRow(cache.data, action);
      if (cacheUpdateResult !== -1) {
        result.cache = {
          ...state.cache,
          data: cacheUpdateResult
        };
      }

      return { ...result };
    }
    case types.TABLE_UPDATE_COLUMNS: {
      return {
        ...state,
        columns: action.columns,
      };
    }
    case types.TABLE_UPDATE_URL: {
      return {
        ...state,
        url: action.url,
      };
    }
    case types.TABLE_RELOAD_ROW_PROPERTY_SUCCESS: {
      let result = {
        ...state
      };

      let dataUpdateResult = reloadRowProperty(state.data, action);
      if (dataUpdateResult !== -1) {
        result.data = dataUpdateResult;
      }

      let cacheUpdateResult = reloadRowProperty(state.cache.data, action);
      if (cacheUpdateResult !== -1) {
        result.cache = {
          ...state.cache,
          data: cacheUpdateResult
        };
      }

      return { ...result };
    }
    default:
      return state;
  }
};

export default table;
