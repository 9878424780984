import ReactDOM from 'react-dom';
import { ConnectedProps, connect } from "react-redux";

import { filterTriggers } from '../generalFunctions';
import { RootState } from '../../common/types/reduxStateTypes';

export type LoadingPanelOwnProps = {
  actionType?: string,
};

const LoadingPanel = ({ actionType = 'FETCH', triggers }: LoadingPanelProps) => {
  const getLoadingPanel = () => {
    return (
      <div className="k-loading-mask">
        <div className="k-loading-image" />
        <div className="k-loading-color" />
      </div>
    );
  };

  // exclude 'notification' so that notifications fetching don't affect working of the whole application
  let fetchTriggers = filterTriggers(triggers, { include: [actionType], exclude: ['notification'] });
  const open = fetchTriggers.some((t: { total: number }) => t.total > 0);

  if (open) {
    const loadingPanel = getLoadingPanel();
    const content = document && document.querySelector('.content');

    return content ? ReactDOM.createPortal(loadingPanel, content) : loadingPanel;
  }

  return null;
};


const mapStateToProps = (state: RootState) => ({
  triggers: state.ajaxStatus.triggers
});

const connector = connect(mapStateToProps);
type LoadingPanelProps = ConnectedProps<typeof connector> & LoadingPanelOwnProps;

export default connector(LoadingPanel);
