import * as types from './actionTypes';

export const toggleAccountMenu = () => (dispatch) => {
  return dispatch({ type: types.TOGGLE_ACCOUNT_MENU });
};

export const closeAccountMenu = () => (dispatch) => {
  return dispatch({ type: types.CLOSE_ACCOUNT_MENU });
};

export const toggleAccountMenuSelectItem = () => (dispatch) => {
  return dispatch({ type: types.TOGGLE_ACCOUNT_MENU_SELECT_ITEM });
};
