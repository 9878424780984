import { DropDownList, DropDownListProps, ListItemProps } from '@progress/kendo-react-dropdowns';
import React from 'react';
import { AgendaType } from './Agenda';

export function withNotificationsDropDown<T extends DropDownList>(WrappedComponent: React.ComponentType<DropDownList>) {
  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const { dataItem } = itemProps;
    const itemChildren = (
      <div className="select-agenda-list-item select-agenda-item-render">
        <div className="select-agenda-list-item-left">
          {li.props.children}
        </div>
        {dataItem.totalDocumentsForApproval && <div className="select-agenda-list-item-right">
          <div className="handshake-component">
            <span className="icon icon-handshake">&nbsp;</span><span className="text">{dataItem.totalDocumentsForApproval}</span>
          </div>
        </div>}
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (
    element: React.ReactElement<HTMLSpanElement>,
    value: AgendaType
  ) => {
    const { text, totalDocumentsForApproval } = value;
    const children = (
      <div className="select-agenda-list-item select-agenda-value-render">
        <div className="select-agenda-list-item-left">
          {text}
        </div>
        {totalDocumentsForApproval && <div className="select-agenda-list-item-right">
          <div className="handshake-component">
            <span className="icon icon-handshake">&nbsp;</span><span className="text">{totalDocumentsForApproval}</span>
          </div>
        </div>}
      </div>
    );

    return React.cloneElement(element, { ...element.props }, children);
  };

  return (props: DropDownListProps) => {
    return (<WrappedComponent
      valueRender={valueRender}
      itemRender={itemRender}
      {...(props as T)} />);
  };
}
