import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextBox from './TextBox';

class StrengthMeterTextBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meterClass: 'progress',
      meterStepClass: 'danger',
      style: {
        width: '0'
      },
      score: 0
    };
  }

  evaluate = (password) => {
    let digitRegEx = /\d/;
    let specialCharacterRegEx = /[!@$#%^&*)(+=.,_*[\]}{;:"'\\|?></]{1,}/;
    let upperLetterRegEx = /[A-Z]/;
    let lowerLetterRegEx = /[a-z]/;
    let score = 0;

    if (password.length < 5) {
      return 0;
    }

    if (upperLetterRegEx.test(password) && lowerLetterRegEx.test(password)) {
      score += 1;
    }

    if (digitRegEx.test(password)) {
      score += 1;
    }

    if (password.length > 5) {
      score += 1;
    }

    if (password.length > 5 &&
      upperLetterRegEx.test(password) &&
      lowerLetterRegEx.test(password) &&
      digitRegEx.test(password) &&
      specialCharacterRegEx.test(password)) {
      score += 1;
    }

    return score;
  };

  onChange = (event) => {
    this.props.onChange(event);
    let width = 0;
    let stepClass = 'danger';
    let score = this.evaluate(event.target.value) || 0;

    if (event.target.value.length > 0) {
      switch (score) {
        case 0:
          width = 25;
          break;
        case 1:
          width = 40;
          stepClass = 'warning';
          break;
        case 2:
          width = 50;
          stepClass = 'warning';
          break;
        case 3:
          width = 75;
          stepClass = 'acceptable';
          break;
        case 4:
          width = 100;
          stepClass = 'success';
          break;
        default:
          width = 0;
      }
    }

    this.setState({ meterStepClass: stepClass, style: { width: `${width}%` }, score: score });
  };

  getScore = (value) => value ? this.evaluate(value) : this.state.score;

  reset = () => this.setState({ style: { width: 0 }, score: 0 });

  render() {
    let { meterClass, meterStepClass, style } = this.state;
    let wrappedMeterStepClass = `progress-bar progress-bar-${meterStepClass}`;
    return (
      <TextBox {...this.props} onChange={this.onChange} autoComplete="new-password">
        <div className={meterClass}>
          <div className={wrappedMeterStepClass} style={style}>&nbsp;</div>
        </div>
      </TextBox>
    );
  }
}

StrengthMeterTextBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default StrengthMeterTextBox;
