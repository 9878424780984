import { AppViewModeType } from "./appTypes";

export const SETTINGS_RELATION_TYPE = {
  user: 1,
  agenda: 2,
  userAgenda: 3
};

export const SETTINGS_TYPE = {
  desktop: 1,
  web: 2,
  mobile: 3
};

export const SETTING_CATEGORY = {
  NotSet: 0,
  WorkflowInputQueueFilterSettings: 50,
  NotificationsSettings: 51,
  WebApplicationSettings: 52,
  TableFiltersSettings: 53
} as const;

export type SettingsRelationType = keyof typeof SETTINGS_RELATION_TYPE;

export type SettingCategory = typeof SETTING_CATEGORY[keyof typeof SETTING_CATEGORY];

export interface UserAgendaSettingsModelBase {
  category: SettingCategory,
  guidOfModified: string,
  nameOfModified: string,
  data: string,
  type: number,
  dataStructureVersion: number,
}

export interface UserAgendaSettingsModel extends UserAgendaSettingsModelBase {
  id: number,
}

export interface AgendaSettingsModel extends UserAgendaSettingsModelBase {
  id: number,
}

export interface UserAgendaSettings {
  id: number,
  data: object,
}

export interface UserAgendaWebAppSettings extends UserAgendaSettings {
  data: WebAppSettings,
}

export type WebAppSettings = {
  userViewModes: { [pageName: string]: AppViewModeType },
  columnNames: {
    archive: string[]
  },
  lastSeenNotifyDates: { [pageName: string]: string | Date },
  workflowFilter: {
    filterId: number,
    isActive: boolean,
  },
};

export interface UserViewModes {
  [key: string]: AppViewModeType
}

export interface LastSeenNotifyDates {
  [key: string]: Date
}
