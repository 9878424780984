import { compose } from 'redux';
import { configureStore as reduxConfigureStore } from '@reduxjs/toolkit';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import { callAPIMiddleware } from '../middlewares/apiMiddleware';
import navigationMiddleware from '../middlewares/navigationMiddleware';
import { routerMiddleware } from 'connected-react-router';
import { debounceMiddleware } from '../middlewares/debounceMiddleware';
import { signalRMiddleware } from '../middlewares/signalRMiddleware';

const globalStore = store => next => action => {
  if (!window.store) { window.store = store; }

  next(action);
};

function configureStoreProd(initialState, history) {
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    globalStore,
    debounceMiddleware,
    callAPIMiddleware,
    signalRMiddleware,
    thunk,
    navigationMiddleware(history),
    routerMiddleware(history)
  ];

  return reduxConfigureStore({
    reducer: createRootReducer(history),
    preloadedState: initialState,
    middleware: middlewares,
  });
}

function configureStoreDev(initialState, history) {
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    globalStore,
    debounceMiddleware,
    callAPIMiddleware,
    signalRMiddleware,
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
    navigationMiddleware(history),
    routerMiddleware(history)
  ];

  const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && // add support for Redux dev tools
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose; // add support for tracing in Redux dev tools

  const store = reduxConfigureStore({
    reducer: createRootReducer(history),
    preloadedState: initialState,
    middleware: middlewares,
    devTools,
  });

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const createNextRootReducer = require('../reducers').default; // eslint-disable-line global-require
      store.replaceReducer(createNextRootReducer(history));
    });
  }

  return store;
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
