import _ from 'lodash';

const debouncers = {};

function getDebouncer(key, wait, func) {
  let debouncer;
  if (debouncers.hasOwnProperty(key)) {
    debouncer = debouncers[key];
  } else {
    debouncer = _.debounce(func, wait);
    debouncers[key] = debouncer;
  }
  return debouncer;
}

export const debounceMiddleware = () => next => action => {
  const {
    types,
  } = action;

  if (!types) {
    // Normal action: pass it on
    return next(action);
  }

  const [requestType] = types;
  const debouncer = getDebouncer(requestType, 100, next);

  return debouncer(action);
};
