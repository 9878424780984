import React from 'react';
import classnames from 'classnames';

interface TextAreaProps {
  input: {
    name: string,
    value: any
  },
  className: string,
  onChange: () => void,
  meta: {
    error: string
  }
}

const TextArea = (props: TextAreaProps) => {
  const { className, meta: { error } } = props;

  let wrapperClass = classnames({
    'k-textarea': true,
    [className]: className,
    'notification-input-error': error
  });

  return (
    <>
      <textarea className={wrapperClass} {...props.input} />
      {error && <div className={`notification-error`}>{error}</div>}
    </>
  );
};

export default TextArea;
