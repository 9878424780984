import * as types from '../actions/actionTypes';
import { fetchNotifications } from '../actions/notificationActions';
import { connection, isDisconnected } from '../signalR';
import {
  WORKFLOW_SIMPLE,
  USER_SIMPLE,
  PARTNER_SIMPLE,
  USER_PERMISSION_SIMPLE,
  INVOICE_SIMPLE,
  BILLING_SIMPLE
} from '../common/types/signalRTypes';
import { registerEvents as workflowRegisterEvents, doActions as workflowDoActions } from '../signalR/workflowSignalR';
import { registerEvents as userRegisterEvents, doActions as userDoActions } from '../signalR/userSignalR';


const registerSimpleObjectEvents = (entity, store) => {
  Object.keys(entity).forEach((key) => {
    connection.on(entity[key], () => {
      console.log("SignalR", connection);
      let { notification } = store.getState();
      const { lastFetched } = notification;
      store.dispatch(fetchNotifications(lastFetched));
    });
  });
};

const registerEvents = (store) => {
  workflowRegisterEvents(store, connection);
  userRegisterEvents(store, connection);

  registerSimpleObjectEvents(WORKFLOW_SIMPLE, store);
  registerSimpleObjectEvents(USER_SIMPLE, store);
  registerSimpleObjectEvents(PARTNER_SIMPLE, store);
  registerSimpleObjectEvents(USER_PERMISSION_SIMPLE, store);
  registerSimpleObjectEvents(INVOICE_SIMPLE, store);
  registerSimpleObjectEvents(BILLING_SIMPLE, store);
};

const doActions = (action) => {
  workflowDoActions(action, connection);
  userDoActions(action, connection);
};

const openConnection = async () => {
  try {
    await connection.start();
    console.log("SignalR Connected.");
  } catch (err) {
    setTimeout(openConnection, 5000);
  }
};

const closeConnection = () => {
  return connection.stop();
};

export const signalRMiddleware = store => {
  registerEvents(store);

  return next => action => {
    doActions(action);

    if (action.type === types.USER_RENEW_TOKEN_SUCCESS) {
      if (connection.connectionStarted) {
        closeConnection()
          .any(() => {
            openConnection(store);
          });
      } else if (connection.connectionState === "Disconnected") {
        openConnection(store);
      }
    }

    if (action.type === types.USER_SIGN_IN_SUCCESS) {
      if (!connection.connectionStarted) {
        openConnection(store);
      }
    }

    if (action.type === types.USER_SIGN_OUT) {
      if (connection.connectionStarted) {
        closeConnection();
      }
    }

    if (isDisconnected()) {
      let { user: { isAuth } = { isAuth: false } } = store.getState();
      if (isAuth) {
        openConnection(store);
      }
    }

    return next(action);
  };
};
