export enum SCREEN_RESOLUTION_TYPES {
  // < 768
  XS = 'EXTRRASMALL',
  // >= 768 < 1023
  S = 'SMALL',
  // >= 1024 < 1199
  M = 'MEDIUM',
  // >= 1200 < 1365
  L = 'LARGE',
  // >= 1365
  XL = 'EXTRALARGE'
}

export const SCREEN_WIDTHS = {
  XS: 768,
  S: 1024,
  M: 1200,
  L: 1365,
};
