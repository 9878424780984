import { LangCodesType } from "./commonTypes";

export type ArchivePackageRecipientBaseType = {
  name: string,
  email: string,
  phone: string,
  langCode: LangCodesType,
};

export type ArchivePackageRecipientMutationType = ArchivePackageRecipientBaseType & {
  packageId: number,
};

export type ArchivePackageRecipientType = ArchivePackageRecipientMutationType & {
  id: number,
};

export type ArchivePackageMutationType = {
  name: string,
  isIncludeAttachments: boolean,
  dateOfExpire: Date,
  conditions: string,
  recipients: ArchivePackageRecipientBaseType[],
  conditionText: string,
}

export type ArchivePackageType = Omit<ArchivePackageMutationType, 'conditionText'> & {
  id?: number,
  state: number,
  info?: string | null,
}

export enum ArchivePackageStateEnum {
  NotSet = 0,
  WaitForProcessing = 1,
  Preparing = 2,
  Ready = 3,
  Expired = 4,
  Error = 5,
  Canceled = 6,
}

export enum ArchivePackageDownloadStateEnum {
  NotSet = 0,
  Wait = 1,
  Downloaded = 2
}
