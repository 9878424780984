import { connect, ConnectedProps } from "react-redux";
import { setLang } from "../../actions/appActions";
import { ImageDropDownTheme, ModeType } from "./ImageDropDown";
import LanguageSelector from "./LanguageSelector";

type LanguageSwitchOwnProps = {
  theme?: ImageDropDownTheme,
  mode?: ModeType,
  className?: string,
}

const LanguageSwitch = ({ theme = "light", mode, className, setLang }: LanguageSwitchProps) => (
  <LanguageSelector
    onChange={setLang}
    theme={theme}
    mode={mode}
    className={className}
  />
);

const mapDispatchToProps = {
  setLang
};

const connector = connect(null, mapDispatchToProps);
type LanguageSwitchProps = LanguageSwitchOwnProps & ConnectedProps<typeof connector>;

export default connector(LanguageSwitch);
