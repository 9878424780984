import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';

class Captcha extends Component {
  onChange = (value) => {
    this.props.onChange({ target: { value: value, name: this.props.name } });
  };

  reset = () => {
    this.captcha.reset();
  };

  render() {
    return (
      <ReCAPTCHA
        ref={(el) => { this.captcha = el; }}
        sitekey="6Le1QhQTAAAAAFn-fwINj3nBZde3TSdOu_VyTj6N"
        onChange={this.onChange}
        size={isMobileOnly ? "compact" : "normal"}
        hl={this.props.lang}
      />
    );
  }
}

Captcha.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  lang: PropTypes.string,
};

const mapStateToProps = (state) => ({
  resolution: state.app.resolution,
  lang: state.app.lang,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Captcha);
