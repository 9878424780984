import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { loadState, savePartialState } from '../components/common/LocalStorage';
import { alertAdd, alertClear } from './alertActions';
import * as types from './actionTypes';
import { ajaxCallError } from './ajaxStatusActions';
import * as responseTypes from '../common/types/responseTypes';
import * as alertTypes from './alertTypes';
import { navigateToRoot, navigateToSignUpCompany, navigateTo, navigateToSignIn } from './navigationActions';
import { API_PATHS, NAVIGATION_PATHS } from '../common/data/routes';
import { fetchMyAccount } from './myAccountActions';

export const signInCallback = (response, urlPath = undefined) => (dispatch) => {
  let { data } = response;
  if (data && data.isAuthorized) {
    // Get data
    let { data } = response;
    // Set user logged in
    dispatch({
      type: types.USER_UPDATE_TOKEN_EXPIRATION,
      tokenExpireIn: data.expirationIn,
      permissions: data.permissions,
      email: data.email,
      userId: data.userId
    });
    // Save to local storage
    savePartialState({ user: { isAuth: true, tokenExpireIn: data.expirationIn } });
    // Set local storage change listener
    if (!window.onstorage) {
      window.onstorage = (e) => {
        if (e.key === 'state') {
          const oldValue = JSON.parse(e.oldValue);
          const newValue = JSON.parse(e.newValue);
          if (oldValue.user.tokenExpireIn !== newValue.user.tokenExpireIn) {
            window.onstorage = null;
            dispatch({
              type: types.APP_OPEN_WARNING_DIALOG,
              tKeys: ['WarningDialog.AvoidDoubleLoginMessage.Text1', 'WarningDialog.AvoidDoubleLoginMessage.Text2'],
              signInAfterClosing: true
            });
          }
        }
      };
    }
    dispatch(fetchMyAccount);
    // Navigate to homepage
    if (urlPath) {
      dispatch(navigateTo(urlPath));
    }
  } else {
    dispatch(alertAdd('UserDoesntExist', alertTypes.ERROR));
    dispatch({ type: types.USER_SIGN_IN_FAILURE });
    dispatch(ajaxCallError(types.USER_UPDATE_TOKEN_EXPIRATION));
  }
};

export const signOut = () => (dispatch) => {
  // Clear local storage
  savePartialState({ user: { isAuth: false, token: '', tokenExpireIn: 0 } });
  // Clear local storage change listener
  window.onstorage = null;

  // Clear token from browser
  dispatch({
    types: [types.APP_CLEAR_REQUEST, types.APP_CLEAR_SUCCESS, types.APP_CLEAR_FAILURE],
    callAPI: () => axios.get(API_PATHS.AUTH_CLEAR),
  });

  // Clearing cookies
  dispatch({
    type: types.USER_SIGN_OUT,
  });

  dispatch({
    type: types.APP_RESET,
  });

  // Navigate to signIn page
  dispatch(navigateToSignIn());
};

export const renewToken = (callbackSuccessAction = undefined) => (dispatch) => {
  dispatch({
    types: [types.USER_RENEW_TOKEN_REQUEST, types.USER_RENEW_TOKEN_SUCCESS, types.USER_RENEW_TOKEN_FAILURE],
    callAPI: () => axios.get(API_PATHS.AUTH_RENEW_TOKEN),
    shouldCallSuccesAction: (response) => { let { data } = response; return data !== undefined; },
    callSuccessAction: (response) => {
      dispatch(alertClear());
      dispatch(signInCallback(response));
      if (callbackSuccessAction) {
        dispatch(callbackSuccessAction);
      }
    },
    callFailureAction: () => { dispatch(signOut()); }
  });
};

export const verifyAccess = (token) => (dispatch) => {
  if (!token) { dispatch(signOut()); }

  let { exp } = jwtDecode(token);

  if (!exp) { dispatch(signOut()); }

  let now = new Date();
  let expireIn = new Date(exp * 1000);

  if (now > expireIn) {
    dispatch(signOut());
  } else {
    // Mapping token to storage and set cookie
    savePartialState({ user: { isAuth: true, tokenExpireIn: expireIn } });
    document.cookie = `Token=${token}`;

    // Renew token - checking validity
    dispatch(renewToken());
  }
};

export const setNewPassword = (password, token) => (dispatch) => {
  document.cookie = `Token=${token}`;

  dispatch({
    types: [types.USER_PASSWORD_SET_REQUEST, types.USER_PASSWORD_SET_SUCCESS, types.USER_PASSWORD_SET_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_NEW_PASSWORD, { password }),
    callSuccessAction: (response) => {
      let { accessToken } = response.data;
      if (accessToken) {
        dispatch(verifyAccess(accessToken));
        dispatch(navigateToRoot());
      }
    }
  });
};

export const resetPassword = (payload) => (dispatch) => {
  dispatch({
    types: [types.USER_PASSWORD_RESET_REQUEST, types.USER_PASSWORD_RESET_SUCCESS, types.USER_PASSWORD_RESET_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_FORGOTTEN_PASSWORD, payload),
    callSuccessAction: (response) => {
      let { data } = response;
      if (!data || !data.isSent) {
        dispatch(alertAdd('UserDoesntExist', alertTypes.ERROR));
      }
    }
  });
};

export const verifyEmail = (token) => (dispatch) => {
  dispatch({
    types: [types.USER_VERIFY_EMAIL_REQUEST, types.USER_VERIFY_EMAIL_SUCCESS, types.USER_VERIFY_EMAIL_FAILURE],
    callAPI: () => axios.get(API_PATHS.AUTH_VERIFY_EMAIL, { params: { token: token } }),
  });
};

export const clear = () => (dispatch) => {
  dispatch({
    type: types.USER_CLEAR
  });
};

export const signUpUser = (account) => (dispatch) => {
  dispatch({
    types: [types.USER_SIGN_UP_USER_REQUEST, types.USER_SIGN_UP_USER_SUCCESS, types.USER_SIGN_UP_USER_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_SIGN_UP_USER, { ...account }),
    shouldCallSuccesAction: (response) => {
      return (response || {}).data || false;
    }
  });
};

export const signUpCompany = (company, token) => (dispatch) => {
  dispatch({
    types: [types.USER_SIGN_UP_COMPANY_REQUEST, types.USER_SIGN_UP_COMPANY_SUCCESS, types.USER_SIGN_UP_COMPANY_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_SIGN_UP_COMPANY, { ...company, token }),
    callOkAction: () => {
      dispatch(navigateToSignUpCompany(token));
    },
    callSuccessAction: (response) => {
      let { code } = ((response || {}).data);
      if (code) {
        switch (code) {
          case responseTypes.SignUpCompany.DuplicateIdentificator:
            dispatch(alertAdd('SignUpCompanyDuplicateIdentificator', alertTypes.WARNING));
            break;
          default:
            break;
        }
      }
    }
  });
};

export const signUpCompleteUser = (account, token) => (dispatch) => {
  dispatch({
    types: [types.USER_SIGN_UP_COMPLETE_USER_REQUEST, types.USER_SIGN_UP_COMPLETE_USER_SUCCESS, types.USER_SIGN_UP_COMPLETE_USER_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_SIGN_UP_COMPLETE_USER, { ...account, token }),
    shouldCallSuccesAction: (response) => {
      let { data } = response;
      return data || false;
    },
    callOkAction: () => dispatch(verifyEmail(token))
  });
};

export const signUpViaInvitation = (id, token, reject = false) => (dispatch) => {
  dispatch({
    types: [types.USER_INVITATION_SET_STATUS_REQUEST, types.USER_INVITATION_SET_STATUS_SUCCESS, types.USER_INVITATION_SET_STATUS_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_SIGN_UP_VIA_INVITATION, { id, token, reject }),
    callSuccessAction: (response) => {
      let { data } = response;
      if (data) {
        if (!data.isRegistered) {
          dispatch({ type: types.USER_INVITATION_REMOVE_ROW, id });
        } else {
          dispatch(signInCallback({ data: { ...data.authentication } }, NAVIGATION_PATHS.ROOT));
        }
      }
    },
    payload: { id }
  });
};

export const signIn = (email, password) => (dispatch) => {
  dispatch({
    types: [types.USER_SIGN_IN_REQUEST, types.USER_SIGN_IN_SUCCESS, types.USER_SIGN_IN_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_SIGN_IN, { email, password }),
    callSuccessAction: (response) => {
      dispatch(alertClear());
      dispatch(signInCallback(response, NAVIGATION_PATHS.AGENDA_SELECTION));
    },
    callFailureAction: () => { dispatch(signOut()); }
  });
};

export const checkAccess = () => (dispatch) => {
  let state = loadState();
  if (!state) {
    dispatch(signOut());
  } else {
    let { user } = state;
    if (user.tokenExpireIn === 0) {
      dispatch(signOut());
    }

    let now = new Date();
    let expireIn = new Date(user.tokenExpireIn * 1000);

    if (now > expireIn) {
      dispatch(signOut());
    } else {
      // Try to authorize
      dispatch(renewToken());
    }
  }
};

export const getCompanyInfo = (companyId) => (dispatch) => {
  dispatch({
    types: [types.USER_GET_COMPANY_INFO_REQUEST, types.USER_GET_COMPANY_INFO_SUCCESS, types.USER_GET_COMPANY_INFO_FAILURE],
    callAPI: () => axios.get(API_PATHS.AUTH_GET_COMPANY_INFO, { params: { companyId: companyId } }),
    callFailureAction: () => { dispatch(signOut()); }
  });
};


export const isAuth = (lastModified) => (dispatch) => {
  let state = loadState();
  if (!state) {
    dispatch(signOut());
  } else {
    // Inactive user will be signed out
    let now = new Date();
    // Minutes * Seconds * Miliseconds (120 * 60 * 1000)
    let maxInactiveTime = new Date(lastModified.getTime() + (120 * 60 * 1000));
    if (now > maxInactiveTime) {
      dispatch(signOut());
    } else {
      let { user } = state;
      if (user.tokenExpireIn === 0) {
        dispatch(signOut());
      }

      let expireIn = new Date(user.tokenExpireIn * 1000);
      let willExpire = new Date(user.tokenExpireIn * 1000);
      willExpire.setMinutes(willExpire.getMinutes() - 2);

      if (now < expireIn) {
        // Token is still valid
        if (now > willExpire) {
          // Renew early
          dispatch(renewToken());
        }
      } else {
        // Token is expired
        dispatch(signOut());
      }
    }
  }
};

export const getUserAgendas = (callback) => (dispatch) => {
  dispatch({
    types: [types.USER_FETCH_AGENDAS_REQUEST, types.USER_FETCH_AGENDAS_SUCCESS, types.USER_FETCH_AGENDAS_FAILURE],
    callAPI: () => axios.get(API_PATHS.AUTH_GET_USER_AGENDAS),
    callSuccessAction: ({ data }) => { if (callback) callback(data); },
    callFailureAction: () => { dispatch(signOut()); }
  });
};

export const setAgenda = (agendaId, reload = false) => (dispatch) => {
  dispatch({
    types: [types.APP_AGENDA_SET_REQUEST, types.APP_AGENDA_SET_SUCCESS, types.APP_AGENDA_SET_FAILURE],
    callAPI: () => axios.post(API_PATHS.AUTH_SET_USER_AGENDA, { agendaId }),
    callSuccessAction: (response) => {
      dispatch(signInCallback(response, NAVIGATION_PATHS.ROOT));
      if (reload) {
        window.location.reload();
      }
    },
    callFailureAction: () => { dispatch(signOut()); }
  });
};

export const createSignUpCompanyToken = () => (dispatch) => {
  return dispatch({
    types: [types.USER_SIGN_UP_CREATE_COMPANY_TOKEN_REQUEST, types.USER_SIGN_UP_CREATE_COMPANY_TOKEN_SUCCESS, types.USER_SIGN_UP_CREATE_COMPANY_TOKEN_FAILURE],
    callAPI: () => axios.get(API_PATHS.AUTH_CREATE_SIGN_UP_COMPANY_TOKEN),
    callSuccessAction: (response) => {
      let { data: { accessToken } } = response;
      if (accessToken) {
        dispatch(navigateTo(NAVIGATION_PATHS.SIGN_UP_CODE + accessToken));
      }
    },
  });
};

export const sendEmail = (mail, callback) => (dispatch) => {
  dispatch({
    types: [types.CONTACT_US_SEND_REQUEST, types.CONTACT_US_SEND_SUCCESS, types.CONTACT_US_SEND_FAILURE],
    callAPI: () => axios.post(API_PATHS.SEND_EMAIL, { ...mail }),
    callSuccessAction: () => {
      if (callback) {
        callback();
      }
    }
  });
};

export const checkAccessForbidden = () => (dispatch) => {
  let state = loadState();
  if (!state) {
    dispatch(signOut());
  } else {
    let { user } = state;
    if (user.tokenExpireIn === 0) {
      dispatch(signOut());
    }

    let now = new Date();
    let expireIn = new Date(user.tokenExpireIn * 1000);

    if (now > expireIn) {
      dispatch(signOut());
    } else if (user.isAuth) {
      dispatch({ type: types.APP_OPEN_WARNING_DIALOG, tKey: 'HttpStatus.Forbidden' });
    } else {
      dispatch(renewToken());
    }
  }
};

export const fetchDocumentsForApproval = () => (dispatch) => {
  dispatch({
    types: [types.USER_DOCUMENTS_FOR_APPROVAL_REQUEST, types.USER_DOCUMENTS_FOR_APPROVAL_SUCCESS, types.USER_DOCUMENTS_FOR_APPROVAL_FAILURE],
    callAPI: () => axios.get(API_PATHS.USER_DOCUMENTS_FOR_APPROVAL)
  });
};
