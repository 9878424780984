import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Translation from './Translation';

import { Notification as KendoNotification } from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';
import './Notification.scss';

const Notification = ({ id, style, tKey, transform, remove, sticky }) => {
  const [isVisible, setVisibility] = useState(false);
  const onCloseTimerID = useRef(null);

  const onClose = () => {
    if (onCloseTimerID.current || sticky) {
      clearTimeout(onCloseTimerID.current);
      onCloseTimerID.current = null;
      setVisibility(false);
      remove(id);
    }
  };

  useEffect(() => {
    setVisibility(true);
    if (!sticky) {
      onCloseTimerID.current = setTimeout(onClose, 5000);
    }

    return () => {
      if (onCloseTimerID.current) {
        clearTimeout(onCloseTimerID.current);
        onCloseTimerID.current = null;
      }
    };
  }, []);

  return (
    <Slide direction="up">
      {isVisible && <KendoNotification
        type={{ style: style, icon: true }}
        closable
        onClose={onClose}
      >
        <div className="notification-text"><Translation tKey={tKey} transform={transform} /></div>
      </KendoNotification>}
    </Slide>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  style: PropTypes.string.isRequired,
  tKey: PropTypes.string.isRequired,
  transform: PropTypes.object,
  remove: PropTypes.func,
  sticky: PropTypes.bool
};

export default Notification;
