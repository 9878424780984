import { Reducer } from 'redux';
import { BillingStateActionTypes } from '../components/settings/billing/types';
import * as types from '../actions/actionTypes';
import { BillingState } from '../common/types/reduxStateTypes';

export const initialState: BillingState = {
  balance: {
    amount: undefined,
    dateOfValidity: undefined
  },
  renewFirstDayOfMonth: false,
  rechargeByPaymentId: undefined,
  renewByLimit: false,
  recurrablePayments: [],
  canBeRenew: false,
  renew: false,
  renewLimit: 0,
  tariffs: [],
  groupedTariffItems: []
};

const reducer: Reducer<BillingState, BillingStateActionTypes> = (
  state: BillingState = initialState,
  action: BillingStateActionTypes
): BillingState => {
  switch (action.type) {
    case types.BILLING_BALANCE_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        balance: action.data
      };
    }
    case types.BILLING_FETCH_DATA_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case types.BILLING_TARIFFS_FETCH_SUCCESS: {
      return {
        ...state,
        tariffs: action.data.items
      };
    }
    case types.BILLING_GROUPED_TARIFF_ITEMS_FETCH_SUCCESS: {
      return {
        ...state,
        groupedTariffItems: action.data.items
      };
    }
    default:
      return state;
  }
};

export default reducer;
