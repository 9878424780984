import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import IconButton from './IconButton';
import { useTranslation } from 'react-i18next';

export type TextAlign = 'left' | 'center' | 'right';

const textAlignClasses = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end'
};

interface TrimTextProps {
  value: string,
  className?: any,
  showClipboardButton?: boolean,
  translate?: boolean,
  textAlign?: TextAlign,
}

const TrimText = (props: TrimTextProps) => {
  let { value: rawValue, className, showClipboardButton = false, translate = false, textAlign = 'left' } = props;
  const divRef = React.useRef<HTMLDivElement>(null);
  const [overflowActive, setOverflowActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const value = translate ? t(rawValue) : rawValue;

  const classWrapper = classnames({
    'overme': true,
    'overme-applied': overflowActive,
    [className]: className
  });

  const clipboardClassWrapper = classnames({
    'hide-clipboard-button': !(hover && showClipboardButton && overflowActive),
    'copied-to-clipboard': copied,
  });

  const checkIfEllipsisActive = () => {
    const trimDiv = divRef?.current;

    if (trimDiv) {
      return trimDiv.offsetHeight < trimDiv.scrollHeight || trimDiv.offsetWidth < trimDiv.scrollWidth;
    }
    return false;
  };

  useEffect(() => {
    const trimDiv = divRef?.current;

    if (trimDiv) {
      const isEllipsisActive = checkIfEllipsisActive();
      setOverflowActive(isEllipsisActive);

      const resizeObserver = new ResizeObserver(() => {
        const trimDiv = divRef?.current;

        if (trimDiv) {
          const isEllipsisActive = checkIfEllipsisActive();
          setOverflowActive(isEllipsisActive);
        }
      });

      resizeObserver.observe(trimDiv);
      return (() => {
        if (trimDiv) {
          resizeObserver.unobserve(trimDiv);
        }
      });
    }
  }, [divRef]);

  const renderTitle = () => {
    if (overflowActive) {
      return { title: value };
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}
      className={textAlignClasses[textAlign]}
      onMouseEnter={() => setHover(true)} onMouseLeave={() => { setHover(false); setCopied(false); }}
    >
      <div className={classWrapper} ref={divRef}>
        <span {...renderTitle()}>{value}&nbsp;</span>
      </div>
      {hover && showClipboardButton && overflowActive && <IconButton icon="save" className={clipboardClassWrapper} onClick={copyToClipboard}
        titleKey="Copy"
      />}
    </div>
  );
};

export default TrimText;
