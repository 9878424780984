export const COMGATE_PAYMENT_STATUS = {
  NotSet: 0,
  Pending: 1,
  Paid: 2,
  Canceled: 3,
};

export const COMGATE_PAYMENT_STATE_TEXT = {
  Success: "success",
  Pending: "pending",
  Canceled: "canceled"
};
