import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';

import logo from '../../images/logo_light.svg';
import inAppErrorPicture from '../../images/inapp-error.svg';
import topLevelErrorPicture from '../../images/top-level-error.svg';
import { Panel } from './Panel';
import Translation from './Translation';
import { navigateTo } from '../../actions/navigationActions';
import './ErrorBoundary.scss';
import { connect } from 'react-redux';
import { API_PATHS, NAVIGATION_PATHS } from '../../common/data/routes';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { logError } from 'actions/errorActions';
import StackTrace from "stacktrace-js";
import { getHost } from 'components/generalFunctions';

export interface ErrorPanelOwnProps {
  inApp?: boolean,
  headerText?: string,
  mainInfo?: string,
}

export interface ErrorPanelProps extends ErrorPanelOwnProps {
  navigateTo: (path: string) => void,
}

const ErrorPanel = ({ inApp = false, headerText, mainInfo, navigateTo }: ErrorPanelProps) => {
  const history = useHistory();

  const classWrapper = classNames({
    'unexpected-error': true,
    'top-level-error': !inApp,
  });

  const retryButtonClassWrapper = classNames({
    'datarios-button datarios-button datarios-button-blue': !inApp,
    'datarios-full-button general-button button-dark bg-primary': inApp,
  });

  const mailButtonClassWrapper = classNames({
    'datarios-button': true,
    'datarios-button-green top-right-button': !inApp,
    'datarios-button-blue': inApp,
  });

  return (
    <Panel fullheight className={classWrapper}>
      {!inApp && <img className="logo" src={logo} alt="logo" />}
      {inApp && <div className="unexpected-error-logo"><img style={{ height: '100%' }} src={inAppErrorPicture} alt="error" /></div>}
      {!inApp && <div className="unexpected-error-logo"><img style={{ height: '100%' }} src={topLevelErrorPicture} alt="error" /></div>}
      <div className="unexpected-error-info">
        <div className="unexpected-error-header"><Translation tKey={headerText ?? "UnexpectedError.Header"} /></div>
        <div className="unexpected-error-text"><Translation tKey={mainInfo ?? "UnexpectedError.MainInfo"} /></div>

        <div className="unexpected-error-buttons">
          <span className={retryButtonClassWrapper} onClick={() => history.go(0)}>
            <Translation tKey="UnexpectedError.Retry" />
          </span>
          <span className={mailButtonClassWrapper} onClick={() => navigateTo(NAVIGATION_PATHS.SUPPORT)}>
            <Translation tKey="UnexpectedError.MailUs" />
          </span>
        </div>
      </div>
    </Panel>
  );
};

const dispatchToProps = {
  navigateTo,
};

const WrapperErrorPanel = compose<React.ComponentType<ErrorPanelOwnProps>>(withTranslation(), connect(null, dispatchToProps))(ErrorPanel);
export { WrapperErrorPanel as ErrorPanel };


export interface ErrorBoundaryOwnProps {
  inApp?: boolean;
}

export interface ErrorBoundaryProps extends ErrorBoundaryOwnProps {
  logError: (error: string) => void,
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error, errorInfo);
    StackTrace.fromError(error).then(stack => {
      const { message, name, cause } = error;
      const info = { message, name, cause, stack };
      this.props.logError(`${JSON.stringify(info)}`);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Panel fullheight className="unexpected-error">
          <WrapperErrorPanel inApp={this.props.inApp} />
        </Panel>
      );
    }

    return this.props.children;
  }
}

export default compose<React.ComponentType<ErrorBoundaryOwnProps>>(withTranslation(), connect(null, { logError }))(ErrorBoundary);
