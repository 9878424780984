import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import Button from './Button';
import Translation from './Translation';
import * as types from '../../actions/actionTypes';
import { navigateToSignIn } from '../../actions/navigationActions';
import './Dialog.scss';

class WarningDialog extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showDialog: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ showDialog: this.props.isOpen });
    }
  }

  toggleDialog = () => {
    this.setState({ showDialog: !this.state.showDialog });

    this.props.close();
    if (this.props.signInAfterClosing) {
      this.props.navigateToSignIn();
    }
  };

  render() {
    return (
      <div className="dialog">
        {this.state.showDialog &&
          <Dialog onClose={() => this.toggleDialog()} title={this.props.t('WarningDialog.Title')}>
            {this.props.tKey && <div style={{ textAlign: "justify" }}>
              <Translation tKey={this.props.tKey} />
            </div>}
            {this.props.tKeys?.length > 0 && <div>
              {this.props.tKeys.map((tKey, i) => <div key={i}><Translation tKey={tKey} /></div>)}
            </div>}
            <DialogActionsBar layout="start">
              <Button onClick={() => this.toggleDialog()}><Translation tKey="Close" /></Button>
            </DialogActionsBar>
          </Dialog>}
      </div>
    );
  }
}

WarningDialog.propTypes = {
  t: PropTypes.func,
  tKey: PropTypes.string,
  tKeys: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  close: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    isOpen: state.app.warningDialog.open,
    tKey: state.app.warningDialog.tKey,
    tKeys: state.app.warningDialog.tKeys,
    signInAfterClosing: state.app.warningDialog.signInAfterClosing
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    close: () => dispatch({ type: types.APP_CLOSE_WARNING_DIALOG }),
    navigateToSignIn: () => dispatch(navigateToSignIn()),
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(WarningDialog);
