import { ReactNode, Ref, forwardRef, useImperativeHandle, useState } from 'react';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';

import Button from './Button';
import Translation from './Translation';
import ConfirmDialogBox from './ConfirmDialogBox';
import classNames from "classnames";
import './Dialog.scss';
import { ThemeType } from '../../common/types/commonTypes';

export type ConfirmDialogProps = {
  tKey?: string,
  title?: string | ReactNode,
  width?: number | string,
  okDisabled?: boolean,
  hideActions?: boolean,
  okLabel?: string,
  cancelLabel?: string,
  children?: ReactNode,
  className?: string,
  okAction: (params?: any) => void,
  cancelAction?: () => void,
  large?: boolean,
  theme?: ThemeType,
};

export type ConfirmDialogRef = {
  setParameters: (params: any) => void,
  openDialog: () => void,
  disableOkButton: (value: boolean) => void,
};

const ConfirmDialog = (props: ConfirmDialogProps, ref: Ref<ConfirmDialogRef>) => {
  const [okDisabled, setOkDisabled] = useState(props.okDisabled || false);
  const [showDialog, setShowDialog] = useState(false);
  const [params, setParams] = useState<any>(null);
  const [dialogTKey, setDialogTKey] = useState<string>(props.tKey || 'ConfirmQuestion');

  useImperativeHandle(ref, () => ({ setParameters, openDialog, disableOkButton, setDialogTextKey }));

  const setParameters = (params: any) => {
    setParams(params);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const toggleDialog = (callOkAction = false) => {
    setShowDialog(!showDialog);

    const { okAction, cancelAction } = props;
    if (callOkAction) {
      if (params) {
        okAction(params);
      } else {
        okAction();
      }
    } else if (cancelAction) {
      cancelAction();
    }
  };

  const disableOkButton = (value: boolean) => {
    setOkDisabled(value);
  };

  const setDialogTextKey = (value: string) => {
    setDialogTKey(value);
  };

  const { large, theme, className = "" } = props;
  const classDialogWrapper = classNames({
    'datarios-dialog': true,
    'datarios-dialog-default': true,
    'datarios-dialog-large': large || false,
    'datarios-dialog-dark': theme === 'dark',
    [className]: classNames,
  });

  let { title, width, children } = props;

  return (
    <div className="dialog">
      {showDialog &&
        <ConfirmDialogBox
          onClose={() => toggleDialog()}
          title={title}
          width={width}
          classNames={classDialogWrapper}
        >
          {!children && <div className="dialog-text">
            <Translation tKey={dialogTKey} />
          </div>}
          {children}
          {!props.hideActions &&
            <DialogActionsBar layout="start">
              <Button disabled={okDisabled} onClick={() => toggleDialog(true)} testId="confirm-ok-button">
                <Translation tKey={props.okLabel || "Yes"} />
              </Button>
              <Button mode="tertiary" onClick={() => toggleDialog(false)} testId="confirm-cancel-button">
                <Translation tKey={props.cancelLabel || "No"} />
              </Button>
            </DialogActionsBar>
          }
        </ConfirmDialogBox>}
    </div>);
};

export default forwardRef(ConfirmDialog);
