import axios, { AxiosResponse } from 'axios';

export function getReportsUrl() {
  let hostname = document.location.hostname;

  if (hostname !== "app.datarios.cz" && hostname !== "localhost") {
    hostname = "reports." + hostname;
  } else if (hostname === "app.datarios.cz") {
    hostname = "reports.datarios.cz";
  }

  let rootUrl = document.location.protocol + "//" + hostname;
  if (hostname === "localhost") {
    rootUrl += ":8191";
  }

  return rootUrl;
}

export function downloadThumbnail(id: number) {
  let url = getReportsUrl();
  return axios
    .get(`${url}/api/v1/workflowQueueRecordPdfFile/thumbnail/${id}`)
    .catch((error) => {
      console.log(`Downloading thumbnail error: ${error}`);
      return { data: null };
    });
}

export function downloadFile(id: number) {
  let host = getReportsUrl();
  const url = `${host}/api/v1/workflowQueueRecordPdfFile/download/${id}`;
  createDownloadLink(url);
}

export function downloadOriginFile(id: number) {
  const url = `api/workflowQueueRecord/originAttachment/${id}`;
  createDownloadLink(url);
}

export function downloadArchiveThumbnail(id: number) {
  let url = getReportsUrl();
  return axios
    .get(`${url}/api/v1/archiveFile/thumbnail/${id}`)
    .catch((error) => {
      console.log(`Downloading thumbnail error: ${error}`);
      return { data: null };
    });
}

export function downloadArchivePackageByToken(token: string) {
  let host = getReportsUrl();
  const url = `${host}/api/v1/archivePackage/download?token=${token}`;
  createDownloadLink(url);
}

export function downloadArchivePackage(packageId: number) {
  let host = getReportsUrl();
  const url = `${host}/api/v1/archivePackage/download/${packageId}`;
  createDownloadLink(url);
}

export function downloadArchivePackageInfo(packageId: number) {
  let host = getReportsUrl();
  const url = `${host}/api/v1/archivePackage/downloadInfo/${packageId}`;
  createDownloadLink(url);
}

export function downloadArchiveFile(id: number) {
  const host = getReportsUrl();
  const url = `${host}/api/v1/archiveFile/download/${id}`;
  createDownloadLink(url);
}

const createDownloadLink = (url: string) => {
  var iframe = document.getElementById('external-download-iframe');
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = 'external-download-iframe';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }
  (iframe as HTMLIFrameElement).src = url;
};
