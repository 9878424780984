import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Button from '../../common/Button';
import * as userActions from '../../../actions/userActions';
import TextBox from '../../common/TextBox';
import SubmitButton from '../../common/SubmitButton';
import Translation from '../../common/Translation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import * as generalFunctions from '../../generalFunctions';
import './CompanyVerification.scss';

class CompanyVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: {
        companyIdentificationNumber: '',
        vatNumber: '',
        street: '',
        postalCode: '',
        companyName: '',
        city: ''
      },
      emailIsUsed: false,
      errors: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.aresData !== this.props.aresData) {
      let account = { companyName: this.state.account.companyName, ...this.props.aresData };
      Object.keys(this.state.account).forEach(key => {
        account[key] = account[key] || '';
      });

      this.setState({ account });
    }
  }

  onChange = (event) => {
    let account = { ...this.state.account };
    account[event.target.name] = event.target.value;

    return this.setState({ account });
  };

  formIsValid = () => {
    let { account } = this.state;
    let { t } = this.props;
    let formIsValid = true;
    let errors = {};

    if (!this.props.validateCompanyIdentificationNumber(account.companyIdentificationNumber)) {
      errors.companyIdentificationNumber = t('CIDIsShortOrInvalid');
      formIsValid = false;
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  startTimer = () => {
    let interval = setInterval(this.timer, 1000);
    this.setState({ interval: interval });
  };

  timer = () => {
    if (this.state.timeLeft > 0) {
      this.setState({ timeLeft: this.state.timeLeft - 1 });
    } else {
      clearInterval(this.state.interval);
      this.goToVerification();
    }
  };

  signUp = (event) => {
    event.preventDefault();

    if (this.formIsValid()) {
      this.props.actions.signUpCompany(this.state.account, this.props.token);
    }
  };

  signUpViaInvitation = (event, id, reject = false) => {
    event.preventDefault();

    this.props.actions.signUpViaInvitation(id, this.props.token, reject);
  };

  getCompanyInfo = (event) => {
    event.preventDefault();

    let { companyIdentificationNumber } = this.state.account;
    let { t } = this.props;
    let { validateCompanyIdentificationNumber } = this.props;
    let errors = {};

    if (!validateCompanyIdentificationNumber(companyIdentificationNumber)) {
      errors.companyIdentificationNumber = t('CIDIsShortOrInvalid');
      this.setState({ errors: errors });
      return;
    }

    this.setState({ errors: errors });
    this.props.actions.getCompanyInfo(companyIdentificationNumber);
  };

  renderInvitations = () => {
    let { invitations } = this.props;

    return (
      <div className="box-wrapper two-column">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="DoYouWantConnectToCompany" /></div>
        </div>
        <div className="invitations row-margin">
          {invitations.map((item, i) => this.renderInvitationRow(item, i))}
        </div>
        <div><Translation tKey="YouHaveANewInvitationText" /></div>
      </div>
    );
  };

  renderInvitationRow = (item, index) => {
    return (
      <div className="invitations-row row-margin" key={item.companyIdentificationNumber + "" + item.id}>
        <div className="title row-margin">
          {index > 0 && <div className="horizontal-line" />}
          <div className="invitation-company-name"><Translation tKey="Firm" />: {item.companyName} </div>
          <div className="subtitle"><Translation tKey="CompanyIdentificationNumber" />: {item.companyIdentificationNumber}</div>
        </div>
        {item.message && <div className="invitation-message row-margin">
          {item.message}
        </div>}
        <div className="actions">
          <Button onClick={e => this.signUpViaInvitation(e, item.id)}>
            <Translation tKey="AcceptAndRegister" />
          </Button>
          &nbsp;
          <Button onClick={e => this.signUpViaInvitation(e, item.id, true)} mode="tertiary">
            <Translation tKey="Reject" />
          </Button>
        </div>
      </div>
    );
  };

  renderForm = () => {
    let { account, errors } = this.state;
    let { t } = this.props;
    let countryData = ["Česká republika", "Slovenská republika"];

    return (
      <div className="box-wrapper two-column">
        <div className="box-header-group">
          <div className="box-header"><Translation tKey="SignUpForDatarios" /></div>
          <div><Translation tKey="SignUpForDatariosText" /></div>
        </div>
        <form>
          <div className="row row-margin">
            <div className="col-md-6 col-sm-12">
              <TextBox
                name="companyIdentificationNumber"
                onChange={this.onChange}
                value={account.companyIdentificationNumber}
                placeholder={t('CompanyIdentificationNumber')}
                error={errors.companyIdentificationNumber} />
            </div>
            <div className="col-md-6 col-sm-12">
              <SubmitButton className="search-company-button full-size-button" onClick={this.getCompanyInfo} loading={this.props.loading}>
                <Translation tKey="Search" />
              </SubmitButton>
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-md-6 col-sm-12">
              <TextBox
                name="companyName"
                onChange={this.onChange}
                value={account.companyName}
                placeholder={t('CompanyName')}
                error={errors.companyName} />
            </div>
            <div className="col-md-6 col-sm-12">
              <TextBox
                name="vatNumber"
                onChange={this.onChange}
                value={account.vatNumber}
                placeholder={t('VATIN')}
                error={errors.vatNumber} />
            </div>
          </div>
          <div className="row row-margin">
            <div className="col-md-6 col-sm-12">
              <TextBox
                name="street"
                onChange={this.onChange}
                value={account.street}
                placeholder={t('Street')}
                error={errors.street} />
            </div>
            <div className="col-md-4 col-sm-12">
              <TextBox
                name="city"
                onChange={this.onChange}
                value={account.city}
                placeholder={t('City')}
                error={errors.city} />
            </div>
            <div className="col-md-2 col-sm-12">
              <TextBox
                className="small"
                name="postalCode"
                onChange={this.onChange}
                value={account.postalCode}
                placeholder={t('PostalCode')}
                error={errors.postalCode} />
            </div>
          </div>
          <div className="country" style={{ display: 'none' }}>
            <DropDownList
              disabled
              data={countryData}
              value={countryData[0]} />
          </div>
          <div className="box-actions">
            <div className="actions">
              <SubmitButton onClick={this.signUp} loading={this.props.loading}><Translation tKey="Continue" /></SubmitButton>
            </div>
          </div>
        </form>
      </div >
    );
  };

  render() {
    let { invitations } = this.props;
    return (
      <>
        {invitations.length > 0 && this.renderInvitations()}
        {invitations.length === 0 && this.renderForm()}
      </>
    );
  }
}

CompanyVerification.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  aresData: PropTypes.object,
  invitations: PropTypes.arrayOf(PropTypes.object).isRequired,
  token: PropTypes.string.isRequired,
  validateCompanyIdentificationNumber: PropTypes.func,
};

const mapStateToProps = (state) => {
  let aresData;

  let { user } = state;
  if (user) {
    let { ares } = user;
    if (ares) {
      aresData = ares[0];
    }
  }

  return {
    loading: state.ajaxStatus.totalTriggers > 0,
    aresData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
    ...generalFunctions
  };
};

export default compose(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps))(CompanyVerification);
