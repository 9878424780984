import { NumberFormatOptions, useInternationalization } from '@progress/kendo-react-intl';

export type NumberFormatterProps = {
  amount: number,
  format?: string | NumberFormatOptions;
};

const NumberFormatter = ({ amount, format = 'n' }: NumberFormatterProps) => {
  const intl = useInternationalization();
  return (
    <>{intl.formatNumber(amount, format)}</>
  );
};

export default NumberFormatter;
