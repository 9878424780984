import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';

import TrimText from './TrimText';
import { SCREEN_RESOLUTION_TYPES } from '../../common/types/screenResolutionTypes';
import { RootState } from '../../common/types/reduxStateTypes';

const PageTitle = (props: PageTitleProps) => {
  const { title, params } = props.currentPage;
  const { resolution } = props;
  const { t } = useTranslation();

  if (params?.bindings && params.bindings.length > 0) {
    if (resolution === SCREEN_RESOLUTION_TYPES.XL) {
      return (
        <div className="header-tile breadcrumbs">
          {
            params.bindings.map(({ title, link, translateTitle = true }, index) => {
              const translatedTitle = (translateTitle ? t(title) : title) as string;
              return (
                <span key={index}>
                  {
                    link ?
                      <>
                        <Link to={link}><TrimText value={translatedTitle} /></Link>
                        <span className="icon-angle-right" />
                      </> : <><TrimText value={translatedTitle} /></>
                  }
                </span>
              );
            })
          }
        </div>
      );
    }

    const { title, translateTitle = true } = params.bindings[params.bindings.length - 1];
    const translatedTitle = (translateTitle ? t(title) : title) as string;
    return <TrimText value={translatedTitle} />;
  }

  if (title) {
    const _title = params?.translateTitle ? t(title) : title;
    return <TrimText value={_title as string} />;
  }

  return <></>;
};

const mapStateToProps = (state: RootState) => {
  return {
    currentPage: state.app.currentPage,
    resolution: state.app.resolution,
  };
};

const connector = connect(mapStateToProps);
type PageTitleProps = ConnectedProps<typeof connector>;

export default connector(PageTitle);
