import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import SideBarMenu from './SideBarMenu';
import './SideBar.scss';
import { RootState } from '../../common/types/reduxStateTypes';

interface SideBarProps {
  path: string,
  open: boolean,
  permissions: Array<string>
}

const SideBar = ({ open, path, permissions }: SideBarProps) => {
  const classWrapper = classnames({
    'sidebar': true,
    'sidebar-close': !open
  });

  return (
    <nav className={classWrapper}>
      <SideBarMenu
        path={path}
        className={"sidebar-menu"}
        permissions={permissions}
      />
    </nav>);
};

const mapStateToProps = (state: RootState) => {
  return {
    open: state.sideBar.open,
    permissions: state.user.permissions
  };
};

export default connect(mapStateToProps, undefined)(SideBar);
