import axios from 'axios';
import {
  BILLING_BALANCE_FETCH_DATA_REQUEST,
  BILLING_BALANCE_FETCH_DATA_SUCCESS,
  BILLING_BALANCE_FETCH_DATA_FAILURE,
  BILLING_FETCH_DATA_REQUEST,
  BILLING_FETCH_DATA_SUCCESS,
  BILLING_FETCH_DATA_FAILURE,
  BILLING_UPDATE_DATA_REQUEST,
  BILLING_UPDATE_DATA_SUCCESS,
  BILLING_UPDATE_DATA_FAILURE,
  CREDIT_MAKE_PAYMENT_REQUEST,
  CREDIT_MAKE_PAYMENT_FAILURE,
  CREDIT_MAKE_PAYMENT_SUCCESS,
  BILLING_TARIFFS_FETCH_REQUEST,
  BILLING_TARIFFS_FETCH_SUCCESS,
  BILLING_TARIFFS_FETCH_FAILURE,
  BILLING_GROUPED_TARIFF_ITEMS_FETCH_REQUEST,
  BILLING_GROUPED_TARIFF_ITEMS_FETCH_SUCCESS,
  BILLING_GROUPED_TARIFF_ITEMS_FETCH_FAILURE,
  BILLING_TARIFF_SET_NEXT_REQUEST,
  BILLING_TARIFF_SET_NEXT_SUCCESS,
  BILLING_TARIFF_SET_NEXT_FAILURE,
  CONTACT_US_SEND_REQUEST,
  CONTACT_US_SEND_SUCCESS,
  CONTACT_US_SEND_FAILURE
} from '../../../actions/actionTypes';

import { AgendaBillingMutationModel } from '../../../api/models/AgendaBillingMutationModel';
import { SendMailModel } from '../../../api/models/SendMailModel';
import { alertAdd } from '../../../actions/alertActions';
import * as alertTypes from '../../../actions/alertTypes';
import { API_PATHS } from "../../../common/data/routes";
import { OK } from '../../../common/types/responseTypes';

interface DatariosDispatch {
  <T>(action: T): T
}

export interface ActionsDispatchProps extends DatariosDispatch {
  types: string[],
  callAPI: () => any
}

export const fetchBilling = () => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [BILLING_FETCH_DATA_REQUEST, BILLING_FETCH_DATA_SUCCESS, BILLING_FETCH_DATA_FAILURE],
    callAPI: () => axios.get(API_PATHS.BILLING)
  });
};

export const updateBilling = (values: AgendaBillingMutationModel) => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [BILLING_UPDATE_DATA_REQUEST, BILLING_UPDATE_DATA_SUCCESS, BILLING_UPDATE_DATA_FAILURE],
    callAPI: () => axios.put(API_PATHS.BILLING, { ...values }),
    callSuccessAction: () => dispatch(alertAdd("SaveSuccess", alertTypes.SUCCESS))
  });
};

export const fetchBalance = () => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [BILLING_BALANCE_FETCH_DATA_REQUEST, BILLING_BALANCE_FETCH_DATA_SUCCESS, BILLING_BALANCE_FETCH_DATA_FAILURE],
    callAPI: () => axios.get(API_PATHS.BILLING_BALANCE_GET)
  });
};

export const fetchPriceLists = () => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [BILLING_TARIFFS_FETCH_REQUEST, BILLING_TARIFFS_FETCH_SUCCESS, BILLING_TARIFFS_FETCH_FAILURE],
    callAPI: () => axios.get(API_PATHS.BILLING_TARIFFS_GET)
  });
};

export const fetchGroupedPriceListItems = () => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [BILLING_GROUPED_TARIFF_ITEMS_FETCH_REQUEST, BILLING_GROUPED_TARIFF_ITEMS_FETCH_SUCCESS, BILLING_GROUPED_TARIFF_ITEMS_FETCH_FAILURE],
    callAPI: () => axios.get(API_PATHS.BILLING_GROUPED_TARIFF_ITEMS_GET)
  });
};

export const setNextTariff = (id: number) => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [BILLING_TARIFF_SET_NEXT_REQUEST, BILLING_TARIFF_SET_NEXT_SUCCESS, BILLING_TARIFF_SET_NEXT_FAILURE],
    callAPI: () => axios.put(API_PATHS.BILLING_SET_NEXT_TARIFF, { id }),
    callSuccessAction: () => dispatch(fetchPriceLists())
  });
};

export const sendEmail = (mail: SendMailModel, callback?: () => void) => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [CONTACT_US_SEND_REQUEST, CONTACT_US_SEND_SUCCESS, CONTACT_US_SEND_FAILURE],
    callAPI: () => axios.post(API_PATHS.SEND_EMAIL, { ...mail }),
    callSuccessAction: () => {
      dispatch(alertAdd("ContactUs.EmailWasSend", alertTypes.SUCCESS));
      if (callback) {
        callback();
      }
    }
  });
};

interface IResponse {
  data: {
    code: any,
    data: {
      redirect: any
    }
  }
}

export const makePayment = (values: any) => (dispatch: ActionsDispatchProps): void => {
  dispatch({
    types: [CREDIT_MAKE_PAYMENT_REQUEST, CREDIT_MAKE_PAYMENT_SUCCESS, CREDIT_MAKE_PAYMENT_FAILURE],
    callAPI: () => axios.post(API_PATHS.CREATE_CREDIT_PAYMENT, values),
    callSuccessAction: (response: IResponse) => {
      let { data: { code } } = response;
      if (code) {
        switch (code) {
          case OK: {
            let { data: { data: comgateData } } = response;
            if (comgateData) {
              window.location = comgateData.redirect;
            }
            break;
          }
          default:
            break;
        }
      }
    }
  });
};
