import { AnyAction } from 'redux';
import * as types from '../actions/actionTypes';
import { userAppSettingsInitialState } from './initialStates';

const userAppSettings = (state = userAppSettingsInitialState, action: AnyAction) => {
  const { type, data } = action;
  switch (type) {
    case types.USER_APP_SETTINGS_UPDATE:
      return {
        ...state,
        ...data
      };
    default:
      return state;
  }
};

export default userAppSettings;
