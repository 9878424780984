import * as types from '../actions/actionTypes';
import { appInitialState } from './initialStates';

const app = (state = appInitialState, action) => {
  switch (action.type) {
    case types.APP_RESOLUTION:
      return {
        ...state,
        resolution: action.resolution
      };
    case types.APP_VIEW_MODE:
      return {
        ...state,
        viewMode: action.viewMode,
        viewModeForced: action.forced !== undefined ? action.viewMode : state.viewModeForced
      };
    case types.APP_GRID_MODE:
      return {
        ...state,
        showCompactGrid: action.isCompact
      };
    case types.APP_HAS_UNSAVED_CHANGES:
      return {
        ...state,
        hasUnsavedChanges: action.hasUnsavedChanges
      };
    case types.APP_NEXT_LOCATION:
      return {
        ...state,
        nextLocation: action.nextLocation
      };
    case types.APP_USER_WORKFLOW_FILTER:
      return {
        ...state,
        userFilters: { workflow: action.filterData },
      };
    case types.APP_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case types.APP_CHANGE_LANG:
      return {
        ...state,
        lang: action.lang
      };
    case types.APP_OPEN_WARNING_DIALOG:
      return {
        ...state,
        warningDialog: {
          open: true,
          tKey: action.tKey,
          tKeys: action.tKeys,
          signInAfterClosing: action.signInAfterClosing ?? false
        }
      };
    case types.APP_CLOSE_WARNING_DIALOG:
      return {
        ...state,
        warningDialog: {
          open: false,
          tKey: '',
          tKeys: [],
          signInAfterClosing: false
        }
      };
    case types.APP_NEED_SIGNOUT_DIALOG_OPEN:
      return {
        ...state,
        needSignoutDialog: {
          open: true,
          agendaId: action.agendaId
        }
      };
    case types.APP_NEED_SIGNOUT_DIALOG_CLOSE:
      return {
        ...state,
        needSignoutDialog: {
          open: false,
          agendaId: 0
        }
      };
    default:
      return state;
  }
};

export default app;
