import React from 'react';
import { Trans } from 'react-i18next';

import i18n from '../../i18n';

const AgreeTermOfUse = () => {
  return (<Trans i18nKey="AgreeTermOfUse.Text1"
    values={{ termsOfUse: i18n.t('AgreeTermOfUse.Text2') }}
    components={[
      <a key={1} target="_blank" rel="noreferrer"
        href="https://www.datarios.cz/smluvni-ujednani-pro-pouziti-internetove-a-desktopove-aplikace-datarios/"
        className="text-link underlined"
      >dummyContent</a>
    ]} />);
};

export default AgreeTermOfUse;
