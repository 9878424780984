import classNames from "classnames";
import { ButtonMode } from "../common/types/uiTypes";

export const GetThemeColor = (mode: ButtonMode) => {
  if (mode === 'danger') {
    return 'error';
  }
  return mode;
};

export const getChipListClassName = () => 'k-chip-list k-chip-list-md';
export const getChipClassName = (selected: boolean) => (
  classNames({
    'k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base': true,
    'k-selected ': selected,
  })
);

